import { getPCN } from '../../../utils/classes'
import logo from '../../../svgs/svgjs/spec-wide-logo'
import icons from '../../../svgs/icons'
import { links } from '../../../utils/nav'
import twitterIcon from '../../../svgs/svgjs/twitter'
import discordIcon from '../../../svgs/svgjs/discord'

function DesktopHeader() {
    const className = 'desktop-header'
    const pcn = getPCN(className)

    return (
        <div className={className}>
            <div className={pcn('__liner')}>
                <a className={pcn('__brand-container')} href='/' tabIndex='-1'>
                    <div
                        className={pcn('__brand-logo')}
                        dangerouslySetInnerHTML={{ __html: logo }}>
                    </div>
                </a>
                <div className={pcn('__nav-container')}>
                    <div className={pcn('__nav')}>
                        <div className={pcn('__icon-links')}>
                            <a
                                className={pcn('__icon-link', '__icon-link--github')}
                                target='_blank'
                                tabIndex='-1'
                                rel="noreferrer"
                                href={ links.GITHUB_ORG }
                                dangerouslySetInnerHTML={{ __html: icons.github }}>
                            </a>
                            <a
                                className={pcn('__icon-link', '__icon-link--discord')}
                                target='_blank'
                                tabIndex='-1'
                                rel='noreferrer'
                                href={ links.DISCORD }
                                dangerouslySetInnerHTML={{ __html: discordIcon }}>
                            </a>
                            <a
                                className={pcn('__icon-link', '__icon-link--twitter')}
                                target='_blank'
                                tabIndex='-1'
                                rel="noreferrer"
                                href={ links.TWITTER }
                                dangerouslySetInnerHTML={{ __html: twitterIcon }}>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DesktopHeader
