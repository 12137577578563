const newLiveCol = `<svg viewBox="0 0 29 25">
    <g fill-rule="evenodd">
        <g id="Group-28" fill="#00ded7">
            <g id="Group-27" opacity="0.633114769" fill-rule="nonzero">
                <path d="M7,0 C7.55228475,0 8,0.44771525 8,1 L8,4.95238095 C8,5.5046657 7.55228475,5.95238095 7,5.95238095 L1,5.95238095 C0.44771525,5.95238095 0,5.5046657 0,4.95238095 L0,1 C0,0.44771525 0.44771525,0 1,0 L7,0 Z M7,1 L1,1 L1,4.95238095 L7,4.95238095 L7,1 Z" id="Rectangle"></path>
                <path d="M7,9.52380952 C7.55228475,9.52380952 8,9.97152477 8,10.5238095 L8,14.4761905 C8,15.0284752 7.55228475,15.4761905 7,15.4761905 L1,15.4761905 C0.44771525,15.4761905 0,15.0284752 0,14.4761905 L0,10.5238095 C0,9.97152477 0.44771525,9.52380952 1,9.52380952 L7,9.52380952 Z M7,10.5238095 L1,10.5238095 L1,14.4761905 L7,14.4761905 L7,10.5238095 Z" id="Rectangle"></path>
                <path d="M7,19.047619 C7.55228475,19.047619 8,19.4953343 8,20.047619 L8,24 C8,24.5522847 7.55228475,25 7,25 L1,25 C0.44771525,25 0,24.5522847 0,24 L0,20.047619 C0,19.4953343 0.44771525,19.047619 1,19.047619 L7,19.047619 Z M7,20.047619 L1,20.047619 L1,24 L7,24 L7,20.047619 Z" id="Rectangle"></path>
                <path d="M17.5038805,0 C18.0561653,0 18.5038805,0.44771525 18.5038805,1 L18.5038805,4.95238095 C18.5038805,5.5046657 18.0561653,5.95238095 17.5038805,5.95238095 L11.5,5.95238095 C10.9477153,5.95238095 10.5,5.5046657 10.5,4.95238095 L10.5,1 C10.5,0.44771525 10.9477153,0 11.5,0 L17.5038805,0 Z M17.5038805,1 L11.5,1 L11.5,4.95238095 L17.5038805,4.95238095 L17.5038805,1 Z" id="Rectangle"></path>
                <path d="M17.5038805,9.52380952 C18.0561653,9.52380952 18.5038805,9.97152477 18.5038805,10.5238095 L18.5038805,14.4761905 C18.5038805,15.0284752 18.0561653,15.4761905 17.5038805,15.4761905 L11.5,15.4761905 C10.9477153,15.4761905 10.5,15.0284752 10.5,14.4761905 L10.5,10.5238095 C10.5,9.97152477 10.9477153,9.52380952 11.5,9.52380952 L17.5038805,9.52380952 Z M17.5038805,10.5238095 L11.5,10.5238095 L11.5,14.4761905 L17.5038805,14.4761905 L17.5038805,10.5238095 Z" id="Rectangle"></path>
                <path d="M17.5038805,19.047619 C18.0561653,19.047619 18.5038805,19.4953343 18.5038805,20.047619 L18.5038805,24 C18.5038805,24.5522847 18.0561653,25 17.5038805,25 L11.5,25 C10.9477153,25 10.5,24.5522847 10.5,24 L10.5,20.047619 C10.5,19.4953343 10.9477153,19.047619 11.5,19.047619 L17.5038805,19.047619 Z M17.5038805,20.047619 L11.5,20.047619 L11.5,24 L17.5038805,24 L17.5038805,20.047619 Z" id="Rectangle"></path>
            </g>
        </g>
    </g>
</svg>`

export default newLiveCol