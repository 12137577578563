import humps from 'humps'

const vowels = new Set(['a', 'e', 'i', 'o', 'u'])

export const noMod = val => val

export const abbreviatedAddress = address => `${address.substr(0, 5)}...${address.substr(address.length - 4)}`

export const toPlural = (str = '') => `${str}s`

export const camelToSnake = val => humps.decamelize(val || '')

export const withIndefiniteArticle = val => {
    const firstChar = ((val || '')[0] || '').toLowerCase()
    const startsWithVowel = vowels.has(firstChar)
    return startsWithVowel || val.toLowerCase().startsWith('nft') ? `an ${val}` : `a ${val}`
}

export const toSlug = (value) => {
    return value
        .replace(/[']/g, '')
        .replace(/[^A-Za-z0-9-_]/g, '-')
        .toLowerCase()
}

export const camelToTitle = val => {
    return val[0].toUpperCase() + val.slice(1).replace(/([A-Z])/g, ' $1').trim()
}

export const extractCustomerNspFromContractNsp = nsp => nsp?.split('.')[0]

export const splitOnUppercase = val => {
    return val?.replace(/([0-9])([A-Z])/g, '$1 $2').replace(/([a-z])([A-Z])/g, '$1 $2').replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
}

export const capitalizeFirstLetter = val => {
    if (!val) {
        return
    }
    return val[0].toUpperCase() + val.slice(1)
}

export const formatDesc = val => {
    const dotIndex = val?.indexOf('.')
    return dotIndex !== -1 && dotIndex < val?.length-1 ? capitalizeFirstLetter(val.split(/\.(.*)/s)[1]) : capitalizeFirstLetter(val)
}

export const withPeriod = val => {
    if (!val) return ''
    return val.endsWith('.') ? val : `${val}.`
}

export const toNumber = val => {
    let numRecords = parseInt(val)
    return Number.isNaN(numRecords) ? 0 : numRecords
}

export const toNamespacedVersion = (nsp, name, version) => `${nsp}.${name}@${version}`