const defi = `<svg viewBox="0 0 50 45">
    <g id="Exports" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="noun-candlestick-graph-922408" fill="#0E0E23" fill-rule="nonzero">
            <polygon id="Path" points="5.32019704 4.56034483 4.07892857 4.56034483 4.07892857 8.20667488 0.0442241379 8.20667488 0.0442241379 20.620468 4.07892857 20.620468 4.07892857 29.0774754 5.32019704 29.0774754 5.32019704 20.620468 9.35445813 20.620468 9.35445813 8.20667488 5.32019704 8.20667488"></polygon>
            <polygon id="Path" points="32.0098522 14.8189655 30.7685837 14.8189655 30.7685837 23.2759729 26.7338793 23.2759729 26.7338793 35.689766 30.7685837 35.689766 30.7685837 39.3360961 32.0098522 39.3360961 32.0098522 35.689766 36.0441133 35.689766 36.0441133 23.2759729 32.0098522 23.2759729"></polygon>
            <polygon id="Path" points="49.3891626 15.262931 45.3544581 15.262931 45.3544581 4.96551724 44.1131897 4.96551724 44.1131897 15.262931 40.0789286 15.262931 40.0789286 22.8484729 44.1131897 22.8484729 44.1131897 29.4823153 45.3544581 29.4823153 45.3544581 22.8484729 49.3891626 22.8484729"></polygon>
            <polygon id="Path" points="18.7755296 -5.96025058e-14 17.5342611 -5.96025058e-14 17.5342611 4.31620111 13.5 4.31620111 13.5 39.9762581 17.5342611 39.9762581 17.5342611 45 18.7755296 45 18.7755296 39.9762581 22.810234 39.9762581 22.810234 4.31620111 18.7755296 4.31620111"></polygon>
        </g>
    </g>
</svg>`

export default defi