const ECOSYSTEM = '/ecosystem'

export const subjects = {
    NAMESPACE: 'namespace',
    LIVE_OBJECT: 'live-table',
    CONTRACT_GROUP: 'contract-group',
    EVENT: 'event',
}

export const sections = {
    NAMESPACES: subjects.NAMESPACE + 's',
    LIVE_OBJECTS: subjects.LIVE_OBJECT + 's',
    CONTRACT_GROUPS: subjects.CONTRACT_GROUP + 's',
    EVENTS: subjects.EVENT + 's',
}

export const paths = {
    HOME: '/',
    BLOG_PATTERN: '/blog/:post?',
    JOIN_WAITLIST: '/early-access',
    ECOSYSTEM_PATTERN: `/:nsp/:section?/:subSection?`,
    ECOSYSTEM_HOME: ECOSYSTEM,
    NAMESPACES: `${ECOSYSTEM}/${sections.NAMESPACES}`,
    LIVE_OBJECTS: `${ECOSYSTEM}/${sections.LIVE_OBJECTS}`,
    CONTRACT_GROUPS: `${ECOSYSTEM}/${sections.CONTRACT_GROUPS}`,
    EVENTS: `${ECOSYSTEM}/${sections.EVENTS}`,
}

paths.toNamespace = slug => `/${slug}`
paths.toLiveObject = (nsp, slug) => `/${nsp}/${subjects.LIVE_OBJECT}/${slug}`
paths.toLiveObjects = (nsp) => `/${nsp}/${subjects.LIVE_OBJECT}s`
paths.toLiveObjectSubPage = (nsp, uid, subpage) => `/${nsp}/${subjects.LIVE_OBJECT}/${uid}#${subpage}`
paths.toContractGroup = (nsp, slug) => `/${nsp}/${subjects.CONTRACT_GROUP}/${slug}`
paths.toContractGroups = (nsp) => `/${nsp}/${subjects.CONTRACT_GROUP}s`
paths.toContractGroupSubPage = (nsp, group, subpage) => `/${nsp}/${subjects.CONTRACT_GROUP}/${group}#${subpage}`
paths.toEvent = (nsp, slug) => `/${nsp}/${subjects.EVENT}/${slug}`
paths.toEvents = (nsp) => `/${nsp}/${subjects.EVENT}s`
paths.toEventSubPage = (nsp, uid, subpage) => `/${nsp}/${subjects.EVENT}/${uid}#${subpage}`

export const links = {
    GITHUB_ORG: 'https://github.com/spec-dev',
    GITHUB_SPEC: 'https://github.com/spec-dev/spec',
    TWITTER: 'https://twitter.com/SpecDotDev',
    TEAM_EMAIL: 'mailto:team@spec.dev',
    CALENDLY: 'https://calendly.com/ben-at-spec/spec-early-access',
    DISCORD: 'https://discord.gg/5sqH6eBgH9',
}