import { useCallback } from 'react'
import { getPCN, cn } from '../../utils/classes'
import ChainAvatar from '../shared/ChainAvatar'
import { useHistory } from 'react-router-dom'
import PH from '../shared/PH'
import { paths } from '../../utils/nav'
import { toNumber } from '../../utils/formatters'
import { copyToClipboard } from '../../utils/clipboard'
import copyIcon from '../../svgs/svgjs/copy'
import externalLinkIcon from '../../svgs/svgjs/external-link'
import { format as toTimeAgo } from 'timeago.js'
import { toBlockExplorerAddressLink } from '../../utils/chains'

const className = 'contract-group-resource'
const pcn = getPCN(className)

export const resourceNames = {
    CONTRACTS: 'contracts',
    EVENTS: 'events',
}

export const resources = [
    {
        name: resourceNames.CONTRACTS,
        displayName: 'Contracts',
        width: 81.16,
        maxPlaceholders: 5,
        link: (nsp, group, subpage) => paths.toContractGroupSubPage(nsp, group, subpage),
    },
    {
        name: resourceNames.EVENTS,
        displayName: 'Events',
        width: 65,
        maxPlaceholders: 5,
        link: (nsp, group, subpage) => paths.toContractGroupSubPage(nsp, group, subpage),
    },
]

export const ContractGroupResource = ({ nsp, filter, contractGroup, isLoading }) => {    
    const history = useHistory()
    const resource = resources.find(r => r.name === filter) || {}
    
    const formatListData = useCallback(() => {
        if (isLoading) return []

        if (filter === resourceNames.CONTRACTS) {
            return Object.values(contractGroup.instances).flat().sort((a, b) => (
                new Date(b.createdAt) - new Date(a.createdAt)
            ))
        }

        if (filter === resourceNames.EVENTS) {
            return (contractGroup.events || []).sort((a, b) => (
                (toNumber(b.records) - toNumber(a.records)) || 
                (new Date(b.lastInteraction) - new Date(a.lastInteraction)) || 
                (a.latestVersion?.name || '').localeCompare(b.latestVersion?.name || '')
            ))
        }

        return []
    }, [filter, contractGroup, isLoading])

    const renderContract = useCallback((contract) => {
        return <div className={pcn('__item-body', '__item-body--contracts')}>
            <div>
                <ChainAvatar chainIds={[contract.chainId]} className={className} single={true}/>
                <h4>{contract.address}</h4>
                <div className={pcn('__item-action-links')}>
                    <span
                        className={pcn('__item-action-link', '__item-action-link--copy')}
                        dangerouslySetInnerHTML={{ __html: copyIcon }}
                        onClick={() => copyToClipboard(contract.address)}>
                    </span>
                    <a
                        className={pcn('__item-action-link', '__item-action-link--link')}
                        target='_blank'
                        rel='noreferrer'
                        href={toBlockExplorerAddressLink(contract.address, contract.chainId)}
                        dangerouslySetInnerHTML={{ __html: externalLinkIcon }}>
                    </a>
                </div>
            </div> 
            <p className={pcn('__added-at')}>
                <span>{contract.createdAt ? toTimeAgo(contract.createdAt) : ''}</span>
            </p>
        </div>
    }, [])

    const renderEvent = useCallback((liveObject) => {
        const chainIds = Object.keys(liveObject.latestVersion.config.chains)
        const descClassNames = [
            '__desc',
            `__desc--${resource.name.toLowerCase()}`,
            ...chainIds.map(id => `__desc--chain-${id}`),
        ]
        const contractGroup = liveObject.latestVersion.nsp
        return (
            <div className={pcn('__item-body', '__item-body--events')}>
                <div>
                    <h4>{liveObject.name}</h4>
                    <span className={pcn(...descClassNames)}>
                        { contractGroup }
                    </span>
                </div>
                <ChainAvatar chainIds={chainIds} className={className}/>
                <p className={pcn('__records')}>
                    <span>{liveObject.records?.toLocaleString('en-US') || 0}</span>
                    <span>{liveObject.latestVersion.version}</span>
                </p>
            </div> 
        )
    }, [resource])

    const renderBody = useCallback((data) => {
        if (resource.name === resourceNames.CONTRACTS) {
            return renderContract(data)
        } else if (resource.name === resourceNames.EVENTS) {
            return renderEvent(data)
        }
    }, [resource, renderEvent, renderContract])

    const renderPlaceholders = useCallback(() => {
        const numPlaceholders = resource.maxPlaceholders
        const empty = Array.apply(null, Array(numPlaceholders)).map(() => null)
        return empty.map((_, i) => (
            <li
                key={i}
                className={pcn('__item', '__item--ph', `__item--${resource.name.toLowerCase()}`)}>
                <div className={pcn('__item-liner', '__item-liner--ph', `__item-liner--${resource.name.toLowerCase()}`)}>
                    <PH id={resource.name}/>
                </div>
            </li>
        ))
    }, [resource])

    const onClickItem = useCallback((item) => {
        if (resource.name === resourceNames.EVENTS) {
            history.push(paths.toEvent(nsp, item.latestVersion.id))
        }
    }, [resource, history, nsp])

    return <div className={cn(className, `${className}--${resource.name.toLowerCase()}`)}>
        <ol className={pcn('__list', `__list--${resource.name.toLowerCase()}`)}>
            { isLoading ? renderPlaceholders() : formatListData().map((data, i) => 
                <li
                    key={i}
                    className={pcn('__item', `__item--${resource.name.toLowerCase()}`)}
                    onClick={() => onClickItem(data)}>
                    <div className={pcn('__item-liner', `__item-liner--${resource.name.toLowerCase()}`)}>
                        { renderBody(data) }
                    </div>
                </li>
            )}
        </ol>
    </div>
}