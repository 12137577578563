import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react'
import Toolkit from './Toolkit'
import { getPCN, cn } from '../../../utils/classes'
import { isElementInView } from '../../../utils/doc'
import { compTypes } from '../../../utils/comp-types'
import { toPlural } from '../../../utils/formatters'
import pixels from '../../../svgs/svgjs/sync-pixels'
import packageIcon from '../../../svgs/svgjs/package'
import { animated } from 'react-spring'

const className = 'layers'
const observerTargetId = 'layersObserverTarget'
const pcn = getPCN(className)
const tpcn = getPCN('toolkit')

const layer1BlockIds = {
    INDEXER: 'indexer',
    CONTRACTS: 'contracts',
    TABLES: 'tables',
}

const layer1Blocks = [
    {
        id: layer1BlockIds.INDEXER,
        title: 'Shared Indexers',
        desc: 'Listen to enriched, descriptive, on-chain events.',
    },
    {
        id: layer1BlockIds.CONTRACTS,
        title: 'Contracts API',
        desc: 'Call functions on verified contracts by name — no ABIs.',
    },
    {
        id: layer1BlockIds.TABLES,
        title: 'Shared Tables',
        desc: 'Easily query historical blockchain data.',
    },
]

const toolkitComps = [
    ({ style }) => (
        <animated.div className={tpcn('__panel')} style={style}>
            <div className={tpcn('__intro')}>
                <span dangerouslySetInnerHTML={{ __html: packageIcon }}></span>
                <span>Spec Open-Source Toolkit</span>
            </div>
        </animated.div>
    ),
    ({ style }) => (
        <animated.div className={tpcn('__panel')} style={style}>
            <div className={tpcn('__typed')}>
                <span>spec.<span className='function'>on</span>(<span className='event'>event.AaveDeposit</span>, ...)</span>
            </div>
        </animated.div>
    ),
    ({ style }) => (
        <animated.div className={tpcn('__panel')} style={style}>
            <div className={tpcn('__typed')}>
                <span>spec.<span className='function'>on</span>(<span className='event'>event.NFTSale</span>, ...)</span>
            </div>
        </animated.div>
    ),
    ({ style }) => (
        <animated.div className={tpcn('__panel')} style={style}>
            <div className={tpcn('__typed')}>
                <span>spec.<span className='function'>on</span>(<span className='event'>event.BalancerTrade</span>, ...)</span>
            </div>
        </animated.div>
    ),
    ({ style }) => (
        <animated.div className={tpcn('__panel')} style={style}>
            <div className={tpcn('__typed')}>
                <span>spec.contracts.compound.<span className='function'>cToken</span>(<span className='string'>'cDAI'</span>)</span>
            </div>
        </animated.div>
    ),
    ({ style }) => (
        <animated.div className={tpcn('__panel')} style={style}>
            <div className={tpcn('__typed')}>
                <span>spec.contracts.uniswap.<span className='function'>pool</span>(<span className='string'>'USDC/ETH'</span>)</span>
            </div>
        </animated.div>
    ),
    ({ style }) => (
        <animated.div className={tpcn('__panel')} style={style}>
            <div className={tpcn('__typed')}>
            <span>spec.contracts.ens.<span className='function'>registry</span>()</span>
            </div>
        </animated.div>
    ),
    ({ style }) => (
        <animated.div className={tpcn('__panel')} style={style}>
            <div className={tpcn('__typed')}>
                <span>spec.<span className='function'>publicTable</span>(<span className='string'>'eth.nfts'</span>)</span>
            </div>
        </animated.div>
    ),
    ({ style }) => (
        <animated.div className={tpcn('__panel')} style={style}>
            <div className={tpcn('__typed')}>
                <span>spec.<span className='function'>publicTable</span>(<span className='string'>'solana.transactions'</span>)</span>
            </div>
        </animated.div>
    ),
    ({ style }) => (
        <animated.div className={tpcn('__panel')} style={style}>
            <div className={tpcn('__typed')}>
                <span>spec.<span className='function'>publicTable</span>(<span className='string'>'uniswap.trades'</span>)</span>
            </div>
        </animated.div>
    ),
]

const timing = {
    showAnnotationsDelay: 1800,
    toolkitItemInterval: 6000,
}

function LayersSection() {
    const observerCreated = useRef(false)
    const observerCalled = useRef(false)
    const [showAnno, setShowAnno] = useState(false)
    const [toolkitIndex, setToolkitIndex] = useState(0)
    const selectedLayer1BlockId = useMemo(() => {
        if (toolkitIndex === 0) {
            return null
        }
        if (toolkitIndex <= 3) {
            return layer1BlockIds.INDEXER
        }
        if (toolkitIndex <= 6) {
            return layer1BlockIds.CONTRACTS
        }
        return layer1BlockIds.TABLES
    }, [toolkitIndex])

    const showNextToolkitItem = useCallback(() => {
        setTimeout(() => {
            if (toolkitIndex >= toolkitComps.length - 1) {
                setToolkitIndex(0)
            } else {
                setToolkitIndex(toolkitIndex + 1)
            }
        }, timing.toolkitItemInterval)
    }, [toolkitIndex])

    const callShowAnno = useCallback(() => {
        if (showAnno) return
        setShowAnno(true)
        showNextToolkitItem()
    }, [showAnno, showNextToolkitItem])

    const createIntersectionObserver = useCallback(() => {
        const observer = new IntersectionObserver( entries => {
            if (entries && entries[0] && entries[0].isIntersecting && !observerCalled.current) {
                observerCalled.current = true
                setTimeout(callShowAnno, timing.showAnnotationsDelay)
            }
        }, { threshold: 0 })

        const el = document.querySelector( `#${observerTargetId}` )
        el && observer.observe( el )
    }, [callShowAnno])

    useEffect(() => {
        if (observerCreated.current) return
        observerCreated.current = true

        if ( window.IntersectionObserver ) {
            setTimeout( () => {
                isElementInView( `#${observerTargetId}` )
                    ? setTimeout(callShowAnno, timing.showAnnotationsDelay)
                    : createIntersectionObserver()
            }, 100 )
        } else {
            setTimeout( () => callShowAnno(), timing.showAnnotationsDelay )
        }
    }, [createIntersectionObserver, callShowAnno])

    const renderLayer1Block = useCallback(block => (
        <div key={block.id} className={pcn(
            '__block', 
            '__block--triple', 
            `__block--${block.id}`,
            block.id === selectedLayer1BlockId ? '__block--highlight' : '',
            selectedLayer1BlockId !== null && block.id !== selectedLayer1BlockId ? '__block--dim' : '',
        )}>
            <div className={pcn('__block-liner')}>
                <div className={pcn('__block-title')}>
                    <span>{block.title}</span>
                </div>
            </div>
            <div className={pcn('__block-layer-1-desc')}>
                <span>{block.desc}</span>
            </div>
        </div>
    ), [selectedLayer1BlockId])

    return (
        <div id='layers' className={cn(className, showAnno ? `${className}--show-anno` : '' )}>
            <div
                className='pixels'
                dangerouslySetInnerHTML={{ __html: pixels }}>
            </div>
            <div className={pcn('__liner')}>
                <div className={pcn('__text-container')}>
                    <div className={pcn('__name')}>
                        Shared ecosystem of data
                    </div>
                    <div className={pcn('__title')}>
                        Easily create custom data sources
                    </div>
                    <div className={pcn('__subtitle')}>
                        Spec's foundational APIs and open-source toolkit make it easy 
                        to create custom Live Objects &mdash; the sources of data that power all Live Tables and Columns.
                    </div>
                </div>
                <div className={pcn('__graphic-container')}>
                    <div className={pcn('__layer', '__layer--4', '__layer--blocks')}>
                        <div className={pcn('__block', '__block--single', `__block--${compTypes.LIVE_TABLE.theme}`)}>
                            <div className={pcn('__block-liner')}>
                                <div className={pcn('__block-title')}>
                                    Live Objects
                                </div>
                            </div>
                        </div>
                        <div className={pcn('__block-annotation', '__block-annotation--right')}>
                            <div></div>
                            <span>Specific web3 data models that fetch their own data and always stay up-to-date.</span>
                        </div>
                    </div>
                    <div className={pcn('__layer', '__layer--3', '__layer--blocks')}>
                        <div className={pcn('__block', '__block--double', `__block--${compTypes.EVENT_STREAM.theme}`)}>
                            <div className={pcn('__block-liner')}>
                                <div className={pcn('__block-title')}>
                                    { toPlural(compTypes.EVENT_STREAM.name) }
                                    <span>(Stream updates)</span>
                                </div>
                            </div>
                        </div>
                        <div className={pcn('__block', '__block--double', `__block--${compTypes.EDGE_FUNCTION.theme}`)}>
                            <div className={pcn('__block-liner')}>
                                <div className={pcn('__block-title')}>
                                    { window.innerWidth <= 1100 ? 'Deno Functions' : 'Edge Functions' }
                                    <span>(Fetch data)</span>
                                </div>
                            </div>
                        </div>
                        <div className={pcn('__block-annotation', '__block-annotation--left')}>
                            <span>Consumable events that ensure Live Objects are always up-to-date.</span>
                            <div></div>
                        </div>
                        <div className={pcn('__block-annotation', '__block-annotation--right')}>
                            <div></div>
                            <span>Serverless functions on Deno that Live Objects use to fetch their own data.</span>
                        </div>
                    </div>
                    <div className={pcn('__layer', '__layer--2', '__layer--toolkit')}>
                        <div className={pcn('__block-annotation', '__block-annotation--left')}>
                            <span>Access the foundational data you need to create higher-level web3 insights.</span>
                            <div></div>
                        </div>
                        <Toolkit
                            index={toolkitIndex}
                            comps={toolkitComps}
                            onRest={showNextToolkitItem}
                        />
                    </div>
                    <div className={pcn('__layer', '__layer--1', '__layer--blocks', `__layer--selected-layer-1-${selectedLayer1BlockId}`)}>
                        { layer1Blocks.map(renderLayer1Block) }
                        <div id={observerTargetId}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LayersSection
