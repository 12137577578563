import React, { useRef, useEffect, useState, useCallback, useImperativeHandle, forwardRef } from 'react'
import Select from 'react-select'
import $ from 'jquery'
import { cn, getPCN } from '../../../utils/classes'
import { noop } from '../../../utils/nodash'
import { noMod } from '../../../utils/formatters'
import { notNull } from '../../../utils/validators'
import caretIcon from '../../../svgs/svgjs/caret-down'

const className = 'select-input'
const pcn = getPCN(className)

function SelectInput(props, ref) {
    const {
        options = [],
        placeholder = '',
        classNamePrefix,
        disabled = false,
        onChange = noop,
        formatter = noMod,
        sanitizer = noMod,
        validator = notNull,
        isRequired = false,
        includeMenuIsOpen = false,
        tabSelectsValue = true,
        updateFromAbove = false,
        custom = {},
    } = props
    const inputRef = useRef()
    const wrapperRef = useRef()
    const [data, setData] = useState({
        value: sanitizer(props.value),
        isValid: true,
    })
    const [menuIsOpen, setMenuIsOpen] = useState(false)

    const focus = useCallback(() => inputRef.current?.focus(), [])

    const blur = useCallback(() => inputRef.current?.focus(), [])

    const focusSelect = useCallback((value, delay = 5000) => {
        delay > 0 && setMenuIsOpen(true)
        setTimeout(() => {
            setData({ value, isValid: true })
            onChange(value)
            delay > 0 && setMenuIsOpen(false)
        }, delay)
    }, [])

    useImperativeHandle(ref, () => ({
        focus,
        blur,
        getValue: () => data.value,
        validate,
        focusSelect,
    }))

    const validate = useCallback((updateState = true) => {
        if (!isRequired) return true

        const isValid = validator(data.value)

        if (updateState !== false && isValid !== data.isValid) {
            setData(prevState => ({ ...prevState, isValid }))
        }

        return isValid
    }, [isRequired, validator, data])

    const parseSelectValue = useCallback(value => {
        if ( value === null || value === [] ) {
            return null
        }
        return sanitizer( value.value )
    }, [sanitizer])

    const handleChange = useCallback(value => {
        const parsedValue = parseSelectValue(value)
        setData({ value: parsedValue, isValid: true })
        setTimeout(() => onChange(parsedValue), 5)
    }, [onChange, parseSelectValue])

    const getFormattedValue = useCallback(value => {
        if (value === null || value === undefined) return null
        const formattedValue = formatter(value)
        return options.find(opt => opt.value === formattedValue)
    }, [options, formatter])

    const renderDropdownIcon = useCallback(({ innerProps }) => (
        <span
            className={pcn('__dropdown-icon')}
            dangerouslySetInnerHTML={{ __html: caretIcon }}
            { ...innerProps }>
        </span>
    ), [])

    useEffect(() => {
        if (!updateFromAbove) return
        const sanitized = sanitizer(props.value)

        if (props.value !== data.value) {
            setData(prevState => ({ ...prevState, value: sanitized }))
        }
    }, [updateFromAbove, props.value, data.value, sanitizer])

    const formattedValue = getFormattedValue(data.value)

    const classes = cn(
        className,
        !data.isValid ? `${ className }--invalid` : '',
        disabled ? `${ className }--disabled` : '',
        formattedValue ? `${ className }--has-value` : '',
        isRequired ? `${ className }--required` : '',
        props.className,
    )

    let optionals = {}
    if (includeMenuIsOpen) {
        optionals.menuIsOpen = menuIsOpen
    }

    return (
        <div className={classes} ref={wrapperRef}>
            <Select
                classNamePrefix={classNamePrefix}
                placeholder={placeholder}
                options={options}
                value={formattedValue}
                menuPlacement='auto'
                isDisabled={disabled}
                openMenuOnFocus={true}
                menuShouldScrollIntoView={false}
                tabSelectsValue={tabSelectsValue}
                noOptionsMessage={() => 'No Results' }
                onChange={e => !disabled && handleChange(e)}
                components={{
                    DropdownIndicator: renderDropdownIcon,
                }}
                ref={inputRef}
                { ...optionals }
                { ...custom }
            />
        </div>
    )
}

SelectInput = forwardRef(SelectInput)
export default SelectInput
