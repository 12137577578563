import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { getPCN, cn } from '../../utils/classes'

const className = 'anno'
const pcn = getPCN(className)

function Anno(props, ref) {
    const { icon, title, children } = props
    const [show, setShow] = useState(props.show || false)

    useImperativeHandle(ref, () => ({
        show: () => setShow(true),
        hide: () => setShow(false),
    }))

    return (
        <div className={cn(className, show ? `${className}--show` : '', props.className)}>
            <div className={pcn('__liner')}>
                <div className={pcn('__top')}>
                    { icon ? <span dangerouslySetInnerHTML={{ __html: icon }}></span> : <span style={{ margin: 0, width: 14 }}></span> }
                    <span>{title}</span>
                </div>
                <div className={pcn('__bottom')}>
                    { children }
                </div>
            </div>
        </div>

    )
}

Anno = forwardRef(Anno)
export default Anno