import React, { useMemo, useRef, useCallback, useState, forwardRef, useImperativeHandle } from 'react'
import $ from 'jquery'
import { getPCN } from '../../../utils/classes'
import Typewriter from 'typewriter-effect'
import SelectInput from '../../shared/inputs/SelectInput'
import { noop } from '../../../utils/nodash'
import gearIcon from '../../../svgs/svgjs/gear-thin'
import closeIcon from '../../../svgs/svgjs/close'

const className = 'editable-live-columns'
const pcn = getPCN(className)

function EditableLiveColumns(props, ref) {
    const { liveObjectSpec = {}, selectLiveColumnFormatter = noop } = props
    const typeDef = useMemo(() => liveObjectSpec.typeDef, [liveObjectSpec])
    const properties = useMemo(() => typeDef.properties, [typeDef])
    const dataSourceOptions = useMemo(() => {
        const objectOpt = { value: typeDef.name, label: typeDef.name }
        const propertyOpts = properties.map(p => ({ value: p.name, label: `.${p.name}` }))
        return [ objectOpt, ...propertyOpts ]
    }, [typeDef, properties])
    const [newCols, setNewCols] = useState(props.newCols || [{}, {}])
    const manuallyChangedColName = useRef({})
    const inputRefs = useRef({})

    const setColData = useCallback((idx, key, value) => {
        const updatedNewCols = []

        let colData
        for (let i = 0; i < newCols.length; i++) {
            colData = newCols[i]
            if (i === idx) {
                colData[key] = value
            }
            updatedNewCols.push(colData)
        }

        if (key === 'columnName' && !manuallyChangedColName.current[idx]) {
            manuallyChangedColName.current[idx] = true
        }

        else if (key === 'dataSource' && !manuallyChangedColName.current[idx]) {
            // const dataSource = properties.find(p => p.name === value)
            // if (dataSource && dataSource.type !== 'hash') {
            //     updatedNewCols[idx].columnName = camelToSnake(value)
            // }
        }

        else if (key === 'formatter' && value?.type === 'key-val' && !manuallyChangedColName.current[idx]) {
            // updatedNewCols[idx].columnName = camelToSnake(value.config.key)
        }

        setNewCols(updatedNewCols)
    }, [newCols, properties])

    useImperativeHandle(ref, () => ({
        serialize: () => newCols,
        selectDataSource: (i, value, delay) => {
            const selectRef = (inputRefs.current.select || {})[i]
            selectRef && selectRef.focusSelect(value, delay)
        },
        typeColumnName: (i, value) => {
            const typewriterRef = (inputRefs.current.text || {})[i]
            const textContainerRef = (inputRefs.current.textContainer || {})[i]
            const placeholderRef = (inputRefs.current.textPlaceholder || {})[i]
            if (!typewriterRef || !textContainerRef || !placeholderRef) return

            // Show cursor
            const cursor = $(textContainerRef).find('.Typewriter__cursor')[0]
            if (cursor) {
                cursor.style.visibility = 'visible'
            }

            setTimeout(() => {
                // Start typing.
                typewriterRef
                // Hide placeholder.
                .callFunction(() => {
                    placeholderRef.style.opacity = 0
                })
                .typeString(value)
                // Hide cursor.
                .callFunction(() => {
                    setTimeout(() => {
                        if (cursor) {
                            cursor.style.visibility = 'hidden'
                        }
                    }, 50)
                }).start()
            }, 50)
        },
        setTransform: i => {
            const transformRef = (inputRefs.current.transform || {})[i]
            if (!transformRef) return
            $(transformRef).addClass('--set-transform')
        },
    }), [newCols])

    const addNewColumn = useCallback(() => {
        setNewCols(prevState => [ ...prevState, {}])
    }, [])

    const removeCol = useCallback((idx) => {
        const updatedNewCols = []
        for (let i = 0; i < newCols.length; i++) {
            if (i === idx) {
                continue
            }
            updatedNewCols.push(newCols[i])
        }
        setNewCols(updatedNewCols)

        // TODO: Do something to adjust manuallyChangedColName map
    }, [newCols])

    const customizeFormatter = useCallback((i, dataSource)=> {
        let property
        if (dataSource === liveObjectSpec.typeDef.name) {
            property = {
                isLiveObject: true,
                type: 'hash',
                name: ''
            }
        } else {
            property = properties.find(p => p.name === dataSource)
        }

        selectLiveColumnFormatter(liveObjectSpec, property, formatter => {
            setColData(i, 'formatter', formatter)
        })
    }, [selectLiveColumnFormatter, properties, liveObjectSpec, setColData])

    const setInputRef = useCallback((ref, i, key) => {
        if (!ref) return
        const m = inputRefs.current
        m[key] = m[key] || {}
        m[key][i] = ref
        inputRefs.current = m
    }, [])

    const renderFormatter = useCallback(formatter => {
        switch (formatter?.type) {
            case 'key-val':
                return `.${formatter.config.key}`
            case 'custom-function':
                return liveObjectSpec.name === 'NFT' ? 'setName()' : 'Custom'
            case 'stringify':
                return 'Stringify'
            default:
                return 'None'
        }
    }, [])

    const renderColInputs = useCallback(() => newCols.map((col, i) => (
        <div key={i} className={pcn('__col-input')}>
            <SelectInput
                className={pcn('__col-input-field', '__col-input-field--data-source')}
                classNamePrefix='spec'
                value={col.dataSource}
                options={dataSourceOptions}
                placeholder='.property'
                isRequired={true}
                includeMenuIsOpen={true}
                onChange={value => setColData(i, 'dataSource', value)}
                ref={r => setInputRef(r, i, 'select')}
            />
            <button
                className={pcn(
                    '__col-input-field',
                    '__col-input-field--formatter',
                    col.dataSource ? '' : '__col-input-field--disabled',
                    !!col.formatter?.type ? `__col-formatter-field--${col.formatter.type}` : ''
                )}
                ref={r => setInputRef(r, i, 'transform')}>
                <div className='input'>
                    <span>{ renderFormatter(col.formatter) }</span>
                </div>
            </button>
            <div className={pcn('__col-input-field', '__col-input-field--col-name')} ref={r => setInputRef(r, i, 'textContainer')}>
                <span className={pcn('__col-input-ph')} ref={r => setInputRef(r, i, 'textPlaceholder')}>
                    column_name
                </span>
                <Typewriter
                    onInit={r => setInputRef(r, i, 'text')}
                    options={{ delay: 50 }}                
                />
            </div>
            <div
                className={pcn('__col-input-icon-button', '__col-input-icon-button--settings')}
                dangerouslySetInnerHTML={{ __html: gearIcon }}>
            </div>
            <div
                className={pcn('__col-input-icon-button', '__col-input-icon-button--remove')}
                dangerouslySetInnerHTML={{ __html: closeIcon }}>
            </div>
        </div>
    )), [newCols, setColData, dataSourceOptions, customizeFormatter, renderFormatter, removeCol, setInputRef])

    return (
        <div className={className}>
            <div className={pcn('__liner')}>
                <div className={pcn('__header')}>
                    <span>Data Source</span>
                    <span>Transform</span>
                    <span>Column Name</span>
                </div>
                <div className={pcn('__col-inputs')}>
                    { renderColInputs() }
                </div>
                <div className={pcn('__action-buttons')}>
                    <button>
                        <span>+</span>
                        <span>New Column</span>
                    </button>
                </div>
            </div>
        </div>
    )
}

EditableLiveColumns = forwardRef(EditableLiveColumns)
export default EditableLiveColumns