import React, { useCallback } from 'react'
import { getPCN } from '../../../utils/classes'
import { toSlug } from '../../../utils/formatters'

const className = 'md-icon-bullets'
const pcn = getPCN(className)

function MdIconBullets({ items = [] }) {
    const renderItem = useCallback((item, i) => (
        <div key={i} className={pcn('__item', `__item--${toSlug(item.title)}`)}>
            <div className={pcn('__item-liner')}>
                <div className={pcn('__item-top')}>
                    { item.icon &&
                        <div
                            className={pcn('__item-icon')}
                            dangerouslySetInnerHTML={{ __html: item.icon }}>
                        </div> 
                    }
                    <div className={pcn('__item-title')}>
                        { item.title }
                    </div> 
                </div>
                <div className={pcn('__item-bottom')}>
                    <div className={pcn('__item-desc')}>
                        { item.desc }
                    </div>
                </div>
            </div>
        </div>
    ))

    return (
        <div className={className}>
            <div className={pcn('__liner')}>
                { items.map(renderItem) }
            </div>
        </div>
    )
}

export default MdIconBullets