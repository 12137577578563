
import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react'
import { getPCN } from '../../../utils/classes';
import { noop } from '../../../utils/nodash'
import $ from 'jquery'
import { getRandom } from '../../../utils/math'
import { isElementInView } from '../../../utils/doc'
import checkIcon from '../../../svgs/svgjs/bens-check'
import ex from '../../../data/examples'

const className = 'client'
const pcn = getPCN(className)
const editorClassName = 'editor'
const epcn = getPCN(editorClassName)
const observerTargetId = 'subscribeObserverTarget'

const generateUpdate = (tableName = 'aave_positions') => {
    const oldPosition = ex.aavePosition
        // .replace()

    const newPosition = ex.aavePosition
        // .replace()

    return ex.updateEventOutput
        .replace('{{event}}', 'UPDATE')
        .replace('{{timestamp}}', (new Date()).toISOString())
        .replace('{{table}}', tableName)
        .replace('{{new}}', newPosition)
        .replace('{{old}}', oldPosition)
}

const generateInsert = (tableName = 'token_holdings') => {
    const oldPosition = ex.aavePosition
    // .replace()

    const newPosition = ex.aavePosition
        // .replace()

    return ex.insertEventOutput
        .replace('{{event}}', 'INSERT')
        .replace('{{timestamp}}', (new Date()).toISOString())
        .replace('{{table}}', tableName)
        .replace('{{new}}', newPosition)
}

const examples = [
    {
        title: 'Updates',
        code: ex.subscribeToUpdates,
        showOutput: true,
        generateOutput: generateUpdate,
    },
    {
        title: 'Inserts',
        code: ex.subscribeToInserts,
        showOutput: true,
        generateOutput: generateInsert,
    },
    {
        title: 'All changes',
        code: ex.subscribeToAll,
        showOutput: true,
        generateOutput: () => {
            const random = Math.random()

            let tableName
            if (random < 0.33) {
               tableName = 'aave_positions'
            } else if (random >= 0.33 && random < 0.66) {
                tableName = 'token_holdings'
            } else {
                tableName = 'nfts'
            }
            
            if (Math.random() < 0.5) {
                return generateInsert(tableName)
            } else {
                return generateUpdate(tableName)
            }
        }
    },              
]

const features = [
    'Simple websocket subscriptions',
    'Track inserts, updates, and deletes',
    window.innerWidth <= 401 ? 'Listen to schemas, tables, or rows' : 'Listen with schema, table, or row-level granularity',
]

const timing = {
    outputFloor: 0.7,
    outputCeiling: 6,
}

function SubscribeSection() {
    const [exampleIndex, setExampleIndex] = useState(0)
    const currentExample = useMemo(() => examples[exampleIndex] || {}, [exampleIndex, examples])
    const getOutput = useRef(currentExample.generateOutput)
    const [output, setOutput] = useState([])
    const outputTimer = useRef()
    const startedOutputStream = useRef(false)
    const outputContainerRef = useRef()
    const scrollTargetRef = useRef()
    const autoScroll = useRef(true)
    const observerCreated = useRef(false)
    const observerCalled = useRef(false)

    const generateOutput = useCallback(() => {
        clearTimeout(outputTimer.current)
        if (currentExample.showOutput && currentExample.generateOutput) {
            setOutput(prevState => [ ...(prevState.length > 20 ? [] : prevState), getOutput.current() ])

            if (autoScroll.current) {
                setTimeout(() => {
                    outputContainerRef.current && $(outputContainerRef.current).animate({ 
                        scrollTop: scrollTargetRef.current?.offsetTop - 440
                    }, 150)
                }, 30)    
            }

            outputTimer.current = setTimeout(() => {
                generateOutput()
            }, getRandom(timing.outputFloor, timing.outputCeiling) * 1000)
        }
    }, [currentExample])

    const startAnimation = useCallback(() => {
        if (!startedOutputStream.current && currentExample.showOutput && currentExample.generateOutput) {
            startedOutputStream.current = true
            setTimeout(generateOutput, 500)
        }
    }, [currentExample, generateOutput])

    const createIntersectionObserver = useCallback(() => {
        const observer = new IntersectionObserver( entries => {
            if (entries && entries[0] && entries[0].isIntersecting && !observerCalled.current) {
                observerCalled.current = true
                setTimeout(startAnimation, timing.initialDelay)
            }
        }, { threshold: 0 })

        const el = document.querySelector( `#${observerTargetId}` )
        el && observer.observe( el )
    }, [startAnimation])

    useEffect(() => {
        if (observerCreated.current) return
        observerCreated.current = true

        if ( window.IntersectionObserver ) {
            setTimeout( () => {
                isElementInView( `#${observerTargetId}` )
                    ? setTimeout(startAnimation, timing.initialDelay)
                    : createIntersectionObserver()
            }, 500 )
        } else {
            setTimeout( () => startAnimation(), timing.initialDelay )
        }
    }, [createIntersectionObserver, startAnimation])

    useEffect(() => {
        getOutput.current = currentExample.generateOutput
    }, [currentExample])
    
    const renderSelectExamplesBar = useCallback(() => (
        <div className={epcn(
            '__examples-titles', 
            `__examples-titles--index-${exampleIndex}`,
            `__examples-titles--${currentExample.title?.toLowerCase()?.replaceAll(' ', '-')}`,
        )}>
            <div className={epcn('__selected-example-slider')}></div>
            { examples.map((example, i) => (
                <div
                    key={i}
                    className={epcn(
                        '__examples-title', 
                        i === exampleIndex ? '__examples-title--selected' : '',
                    )}
                    onClick={i === exampleIndex ? noop : () => {
                        setOutput([])
                        autoScroll.current = true
                        setExampleIndex(i)
                    } }>
                    <span>{example.title}</span>
                </div>
            ))}
        </div>
    ), [examples, exampleIndex, currentExample])

    const renderFeature = useCallback((feature, i) => (
        <div className={pcn('__feature')} key={i}>
            <span dangerouslySetInnerHTML={{ __html: checkIcon }}></span>
            <span>{feature}</span>
        </div>
    ), [])

    return (
        <div className={pcn('__section', '__section--subscribe')}>
            <div className={pcn('__liner')}>
                <div className={pcn('__editor-container')}>
                    <div className={editorClassName}>
                        { renderSelectExamplesBar() }
                        <div className={epcn('__body')}>
                            <div
                                className={epcn('__code')}
                                dangerouslySetInnerHTML={{ __html: currentExample.code }}>
                            </div>
                            { currentExample.showOutput && 
                                <div
                                    id={observerTargetId}
                                    className={pcn('__examples-output')}
                                    ref={outputContainerRef}
                                    onWheel={() => { autoScroll.current = false }}>
                                    { output.map((outputCode, i) => (
                                        <div
                                            key={i}
                                            className={pcn('__examples-output-group')}
                                            dangerouslySetInnerHTML={{ __html: outputCode }}>    
                                        </div>
                                    ))}
                                    { <div id='scrollTarget' ref={scrollTargetRef}></div> }
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className={pcn('__text-container')}>
                    <div className={pcn('__text-container-liner')}>
                        <div className={pcn('__name')}>
                            Table Subscriptions
                        </div>
                        <div className={pcn('__title')}>
                            Subscribe & react to realtime data changes
                        </div>
                        <div className={pcn('__subtitle')}>
                            Stop polling and just listen. 
                            Get notified over websockets as soon as your table data changes.
                            Easily build reactive dashboards, event-driven applications, or powerful monitoring tools.
                        </div>
                    </div>
                    <div className={pcn('__features')}>
                        { features.map(renderFeature) }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubscribeSection