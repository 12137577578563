import { nftSpec, aaveUserPositionSpec, tokenBalanceSpec, roleGrantedSpec } from './specs'

export const tables = {
    empty: {
        name: 'empty',
        columns: [{name: 'fake'}],
        records: [
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
            {},
        ],
        status: null,
    },
    wallets: {
        name: 'wallets',
        desc: 'Crypto wallets that our users sign in with.',
        columns: [
            {
                name: 'id',
                isPrimaryKey: true,
                type: 'int8',
            },
            {
                name: 'address',
                type: 'varchar',
                isLiveLinkColumn: true,
            },
            {
                name: '',
                type: '',
                liveSource: 'domain',
                hide: true,
            },
            {
                name: '',
                type: '',
                liveSource: 'avatar',
                hide: true,
            },
        ],
        records: [
            {
                id: 1,
                address: '0xB4837C3507C5C19125ec14FE2FF9Bf7b16eeb806',
                domain: 'automin.eth',
                avatar: 'https://ipfs.io/ipfs/QmSP4nq9fnN9dAiCj42ug9Wa79rqmQerZXZch82VqpiH7U/image.gif',
            },
            {
                id: 2,
                address: '0xba1D3fd5a923C6Bf543CB4Cd0712C096e39DaFA2',
                domain: 'bxn.eth',
                avatar: 'https://ipfs.io/ipfs/A8c9ac321902h43h4',
            },
            {
                id: 3,
                address: '0x67A6dCaE64A0a3FaB7F677441b8809ac7b182768',
                domain: '0xmerkle.eth',
                avatar: 'https://ipfs.io/ipfs/7k9a7cclasdf8',
            },
            {
                id: 4,
                address: '0x9ced4A1e7153B42d2A844C223AA8e425c1BD7B61',
                domain: null,
                avatar: null,
            },
            {
                id: 5,
                address: '0xBAe995278B1102CEb8E86d6eB87d5f0C1caa3986',
                domain: 'j1mmie.eth',
                avatar: null,
            },
            {
                id: 6,
                address: '0xf9c48a80573ac706C7806B023B9Bad138DfAD943',
                domain: 'tylano.eth',
                avatar: 'https://ipfs.io/ipfs/12xa7sdfas89faf0',
            },
            {
                id: 7,
                address: '0x1cd3f145406957d94c5DdCFE2387078EE75F5E90',
                domain: 'leeor.eth',
                avatar: 'https://ipfs.io/ipfs/el223784qwa7ycas7',
            },
            {
                id: 8,
                address: '0x6f8dEda24e409A35a4bB3B6e651a8F4de806C783',
                domain: 'brihu.eth',
                avatar: null,
            },
            {
                id: 9,
                address: '0xD4206305Bc1eCf165570256C029E246cCB73E630',
                domain: 'gaurav.eth',
                avatar: 'https://ipfs.io/ipfs/4ila67ac77230023',
            },
            {
                id: 10,
                user_id: 10,
                address: '0x38add435C37355BCB6dEb123FCf1596F3BF7CAD9',
                domain: '0xmarine.eth',
                avatar: 'https://ipfs.io/ipfs/9avbabsdgfuqw34',
            },
            {
                id: 11,
                address: '0x23a9426ea49c8a0c01aba5c3B8f985cb0FFbd722',
                domain: null,
                avatar: null,
            },
            {
                id: 12,
                address: '0x6efcCe360F76f488a5AddA8981714c590b6bA993',
                domain: 'ansgar.eth',
                avatar: 'https://ipfs.io/ipfs/aas273as98fA7a7saQA',
            },
            {
                id: 13,
                address: '0x81a0C75D7870e70ad928A2cD5Fc4F46c172fEE7A',
                domain: 'guanzo.eth',
                avatar: 'https://ipfs.io/ipfs/We928374brf',
            },
            {
                id: 14,
                address: '0x490861A6D826612a03a49feE73802EE3A52393Aa',
                domain: 'melvillian.eth',
                avatar: 'https://ipfs.io/ipfs/PdCac67a78123h2As',
            },
        ],
        status: null,
    },
    nfts: {
        name: 'nfts',
        desc: 'NFTs owned by wallet',
        columns: [
            {
                name: 'id',
                isPrimaryKey: true,
                type: 'int8',
            },
            {
                name: 'wallet_id',
                isForeignKey: true,
                type: 'int8',
            },
            {
                name: 'contract',
                type: 'varchar',
                isLiveLinkColumn: true,
                liveSource: 'NFT',
            },
            {
                name: 'token_id',
                type: 'int8',
                isLiveLinkColumn: true,
                liveSource: 'NFT',
            },
            {
                name: 'collection',
                type: 'varchar',
                liveSource: 'collection',
            },
            {
                name: 'name',
                type: 'varchar',
                liveSource: 'name',
            },
            {
                name: 'description',
                type: 'varchar',
                liveSource: 'description',
            },
            {
                name: 'image',
                type: 'varchar',
                liveSource: 'image',
            },
            {
                name: 'attributes',
                type: 'json',
                liveSource: 'attributes',
            },
        ],
        records: [
            {
                id: 1,
                wallet_id: 1,
                collection: 'Moonbirds',
                contract: '0x23581767a106ae21c074b2276d25e5c3e136a68b',
                token_id: 8563,
                name: '#8563',
                description: null,
                image: 'https://live---metadata-5covpqijaa-uc.a.run.app/images/8563',
                attributes: '[{"trait_type":"Eyes","value":...'
            },
            {
                id: 2,
                wallet_id: 1,
                collection: 'Bored Ape Yacht Club',
                contract: '0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d',
                token_id: 230,
                name: 'Bored Ape Yacht Club #230',
                description: null,
                image: 'https://ipfs.io/ipfs/Qmc64i1kynJBWVQqLuWmtWGgMp67Vxdp8uyvi2eRwqLcnQ',
                attributes: '[{"trait_type":"Fur","value":...'
            },
            {
                id: 3,
                wallet_id: 1,
                collection: 'BEANZ',
                contract: '0x306b1ea3ecdf94ab739f1910bbda052ed4a9f949',
                token_id: 1020,
                name: 'Bean #1020',
                description: null,
                image: 'https://ikzttp.mypinata.cloud/ipfs/QmPZKyuRw4nQTD6S6R5HaNAXwoQVMj8YydDmad3rC985WZ/...',
                attributes: '[{"trait_type":"Background","value":...'
            },
            {
                id: 4,
                wallet_id: 2,
                collection: 'Dippies',
                contract: '0x82f5ef9ddc3d231962ba57a9c2ebb307dc8d26c2',
                token_id: 365,
                name: 'Dippie #365',
                description: 'Funk, meet web 3. A collection of 8,888 unique digital hippies',
                image: 'https://ipfs.io/ipfs/QmQMerEzZVgAqogVzfGLqM2Q758Tfs56PdoPZbpo7Q5CDS',
                attributes: '[{"trait_type":"Type","value":...'
            },
            {
                id: 5,
                wallet_id: 2,
                collection: 'alien frens',
                contract: '0x123b30e25973fecd8354dd5f41cc45a3065ef88c',
                token_id: 9782,
                name: 'alien fren #9782',
                description: 'alien frens are a collection of 10k randomly generated aliens',
                image: 'https://gateway.pinata.cloud/ipfs/QmbJ3XshhuW5qiJJvAks',
                attributes: '[{"trait_type":"Background","value":...'
            },
            {
                id: 6,
                wallet_id: 2,
                collection: 'Bored Ape Yacht Club',
                contract: '0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d',
                token_id: 4322,
                name: '#4332',
                description: null,
                image: 'https://ipfs.io/ipfs/QmchjX8GZq11GnB7rNE5kpqe1r2Kc7SXkXyPzhUZPXLGrt',
                attributes: '[{"trait_type":"Fur","value":...'   
            },
            {
                id: 7,
                wallet_id: 2,
                collection: 'Doodles',
                contract: '0x8a90cab2b38dba80c64b7734e58ee1db38b8992e',
                token_id: 6231,
                name: 'Doodle #6231',
                description: 'A community-driven collectibles project featuring art by Burnt Toast. Doodles come ',
                image: 'https://ipfs.io/ipfs/QmRQcYqDLbUkgc72kyj5kA2J7bLcVUHJhovtuxWx7CE4zh',
                attributes: '[{"trait_type":"face","value":...'               
            },
            {
                id: 8,
                wallet_id: 2,
                collection: 'Meebits',
                contract: '0x7bd29408f11d2bfc23c34f18275bbf23bb716bc7',
                token_id: 5629,
                name: 'Meebit #5629',
                description: 'Meebit #5629',
                image: 'http://meebits.app/meebitimages/characterimage',
                attributes: '[{"trait_type":"Type","value":...'
            },
            {
                id: 9,
                wallet_id: 3,
                collection: 'Moonbirds',
                contract: '0x23581767a106ae21c074b2276d25e5c3e136a68b',
                token_id: 6010,
                name: '#6010',
                description: null,
                image: 'https://live---metadata-5covpqijaa-uc.a.run.app/images/6010',
                attributes: '[{"trait_type":"Eyes","value":...'
            },
            {
                id: 10,
                wallet_id: 3,
                collection: 'Moonbirds' ,
                contract: '0x23581767a106ae21c074b2276d25e5c3e136a68b',
                token_id: 820,
                name: '#820',
                description: null,
                image: 'https://live---metadata-5covpqijaa-uc.a.run.app/images/820',
                attributes: '[{"trait_type":"Eyes","value":...'            
            },
            {
                id: 11,
                wallet_id: 3,
                collection: 'Invisible Friends',
                contract: '0x59468516a8259058bad1ca5f8f4bff190d30e066',
                token_id: 2231,
                name: 'Invisible Friends #2231',
                description: '5,000 animated Invisible Friends hiding in the metaverse. A collection by',
                image: 'https://ipfs.io/ipfs/QmXmuSenZRnofhGMz2NyT3Yc4Zrty1TypuiBKDcaB',
                attributes: '[{"trait_type":"Feet","value":...'
            },
            {
                id: 12,
                wallet_id: 3,
                collection: 'Bored Ape Yacht Club',
                contract: '0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d',
                token_id: 732,
                name: 'Bored Ape Yacht Club #732',
                description: null,
                image: 'https://ipfs.io/ipfs/QmeKbLGcfjfQfnvZtBgogqVAKu87MXmh38KsGvv2MjeZN6',
                attributes: '[{"trait_type":"Earring","value":...'
            },
            {
                id: 13,
                wallet_id: 5,
                collection: 'Moonbirds' ,
                contract: '0x23581767a106ae21c074b2276d25e5c3e136a68b',
                token_id: 1231,
                name: '#1231',
                description: null,
                image: 'https://live---metadata-5covpqijaa-uc.a.run.app/images/1231',
                attributes: '[{"trait_type":"Eyes","value":...',
            },
            {
                id: 14,
                wallet_id: 5,
                collection: 'BEANZ',
                contract: '0x306b1ea3ecdf94ab739f1910bbda052ed4a9f949',
                token_id: 8792,
                name: 'Bean #8792',
                description: null,
                image: 'https://ikzttp.mypinata.cloud/ipfs/QmPZKyuRw4nQTD6S6R5HaNAXwoQVMj8YydDmad3rC985WZ/...',
                attributes: '[{"trait_type":"Background","value":...'
            },
        ],
        status: null,
        isLiveTable: true,
        liveObjectSpec: nftSpec,
    },
    aaveUserPositions: {
        name: 'aave_positions',
        desc: 'An Aave user\'s current asset position',
        columns: [
            {
                name: 'id',
                isPrimaryKey: true,
                type: 'int8',
            },
            {
                name: 'wallet_id',
                isForeignKey: true,
                type: 'int8',
            },
            {
                name: 'asset',
                type: 'varchar',
                isLiveLinkColumn: true,
                liveSource: 'Aave Position',
            },
            {
                name: 'deposited',
                type: 'float8',
                liveSource: 'deposited',
                recordIdsToUpdate: [
                    2,
                    6,
                    8,
                    12,
                    13,
                ],
                incrementRange: [0.1, 2],
                numDecimals: 2,
            },
            {
                name: 'variable_debt',
                type: 'float8',
                liveSource: 'variableDebt',
                recordIdsToUpdate: [
                    5,
                    9,
                    13,
                ],
                numDecimals: 3,
                incrementRange: [0.01, 0.15],
            },
            {
                name: 'stable_debt',
                type: 'float8',
                liveSource: 'stableDebt',
            },
            {
                name: 'updated_at',
                type: 'timestampz',
            },
        ],
        records: [
            {
                id: 1,
                wallet_id: 1,
                asset: 'DAI',
                deposited: 2.75,
                stable_debt: 0,
                variable_debt: 0,
                updated_at: '',
            },
            {
                id: 2,
                wallet_id: 1,
                asset: 'UNI',
                deposited: 0,
                stable_debt: 0,
                variable_debt: 0.582,
                updated_at: '',
            },
            {
                id: 3,
                wallet_id: 1,
                asset: 'LINK',
                deposited: 0,
                stable_debt: 0,
                variable_debt: 0.831,
                updated_at: '',
            },
            {
                id: 4,
                wallet_id: 5,
                asset: 'ZRX',
                deposited: 1.22,
                stable_debt: 0, 
                variable_debt: 0,
                updated_at: '',
            },
            {
                id: 5,
                wallet_id: 5,
                asset: 'UNI',
                deposited: 4.1,
                stable_debt: 0,
                variable_debt: 0.658,
                updated_at: '',
            },
            {
                id: 6,
                wallet_id: 11,
                asset: 'TUSD',
                deposited: 0.345,
                stable_debt: 0,
                variable_debt: 0,
                updated_at: '',
            },
            {
                id: 7,
                wallet_id: 13,
                asset: 'RAI',
                deposited: 0,
                stable_debt: 0,
                variable_debt: 0.121,
                updated_at: '',
            },
            {
                id: 8,
                wallet_id: 24,
                asset: 'GUSD',
                deposited: 0,
                stable_debt: 0,
                variable_debt: 0.43,
                updated_at: '',
            },
            {
                id: 9,
                wallet_id: 24,
                asset: 'LINK',
                deposited: 5.18,
                stable_debt: 0,
                variable_debt: 0,
                updated_at: '',
            },
            {
                id: 10,
                wallet_id: 24,
                asset: 'MANA',
                deposited: 1.38,
                stable_debt: 0,
                variable_debt: 0,
                updated_at: '',
            },
            {
                id: 11,
                wallet_id: 26,
                asset: 'DPI',
                deposited: 0,
                stable_debt: 0,
                variable_debt: 0.405,
                updated_at: '',
            },
            {
                id: 12,
                wallet_id: 29,
                asset: 'REN',
                deposited: 3.88,
                stable_debt: 0,
                variable_debt: 0,
                updated_at: '',
            },
            {
                id: 13,
                wallet_id: 40,
                asset: 'SUSD',
                deposited: 3.4,
                stable_debt: 0,
                variable_debt: 0,
                updated_at: '',
            },
            {
                id: 14,
                wallet_id: 41,
                asset: 'FRAX',
                deposited: 0,
                stable_debt: 0,
                variable_debt: .87,
                updated_at: '',
            },
        ],
        status: null,
        isLiveTable: true,
        liveObjectSpec: aaveUserPositionSpec,
    },
    borrowers: {
        name: 'borrowers',
        desc: 'Tracked lending accounts',
        columns: [
            {
                name: 'id',
                isPrimaryKey: true,
                type: 'int8',
            },
            {
                name: 'address',
                type: 'varchar',
                isLiveLinkColumn: true,
                liveSource: 'Aave User Health',
            },
            {
                name: 'aave_health_factor',
                type: 'float8',
                liveSource: 'Aave User Health',
            },
        ],
        records: [
            {
                id: 1,
                address: '0x3e555441c42637e50A83132b7238A79B6000F34B',
                aave_health_factor: 2.131,
            },
            {
                id: 2,
                address: '0xBa7aaCD9E740757b9452CD9FbE21c26fefA00a81',
                aave_health_factor: 4.21,
            },
            {
                id: 3,
                address: '0xe4eA845540DA9C3116AdF95693290e7808dbe239',
                aave_health_factor: 1.34,
            },
            {
                id: 4,
                address: '0x7Ac42385124bD58cfE6Fa1f77d2fdD1BE6D91B1F',
                aave_health_factor: 1.093,
            },
            {
                id: 5,
                address: '0xb111d429A0CCc633058EFb7f419301b7acFb8fB9',
                aave_health_factor: 1.563,
            },
            {
                id: 6,
                address: '0xA38Fe3c10B30AEfC91B1be84142e36a1aD602250',
                aave_health_factor: 2.13,
            },
            {
                id: 7,
                address: '0xf123B90Cad936201c99e58aaBc3b1e9362baE46a',
                aave_health_factor: 3.32,
            },
            {
                id: 8,
                address: '0x0550E889dcBaF659fdacAEe5e5dAEAaE906974B9',
                aave_health_factor: 1.191,
            },
            {
                id: 9,
                address: '0x1269A56A6eafdb8F84E7D6026efC344Fa2B2E025',
                aave_health_factor: 4.812,
            },
            {
                id: 10,
                address: '0x3012846C89A159268BbeA3D6F35aeA5373456411',
                aave_health_factor: 2.04,
            },
            {
                id: 11,
                address: '0x93B7C50B6B7F94b7a809baCAaa379f6D15105025',
                aave_health_factor: 1.15,
            },
        ],
        status: null,
        isLiveTable: false,
    },
    tokenBalances: {
        name: 'token_balances',
        desc: '...',
        columns: [
            {
                name: 'id',
                isPrimaryKey: true,
                type: 'int8',
                liveSource: 'Aave Position',
            },
            {
                name: 'token',
                type: 'varchar',
                isLiveLinkColumn: true,
                liveSource: 'Aave Position',
            },
            {
                name: 'owner',
                type: 'varchar',
                isLiveLinkColumn: true,
                liveSource: 'Aave Position',
            },
            {
                name: 'balance',
                type: 'varchar',
                liveSource: 'Aave Position',
                recordIdsToUpdate: [
                    1,
                    2,
                    4,
                    6,
                    8,
                    9,
                    10,
                ],
                incrementRange: [1, 8],
                numDecimals: 3,
            },
            {
                name: 'chain_id',
                type: 'int',
                isLiveLinkColumn: true,
                liveSource: 'Aave Position',
            }
        ],
        records: [
            {
                id: 1,
                token: '0xcc7bb2d219a0fc08033e130629c2b854b7ba9195',
                owner: '0xeafdffe380f1ba4d4e2016cfc8b12cad1bb1698c',
                balance: 50.0,
                chain_id: '5',
                updated_at: '2023-08-05 04:07:48+00',
            },
            {
                id: 2,
                token: '0x924347b55a155525fdb0c0d5a6f4f91ec3068423',
                owner: '0x7a0d7df477a9fc055a967b8872bacf8fe270c3d2',
                balance: 301740.577,
                chain_id: '1',
                updated_at: '2023-08-05 04:07:23+00',
            },
            {
                id: 3,
                token: '0x925206b8a707096ed26ae47c84747fe0bb734f59',
                owner: '0xed44602e2c729a7403eb94e241598d7222175a60',
                balance: 0.752,
                chain_id: '1',
                updated_at: '2023-08-05 04:07:23+00',
            },
            {
                id: 4,
                token: '0x36fad6aa2e6c802afc8e637b028ece5fd74fac2a',
                owner: '0x42910a69effb47b8f8f2cb726fd964a654914131',
                balance: 109.778,
                chain_id: '1',
                updated_at: '2023-08-05 04:07:23+00',
            },
            {
                id: 5,
                token: '0x0f7b3f5a8fed821c5eb60049538a548db2d479ce',
                owner: '0xd5ef9f7c290a7b5ae93d59c4eb8a25e2a9c31f9c',
                balance: 1306.170,
                chain_id: '1',
                updated_at: '2023-08-05 04:07:11+00',
            },
            {
                id: 6,
                token: '0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce',
                owner: '0x9b4e494d7a899fa7995f62a1e92cc809658875c0',
                balance: 1500000.0,
                chain_id: '1',
                updated_at: '2023-08-05 04:07:11+00',
            },
            {
                id: 7,
                token: '0x0f5d2fb29fb7d3cfee444a200298f468908cc942',
                owner: '0xe465bc363bc20980158ae677fb3d488b7fb201b6',
                balance: 1.096,
                chain_id: '1',
                updated_at: '2023-08-05 04:07:11+00',
            },
            {
                id: 8,
                token: '0xbbbbca6a901c926f240b89eacb641d8aec7aeafd',
                owner: '0xe465bc363bc20980158ae677fb3d488b7fb201b6',
                balance: 4.232,
                chain_id: '1',
                updated_at: '2023-08-05 04:07:11+00',
            },
            {
                id: 9,
                token: '0x3845badade8e6dff049820680d1f14bd3903a5d0',
                owner: '0xe465bc363bc20980158ae677fb3d488b7fb201b6',
                balance: 2.503,
                chain_id: '1',
                updated_at: '2023-08-05 04:07:11+00',
            },
            {
                id: 10,
                token: '0xdac17f958d2ee523a2206206994597c13d831ec7',
                owner: '0x0bb8b9194ce90464fc0fa1c58ebb0c515bfaca50',
                balance: 10.016,
                chain_id: '1',
                updated_at: '2023-08-05 04:07:11+00',
            },
            {
                id: 11,
                token: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
                owner: '0x51d4263d37e9643f452abbb3e2f13e384211818f',
                balance: 1.01,
                chain_id: '1',
                updated_at: '2023-08-05 04:07:11+00',
            },
        ],
        status: null,
        isLiveTable: true,
        liveObjectSpec: tokenBalanceSpec,
    },
    roleGranted: {
        nsp: 'allov2',
        name: 'RoleGranted',
        displayName: 'RoleGranted Events',
        desc: '...',
        columns: [
            {
                name: 'id',
                isPrimaryKey: true,
                type: 'int8',
                liveSource: '...',
            },
            {
                name: 'role',
                type: 'varchar',
                liveSource: '...',
            },
            {
                name: 'account',
                type: 'varchar',
                liveSource: '...',
            },
            {
                name: 'sender',
                type: 'varchar',
                liveSource: '...',
            },
            {
                name: 'chainId',
                type: 'int',
                isLiveLinkColumn: true,
                liveSource: '...',
            }
        ],
        records: [
            {
                id: 1,
                role: '0x815b5a78dc333d344c7df9da23c04dbd432015cc701876ddb9ffe850e6882747',
                account: '0xba0bbfb320486119f97ea9c4671de5e45441b2b7',
                sender: '0x4596039a69602b115752006ef8425f43d6e80a6f',
                chainId: '1',
                updated_at: '2023-08-05 04:07:48+00',
            },
            {
                id: 2,
                role: '0xb2a02f1bb0d07456d929eb7bf31c49b69f756ba90f64c0288488fb1a4cd6abef',
                account: '0x5cdb35fadb8262a3f88863254c870c2e6a848cca',
                sender: '0xe8e1543235e6c35c656ef0b28526c61571583f4b',
                chainId: '1',
                updated_at: '2023-08-05 04:07:23+00',
            },
            {
                id: 3,
                role: '0xf63bbcfe56807e4a1f97aff8571dd4477c91546d1fb9dbc1b2d8bc09f1a465d7',
                account: '0x4596039a69602b115752006ef8425f43d6e80a6f',
                sender: '0xb8cef765721a6da910f14be93e7684e9a3714123',
                chainId: '10',
                updated_at: '2023-08-05 04:07:23+00',
            },
            { 
                id: 4,
                role: '0xc2e39878a8c7f6889da39e49fc4ec0144a95434f92521d73b979351a7d161bf4',
                account: '0xe8e1543235e6c35c656ef0b28526c61571583f4b',
                sender: '0xb8cef765721a6da910f14be93e7684e9a3714123',
                chainId: '5',
                updated_at: '2023-08-05 04:07:23+00',
            },
            {
                id: 5,
                role: '0xec3b888b11acdf6cab7a35c97b2435487c93f28e86e7ad456483dd46ba5f3dae',
                account: '0x136d473d0c3965d7630f346152101e741074825f',
                sender: '0x3daf2eae4fe3232ed8a29c5e1be6eeba81c1cfd6',
                chainId: '8453',
                updated_at: '2023-08-05 04:07:11+00',
            },
            {
                id: 6,
                role: '0x78a40a0ce0221ae1b85397940de471d9ee506062b122c6826dba53beb8ced63c',
                account: '0xe7eb5d2b5b188777df902e89c54570e7ef4f59ce',
                sender: '0x387b58a25b9ea973a562afb09670a272f3a29d9a',
                chainId: '8453',
                updated_at: '2023-08-05 04:07:11+00',
            },
            {
                id: 7,
                role: '0xaf8125e8c20563f07a5a03fcc260c24f43edb1286dd5a2aa0103883d1660e654',
                account: '0xa6725238a5f4cf0253ca0f59d2c3a1d9b6ecc27f',
                sender: '0x51f9cc45f0a006ca1e0ee90b1c7b663703e0c05c',
                chainId: '1',
                updated_at: '2023-08-05 04:07:11+00',
            },
            {
                id: 8,
                role: '0x4760211e817440009a46b7d20c7307fed767f69a2bb629f98742d185a0e8dab5',
                account: '0x70cba46d2e933030e2f274ae58c951c800548aef',
                sender: '0x51f9cc45f0a006ca1e0ee90b1c7b663703e0c05c',
                chainId: '424',
                updated_at: '2023-08-05 04:07:11+00',
            },
            {
                id: 9,
                role: '0xdc9b7349f52b60250399623245a1879367e9b156dfd460ce2a315af1e7cc7ebc',
                account: '0x020b4110534cc71f4167948f25d3ae00e567363e',
                sender: '0xbad52370b73d006199f99cc1612aea0e63812640',
                chainId: '42161',
                updated_at: '2023-08-05 04:07:11+00',
            },
            {
                id: 10,
                role: '0xdd24c7e8a35d4a95f0f4b8f5c819b7c2a0029807a39b0a2f8cb4bf51141816ff',
                account: '0x7a33615d12a12f58b25c653dc5e44188d44f6898',
                sender: '0x7667f3f416c9a81f9a0bd91dec4a1b91480b6fec',
                chainId: '137',
                updated_at: '2023-08-05 04:07:11+00',
            },
            {
                id: 11,
                role: '0x6f3f273f124d15d82c410ebd6398273d133e0821677f02a81271bc6538486c00',
                account: '0x533d5e63226aab49c7ba3c3387ffa09e0baf2f10',
                sender: '0x0356b42ca8927146b89092d41f1e3aba0d3fedb5',
                chainId: '10',
                updated_at: '2023-08-05 04:07:11+00',
            },
        ],
        status: null,
        isLiveTable: true,
        liveObjectSpec: roleGrantedSpec,
    },
    holder: {
        nsp: 'lido',
        name: 'Holder',
        displayName: 'Lido Holders',
        desc: '...',
        columns: [
            {
                name: 'id',
                isPrimaryKey: true,
                type: 'int8',
                liveSource: '...',
            },
            {
                name: 'address',
                type: 'varchar',
                liveSource: '...',
                isLiveLinkColumn: true,
            },
            {
                name: 'hasBalance',
                type: 'boolean',
                liveSource: '...',
            },
            {
                name: 'updatedAt',
                type: 'timestamptz',
                liveSource: '...',
            },
            {
                name: 'chainId',
                type: 'int',
                liveSource: '...',
                isLiveLinkColumn: true,
            },
        ],
        records: [
            {
                id: 1,
                address: '0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0',
                hasBalance: 'true',
                chainId: '1',
                updatedAt: '2023-11-01 19:00:23+00',
            },
            {
                id: 2,
                address: '0x3f9ea879e489e028732029d4a691f4c0f8174cb0',
                hasBalance: 'true',
                chainId: '5',
                updatedAt: '2023-11-01 19:00:00+00',
            },
            {
                id: 3,
                address: '0x889edc2edab5f40e902b864ad4d7ade8e412f9b1',
                hasBalance: 'true',
                chainId: '1',
                updatedAt: '2023-11-01 18:56:47+00',
            },
            {
                id: 4,
                address: '0x4c49d4bd6a571827b4a556a0e1e3071da6231b9d',
                hasBalance: 'true',
                chainId: '1',
                updatedAt: '2023-11-01 18:56:11+00',
            },
            { 
                id: 5,
                address: '0x5b16b56d50ed8a05a3598ae0299aa2cab975e3a9',
                hasBalance: 'true',
                chainId: '1',
                updatedAt: '2023-11-01 18:53:35+00',
            },
            {
                id: 6,
                address: '0x42f83497ecba1608bbfeea9ef1a42e5d9c8064fc',
                hasBalance: 'true',
                chainId: '1',
                updatedAt: '2023-11-01 18:53:11+00',
            },
            {
                id: 7,
                address: '0xc5fe6493daae4a82f04ffed5b3ea46c58674ff00',
                hasBalance: 'true',
                chainId: '1',
                updatedAt: '2023-11-01 18:53:11+00',
            },
            {
                id: 8,
                address: '0x1982b2f5814301d4e9a8b0201555376e62f82428',
                hasBalance: 'true',
                chainId: '1',
                updatedAt: '2023-11-01 18:52:35+00',
            },
            {
                id: 9,
                address: '0x0ae6ca42d023280da7f11499e6576c33adf1c15f',
                hasBalance: 'true',
                chainId: '1',
                updatedAt: '2023-11-01 18:51:23+00',
            },
            {
                id: 10,
                address: '0x1111111254eeb25477b68fb85ed929f73a960582',
                hasBalance: 'true',
                chainId: '1',
                updatedAt: '2023-11-01 18:51:23+00',
            },
            {
                id: 11,
                address: '0xb613e78e2068d7489bb66419fb1cfa11275d14da',
                hasBalance: 'true',
                chainId: '5',
                updatedAt: '2023-11-01 18:50:00+00',
            },
        ],
        status: null,
        isLiveTable: true,
        liveObjectSpec: roleGrantedSpec,
    },
}