import React from "react";
import { getPCN } from "../../utils/classes";
import { chainNames } from '../../utils/chains';

function ChainAvatar({ chainIds = [], className, single = false }) {
  const pcn = getPCN(className);
  chainIds = chainIds
    .map(v => Number(v))
    .sort((a, b) => a !== 42161 ? -1 : a - b)
    .map(v => v.toString())
  const supportedChainNames = chainIds.map(chainId => chainNames[chainId]).filter(v => !!v)
  const singleChainClass = supportedChainNames.length === 1 & !single ? '__chain--single' : ''

  return (
    <div className={pcn("__chains")}>
      {supportedChainNames.map((chain, i) => (
        <div key={i} className={pcn(
          '__chain',
          singleChainClass,
          `__chain--${chain.replace(' ', '').toLowerCase()}`
        )}>
          <span>
            {supportedChainNames.length > 1 || single ? chain.split(' ').pop()[0] : chain}
          </span>
        </div>
      ))}
    </div>
  );
}

export default ChainAvatar;
