import React from 'react'
import DesktopFooter from './DesktopFooter'
import { getPCN } from '../../../utils/classes'
import ActionLink from '../ActionLink'
import { paths } from '../../../utils/nav'
import { waitlistButtonClicked } from '../../../utils/segment'

function Footer() {
    const className = 'footer'
    const pcn = getPCN(className)

    return (
        <footer className={className}>
            <div className={pcn('__cta')}>
                <div className={pcn('__cta-title')}>
                    Join the Spec community
                </div>
                <div className={pcn('__cta-subtitle')}>
                    Get notified the second we enter public beta.
                </div>
                <div className={pcn('__cta-buttons')}>
                    <ActionLink
                        text='Join our waitlist'
                        icon='slide-arrow'
                        to={paths.JOIN_WAITLIST}
                        onClick={() => waitlistButtonClicked('footer')}
                    />
                </div>
            </div>
            <DesktopFooter/>
        </footer>
    )
}

export default Footer