import React from 'react';
import { getPCN } from '../../utils/classes'

const className = 'object-property-docs'
const pcn = getPCN(className)

function ObjectPropertyDocs({ properties = [] }) {
    return (
        <div className={className}>
            { properties.map((p, i) => (
                <div key={i} className={pcn('__property')}>
                    <div><span>{p.name}</span><span>{p.type}</span></div>
                    <div>{p.desc}</div>
                </div>
            )) }
        </div>
    )
}

export default ObjectPropertyDocs