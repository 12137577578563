const specIconDark = `<svg viewBox="0 0 28 28">
    <g id="Exports" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group" transform="translate(14.000000, 14.000000) scale(-1, 1) translate(-14.000000, -14.000000) ">
            <circle id="Oval" fill="#0e0d24" cx="2" cy="2" r="2"></circle>
            <circle id="Oval" fill="#0e0d24" cx="1.25" cy="10.25" r="1.25"></circle>
            <circle id="Oval" fill="#0e0d24" cx="10.25" cy="1.25" r="1.25"></circle>
            <circle id="Oval" fill="#0e0d24" cx="26" cy="26" r="2"></circle>
            <circle id="Oval" fill="#0e0d24" cx="17.75" cy="26.75" r="1.25"></circle>
            <circle id="Oval" fill="#0e0d24" cx="26.75" cy="17.75" r="1.25"></circle>
            <circle id="Oval" fill="#D2D2E6" opacity="0.252341" cx="2" cy="26" r="2"></circle>
            <circle id="Oval" fill="#D2D2E6" opacity="0.252341" cx="26" cy="2" r="2"></circle>
        </g>
    </g>
</svg>`

export default specIconDark