const nft = `<div class="interface">
    <span class="line"><span class="keyword">interface</span><span class="type"> NFT </span><span class="default">{</span></span><br/>
    <span class="line"><span class="tab"></span><span class="default">collection: </span><span class="type">string</span></span><br/>
    <span class="line"><span class="tab"></span><span class="default">ownerAddress: </span><span class="type">string</span></span><br/>
    <span class="line"><span class="tab"></span><span class="default">contractAddress: </span><span class="type">string</span></span><br/>
    <span class="line"><span class="tab"></span><span class="default">tokenId: </span><span class="type">number</span></span><br/>
    <span class="line"><span class="tab"></span><span class="default">tokenURI: </span><span class="type">string</span></span><br/>
    <span class="line"><span class="tab"></span><span class="default">chain: </span><span class="type">string</span></span><br/>
    <span class="line"><span class="tab"></span><span class="default">standard: </span><span class="type">string</span></span><br/>
    <span class="line"><span class="tab"></span><span class="default">metadata: {</span></span><br/>
    <span class="line"><span class="tab"></span><span class="tab"></span><span class="default">[key: </span><span class="type">string</span><span class="default">]: </span><span class="type">string</span></span><br/>
    <span class="line"><span class="tab"></span><span class="default">}</span></span><br/>
    <span class="line"><span class="default">}</span></span><br/>
</div>`

export default nft
