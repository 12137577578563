import React, { useCallback, useState, useEffect, useRef } from 'react'
import { getPCN, cn } from '../../utils/classes'
import { isElementInView } from '../../utils/doc'
import Typewriter from 'typewriter-effect'
import $ from 'jquery'
import { roleGrantedSpec, lidoHolderSpec } from '../../data/specs'
import { tables } from '../../data/dapps'
import Table from './Table'

const className = 'add-tables-terminal'
const pcn = getPCN(className)

const id = 'addTablesTerminal'
const observerTargetId = 'addTablesTerminalObserver'

const timing = {
    initialDelay: 0,
}

function AddTablesTerminal() {
    const typewriter = useRef(null)
    const didType = useRef(false)
    const [showEventTable, setShowEventTable] = useState(false)
    const [showLiveObjectTable, setShowLiveObjectTable] = useState(false)
    const eventTableRef = useRef()
    const liveObjectTableRef = useRef()
    const eventTableName = window.innerWidth > 500 
        ? 'allov2.Registry.RoleGranted' 
        : 'allov2.Allo.RoleGranted'
    const command = `<span>spec <span class='accent'>add table</span> <span class='variable'>${eventTableName}</span></span>`
    const secondTable = window.innerWidth > 500 
        ? `<span class='variable'>lido.Holder</span> <span class='option'>--filter chainId=1,5</span>`
        : `<span class='variable'>lido.Holder</span> <span class='option'>--chains 1,5</span>`

    const startTyping = useCallback(() => {
        if (!typewriter.current || didType.current) return
        didType.current = true

        typewriter.current
            .callFunction(() => {
                $(`#${id}`).addClass(`${className}--did-type`)
            })
            .typeString(command)
            .callFunction(() => {
                setTimeout(() => setShowEventTable(true), 20)
            })
            .pauseFor(7500)
            .deleteChars(eventTableName.length)
            .pauseFor(250)
            .typeString(secondTable)
            .pauseFor(50)
            .callFunction(() => {
                typewriter.current.stop()
                $(`#${id} .Typewriter__cursor`).addClass('done')
                setTimeout(() => setShowLiveObjectTable(true), 20)
            })
            .start()
    }, [])

    const setTypeWriterRef = useCallback(ref => {
        typewriter.current = ref
    }, [])

    const createIntersectionObserver = useCallback(() => {
        const observer = new IntersectionObserver( entries => (
            entries && entries[0] && entries[0].isIntersecting && setTimeout(() => startTyping(), timing.initialDelay)
        ), { threshold: 0 } )

        const el = document.querySelector( `#${observerTargetId}` )
        el && observer.observe( el )
    }, [startTyping])

    useEffect(() => {
        if ( window.IntersectionObserver ) {
            setTimeout( () => {
                isElementInView( `#${observerTargetId}` )
                    ? startTyping()
                    : createIntersectionObserver()
            }, 50 )
        } else {
            setTimeout( () => startTyping(), 5 )
        }
    }, [createIntersectionObserver, startTyping])

    useEffect(() => {
        if (showEventTable) {
            setTimeout(() => eventTableRef.current.backfillLiveTable(), 300)
        }
    }, [showEventTable])

    useEffect(() => {
        if (showLiveObjectTable) {
            setTimeout(() => liveObjectTableRef.current.backfillLiveTable(), 300)
        }
    }, [showLiveObjectTable])

    const renderTerminal = useCallback(() => {
        return (
            <div className={pcn('__body')}>
                <div className={pcn('__body-liner')}>
                    <div className={pcn('__input')}>
                        <span className={pcn('__line')}>
                            <span className='prefix'>$</span>
                            <span>
                                <Typewriter
                                    onInit={setTypeWriterRef}
                                    options={{ delay: 50, deleteSpeed: 16 }}
                                />
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        )
    }, [])

    return (
        <div className={cn(
            className, 
            showEventTable ? `${className}--show-event-table` : '',
            showLiveObjectTable ? `${className}--show-live-object-table` : '',
            didType.current ? `${className}--did-type` : '',
        )} id={id}>
            { renderTerminal() }
            <div id={observerTargetId}></div>
            <div className={pcn('__tables')}>
                <Table
                    table={tables.roleGranted}
                    liveObjectSpec={roleGrantedSpec}
                    replaceLiveColType={false}
                    ref={eventTableRef}
                />
                <Table
                    table={tables.holder}
                    liveObjectSpec={lidoHolderSpec}
                    replaceLiveColType={false}
                    ref={liveObjectTableRef}
                />
            </div>
        </div>
    )
}

export default AddTablesTerminal