import React, { useCallback, useState, useRef, useEffect } from 'react'
import { getPCN, cn } from '../../utils/classes'
import { isElementInView } from '../../utils/doc'
import dashedbox from '../../svgs/svgjs/dashedbox'
import chainbox from '../../svgs/svgjs/chainbox'
import chainDash from '../../svgs/svgjs/chain-dash'
import chainArrow from '../../svgs/svgjs/chain-arrow'
import ethereum from '../../svgs/svgjs/ethereum'
import polygon from '../../svgs/svgjs/polygon'
import optimism from '../../svgs/svgjs/optimism-gray'
import linea from '../../svgs/svgjs/linea-gray'
import base from '../../svgs/svgjs/base-gray'
import celo from '../../svgs/svgjs/celo'
import Anno from './Anno'

const className = 'data-sources'
const pcn = getPCN(className)
const observerTargetId = `dataSourcesObserverTarget`

const modifiers = {
    ON_CHAIN: 'on-chain',
    OFF_CHAIN: 'off-chain',
}

const timing = {
    initialDelay: 500,
    multiChainDelay: 6000,
    animateMultiChainDelay: 1400,
}

function DataSources() {
    const [showAnno, setShowAnno] = useState(false)
    const [showMultiChain, setShowMultiChain] = useState(false)
    const [animateMultiChain, setAnimateMultiChain] = useState(false)
    const [adjustTransition, setAdjustTransition] = useState(false)
    const [onMobile, _] = useState(window.innerWidth < 1080)
    const observerCreated = useRef(false)
    const observerCalled = useRef(false)
    const onChainAnnoRef = useRef()
    const offChainAnnoRef = useRef()
    const startAnimation = useCallback(() => setShowAnno(true), [])

    const createIntersectionObserver = useCallback(() => {
        const observer = new IntersectionObserver( entries => {
            if (entries && entries[0] && entries[0].isIntersecting && !observerCalled.current) {
                observerCalled.current = true
                setTimeout(startAnimation, timing.initialDelay)
            }
        }, { threshold: 0 })

        const el = document.querySelector( `#${observerTargetId}` )
        el && observer.observe( el )
    }, [startAnimation])

    useEffect(() => {
        if (observerCreated.current) return
        observerCreated.current = true

        if ( window.IntersectionObserver ) {
            setTimeout(() => {
                isElementInView( `#${observerTargetId}` )
                    ? setTimeout(startAnimation, timing.initialDelay)
                    : createIntersectionObserver()
            }, 500)
        } else {
            setTimeout( () => startAnimation(), timing.initialDelay )
        }
    }, [createIntersectionObserver, startAnimation])

    useEffect(() => {
        if (showAnno) {
            onChainAnnoRef.current?.show()
            offChainAnnoRef.current?.show()

            adjustTransition || setTimeout(() => {
                setAdjustTransition(true)
            }, 1000)

            showMultiChain || setTimeout(() => {
                setShowMultiChain(true)
            }, timing.multiChainDelay)
        }
    }, [showAnno, showMultiChain])

    useEffect(() => {
        if (showMultiChain) {
            setTimeout(() => {
                setAnimateMultiChain(true)
            }, timing.animateMultiChainDelay)
        }
    }, [showMultiChain])

    const renderItem = useCallback((modifier, title, sideText) => (
        <li className={`--${modifier}`}>
            <span>{title}</span>
            <span>{sideText}</span>
        </li>
    ), [])

    return (
        <div className={cn(
            className,
            onMobile ? `${className}--mobile` : '',
            showAnno ? `${className}--show-anno` : '',
            adjustTransition ? `${className}--adjust-transition` : '',
            showMultiChain ? `${className}--show-multi-chain` : '',
            animateMultiChain ? `${className}--animate-multi-chain` : '' 
        )}>
            <Anno className={`--${modifiers.ON_CHAIN}`} title='on-chain' ref={onChainAnnoRef}/>
            <Anno className={`--${modifiers.OFF_CHAIN}`} title='off-chain' ref={offChainAnnoRef}/>
            <ol className='custom-ol'>
                { renderItem(modifiers.ON_CHAIN, 'Current on-chain state', 'Full Node') }
                { renderItem(modifiers.ON_CHAIN, 'Historical on-chain state', 'Archive Node') }
                { renderItem(modifiers.ON_CHAIN, 'Historical event logs', 'Archive Node') }
                { renderItem(modifiers.OFF_CHAIN, 'Off-chain metadata', 'IPFS') }
                { renderItem(modifiers.OFF_CHAIN, 'Private application data', 'Postgres') }
            </ol>
            <div id={observerTargetId}></div>
            <div className={pcn('__big-box')} dangerouslySetInnerHTML={{ __html: dashedbox }}>
            </div>
            <span className={pcn('__extension-lines', '__extension-lines--1')}></span>
            <span className={pcn('__extension-lines', '__extension-lines--2')}></span>
            <div className={pcn('__chains')}>
                <div className={pcn('__chain', '__chain--ethereum')}>
                    <span dangerouslySetInnerHTML={{ __html: chainbox }}></span>
                    <span dangerouslySetInnerHTML={{ __html: ethereum }}></span>
                    <span dangerouslySetInnerHTML={{ __html: chainDash }}></span>
                </div>
                <div className={pcn('__chain', '__chain--polygon')}>
                    <span dangerouslySetInnerHTML={{ __html: chainbox }}></span>
                    <span dangerouslySetInnerHTML={{ __html: polygon }}></span>
                    <span dangerouslySetInnerHTML={{ __html: chainDash }}></span>
                </div>
                <div className={pcn('__chain', '__chain--optimism')}>
                    <span dangerouslySetInnerHTML={{ __html: chainbox }}></span>
                    <span dangerouslySetInnerHTML={{ __html: optimism }}></span>
                    <span dangerouslySetInnerHTML={{ __html: chainDash }}></span>
                </div>
                <div className={pcn('__chain', '__chain--base')}>
                    <span dangerouslySetInnerHTML={{ __html: chainbox }}></span>
                    <span dangerouslySetInnerHTML={{ __html: base }}></span>
                    <span dangerouslySetInnerHTML={{ __html: chainDash }}></span>
                </div>
                <div className={pcn('__chain', '__chain--linea')}>
                    <span dangerouslySetInnerHTML={{ __html: chainbox }}></span>
                    <span dangerouslySetInnerHTML={{ __html: linea }}></span>
                    <span dangerouslySetInnerHTML={{ __html: chainDash }}></span>
                </div>
                <div className={pcn('__chain', '__chain--celo')}>
                    <span dangerouslySetInnerHTML={{ __html: chainbox }}></span>
                    <span dangerouslySetInnerHTML={{ __html: celo }}></span>
                </div>
                <span className={pcn('__arrow')} dangerouslySetInnerHTML={{ __html: chainArrow }}></span>
            </div>
        </div>
    )
}

export default DataSources