import React, { useEffect, useState, useRef } from 'react'
import { cn } from '../../utils/classes'
import { format as toTimeAgo, register } from 'timeago.js'

const localeFunc = (number, index) => {
    if ([0, 1].includes(index)) {
        if (number === 0) return ['just now', 'right now']
        return [
            `${number} second${number === 1 ? '' : 's'} ago`,
            `in ${number} second${number === 1 ? '' : 's'}`
        ]
    }
    return [
        ['just now', 'right now'],
        ['%s seconds ago', 'in %s seconds'],
        ['1 minute ago', 'in 1 minute'],
        ['%s minutes ago', 'in %s minutes'],
        ['1 hour ago', 'in 1 hour'],
        ['%s hours ago', 'in %s hours'],
        ['1 day ago', 'in 1 day'],
        ['%s days ago', 'in %s days'],
        ['1 week ago', 'in 1 week'],
        ['%s weeks ago', 'in %s weeks'],
        ['1 month ago', 'in 1 month'],
        ['%s months ago', 'in %s months'],
        ['1 year ago', 'in 1 year'],
        ['%s years ago', 'in %s years']
    ][index]
}
register('realtime', localeFunc)

const className = 'timeago'
const JUST_NOW = 'just now'

function TimeAgo(props) {
    const [value, setValue] = useState(props.value)
    const [_, forceRender] = useState(false)
    const displayValue = value ? toTimeAgo(value, 'realtime') : null
    const isJustNow = displayValue === JUST_NOW
    const timer = useRef(null)

    useEffect(() => {
        if (timer.current === null) {
            setValue(props.value)
            timer.current = setInterval(() => {
                forceRender(v => !v)
            }, 1000)
        } else {
            if (toTimeAgo(props.value, 'realtime') === JUST_NOW) {
                setTimeout(() => setValue(props.value), 5)
            } else {
                setValue(props.value)
            }
        }
    }, [props.value])

    return (
        <span className={cn(className, isJustNow ? `${className}--just-now` : '')}>
            { displayValue || '--' }
        </span>
    )
}

export default TimeAgo