import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react'
import { getPCN } from '../../utils/classes'
import { paths, links } from '../../utils/nav'
import $ from 'jquery'
import { useHistory, Link } from 'react-router-dom'
import { posts, fallbackPostId } from './posts'
import specIcon from '../../svgs/svgjs/spec-icon'
import BlogAuthor from './BlogAuthor'
import pixels from '../../svgs/svgjs/blog-splash-pixels'
import starIcon from '../../svgs/svgjs/star'
import MdToc from '../shared/md/MdToc'
import twitterIcon from '../../svgs/svgjs/twitter'
import discordIcon from '../../svgs/svgjs/discord'
import githubIcon from '../../svgs/svgjs/github'
import DesktopFooter from '../shared/footer/DesktopFooter'
import ActionButton from '../shared/ActionButton'
import DotSplit from '../shared/DotSplit'

const className = 'blog'
const pcn = getPCN(className)
const desktopHeaderClassName = 'desktop-header'
const hpcn = getPCN(desktopHeaderClassName)
const overviewFreezeAnchorId = 'overviewFreezeAnchor'

function BlogPage(props) {
    const history = useHistory()
    const params = (props.match || {}).params || {}
    const currentSectionId = (props.location.hash || '#').slice(1) || null
    const postId = useMemo(() => params.post || fallbackPostId, [params])
    const post = posts[postId]
    const [hasScrolledDeep, setHasScrolledDeep] = useState(false)
    const observerCreated = useRef(false)
    const tocRef = useRef()

    const createIntersectionObserver = useCallback(() => {
        const observer = new IntersectionObserver( entries => {
            if (entries && entries[0]) {
                setHasScrolledDeep(!entries[0].isIntersecting)
            }
        }, { threshold: 0 })

        const el = document.querySelector(`#${overviewFreezeAnchorId}`)
        el && observer.observe(el)
    }, [])

    const renderBlogPageHeader = useCallback(() => (
        <header>
            <div className={desktopHeaderClassName}>
                <a className={pcn('__brand-container')} href='/' tabIndex='-1'>
                    <div
                        className={pcn('__header-icon')}
                        dangerouslySetInnerHTML={{ __html: specIcon }}>
                    </div>
                </a>
                <div className={hpcn('__liner')}>
                    <div className={hpcn('__nav-container')}>
                        <div className={hpcn('__nav')}>
                            <a
                                className={hpcn('__star-on-gh-button')}
                                target='_blank'
                                tabIndex='-1'
                                rel='noreferrer'
                                href={ links.GITHUB_SPEC }>
                                <span dangerouslySetInnerHTML={{ __html: starIcon }}></span>
                                <span>Star us on GitHub</span>
                            </a>
                            <div className={hpcn('__icon-links')}>
                                <a
                                    className={hpcn('__icon-link', '__icon-link--github')}
                                    target='_blank'
                                    tabIndex='-1'
                                    rel='noreferrer'
                                    href={ links.GITHUB_SPEC }
                                    dangerouslySetInnerHTML={{ __html: githubIcon }}>
                                </a>
                                <a
                                    className={hpcn('__icon-link', '__icon-link--discord')}
                                    target='_blank'
                                    tabIndex='-1'
                                    rel='noreferrer'
                                    href={ links.DISCORD }
                                    dangerouslySetInnerHTML={{ __html: discordIcon }}>
                                </a>
                                <a
                                    className={hpcn('__icon-link', '__icon-link--twitter')}
                                    target='_blank'
                                    tabIndex='-1'
                                    rel='noreferrer'
                                    href={ links.TWITTER }
                                    dangerouslySetInnerHTML={{ __html: twitterIcon }}>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    ))

    const renderPostHeader = useCallback(() => (
        <div className={pcn('__header')}>
            <div className={pcn('__header-liner')}>
                <Link to={paths.HOME}>
                    <div
                        className={pcn('__header-icon')}
                        dangerouslySetInnerHTML={{ __html: specIcon }}>
                    </div>
                </Link>
                <div className={pcn('__header-main')}>
                    <div className={pcn('__header-title')}>{ post.title }</div>
                    <div className={pcn('__header-subtitle')}>{ post.subtitle }</div>
                    {/* <div className={pcn('__header-date')}>{ post.date }</div> */}
                    <div className={pcn('__labels', '__labels--header')}>
                        { post.labels.map((label, i) => (<div key={i}><span>{label}</span></div>)) }
                    </div>
                </div>
                <div className={pcn('__header-authors')}>
                    { post.authors.map((author, i) => (
                        <BlogAuthor key={i} {...author}/>
                    ))}
                </div>
                <div id={overviewFreezeAnchorId}></div>
            </div>
        </div>
    ), [post])

    const renderPostBody = useCallback(() => (
        <div className={pcn('__body')}>
            { post.render({
                onCurrentSectionChange: id => tocRef.current?.setCurrentSectionId(id)
            })}
        </div>
    ), [post])

    const renderPostOverview = useCallback(() => (
        <div className={pcn('__overview', hasScrolledDeep ? '__overview--freeze' : '')}>
            <div className={pcn(
                '__overview-icon-links',
                hasScrolledDeep ? `__overview-icon-links--show` : ''
            )}>
                <a
                    className={pcn('__overview-icon-link', '__overview-icon-link--discord')}
                    target='_blank'
                    tabIndex='-1'
                    rel='noreferrer'
                    href={ links.DISCORD }
                    dangerouslySetInnerHTML={{ __html: discordIcon }}>
                </a>
                <a
                    className={pcn('__overview-icon-link', '__overview-icon-link--github')}
                    target='_blank'
                    tabIndex='-1'
                    rel='noreferrer'
                    href={ links.GITHUB_ORG }
                    dangerouslySetInnerHTML={{ __html: githubIcon }}>
                </a>
                <a
                    className={pcn('__overview-icon-link', '__overview-icon-link--twitter')}
                    target='_blank'
                    tabIndex='-1'
                    rel='noreferrer'
                    href={ links.TWITTER }
                    dangerouslySetInnerHTML={{ __html: twitterIcon }}>
                </a>
            </div>
            <div className={pcn('__labels', hasScrolledDeep ? '__labels--show' : '')}>
                { post.labels.map((label, i) => (<div key={i}><span>{label}</span></div>)) }
            </div>
            <MdToc
                header='In this article'
                items={post.toc}
                ref={tocRef}
            />
            <DotSplit/>
            <div className={pcn('__overview-action-buttons')}>
                <ActionButton
                    text='Request early access'
                    icon='slide-arrow'
                    className='--with-border'
                    onClick={() => {
                        window.location = ('https://spec.dev' + paths.JOIN_WAITLIST)
                    }}
                />
            </div>
        </div>
    ), [post, hasScrolledDeep])

    useEffect(() => {
        if (observerCreated.current) return
        observerCreated.current = true
        if (!window.IntersectionObserver) return
        setTimeout(() => createIntersectionObserver(), 10)
    }, [createIntersectionObserver])

    useEffect(() => {
        if (currentSectionId) {
            const currentScrollPos = window.scrollY
            const destinationScrollPos = $(`#${currentSectionId}-ap`).offset()?.top
            if (typeof destinationScrollPos !== 'number') return
            const scrollDown = destinationScrollPos > currentScrollPos
            $(window).scrollTop(destinationScrollPos + (scrollDown ? 10 : -10))
        }
    }, [currentSectionId])

    useEffect(() => {
        if (!post) {
            history.push(paths.HOME)
            return
        }
        document.title = post.title
    }, [post])

    if (!post) {
        return <div className={className}></div>
    }

    return (
        <div className={className}>
            { renderBlogPageHeader() }
            <div
                className={pcn('__pixels')}
                dangerouslySetInnerHTML={{  __html: pixels }}>
            </div>
            { renderPostHeader() }
            { renderPostBody() }
            { renderPostOverview() }
            <DesktopFooter/>
        </div>
    )
}

export default BlogPage