const db = `<svg viewBox="0 0 12 13">
    <g id="Exports" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
        <g id=db-icon" transform="translate(1.000000, 1.000000)" stroke="currentColor">
            <ellipse id="Oval" cx="4.95" cy="1.65" rx="4.95" ry="1.65"></ellipse>
            <path d="M9.9,5.5 C9.9,6.413 7.7,7.15 4.95,7.15 C2.2,7.15 0,6.413 0,5.5" id="Path"></path>
            <path d="M0,1.65 L0,9.35 C0,10.263 2.2,11 4.95,11 C7.7,11 9.9,10.263 9.9,9.35 L9.9,1.65" id="Path"></path>
        </g>
    </g>
</svg>`

export default db