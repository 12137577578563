import React, { useCallback, useEffect, useRef, useState } from 'react'
import MdIconCarouselControls from './md/MdIconCarouselControls'
import { isElementInView } from '../../utils/doc'
import { getPCN } from '../../utils/classes'
import ex from '../../data/examples'
import arrowRight from '../../svgs/svgjs/arrow-right'
import Anno from './Anno'

const className = 'live-object-code-examples'
const pcn = getPCN(className)
const editorClassName = 'editor'
const epcn = getPCN(editorClassName)
const observerTargetId = `liveObjectCodeExamplesObserverTarget`

const examples = [
    {
        icon: 'allov2',
        title: 'Gitcoin — Allo Profiles',
        subtitle: 'All profiles on Gitcoin\'s Allo protocol.',
        next: 'Uniswap Pools',
        files: [
            {
                name: 'spec.ts',
                code: ex.alloProfile,
                annotations: [
                    {
                        lineNumber: 4,
                        title: 'New Live Table',
                        desc: 'Create a new Live Table class and specify the properties of your data model.',
                    },
                    {
                        lineNumber: 20,
                        title: 'Multi-Chain Event Handlers',
                        desc: 'Leverage contextual events from the ecosystem to index your cross-chain data models in a unique way.',
                    },
                    {
                        lineNumber: 25,
                        title: 'Powerful Helper Functions',
                        desc: 'Easily resolve metadata, call methods on contracts, query token data, and more.',
                    },
                ],
            },
            {
                name: 'manifest.json',
                code: ex.alloProfileManifest,
            }
        ],
    },
    {
        icon: 'uniswapv3',
        title: 'Uniswap — Pools',
        subtitle: 'All pools on Uniswap V3.',
        next: 'Synthetix Positions',
        files: [
            {
                name: 'spec.ts',
                code: ex.uniswapPool(),
                annotations: [
                    {
                        lineNumber: 32,
                        title: 'Factory Pattern Support',
                        desc: '"Contract Groups" make it possible to dynamically index events from an ever-growing set of addresses.',
                    },
                ]
            },
            {
                name: 'manifest.json',
                code: ex.uniswapPoolManifest,
            }
        ],
    },
    {
        icon: 'synthetixv3',
        title: 'Synthetix — Positions',
        subtitle: 'All positions on Synthetix V3.',
        next: 'Allo Profiles',
        files: [
            {
                name: 'spec.ts',
                code: ex.synthetixPosition,
                annotations: [
                    {
                        lineNumber: 26,
                        title: 'Cross-Event Handler Logic',
                        desc: 'Easily share logic across multiple events for simplicity and consolidation.',
                    },
                ],
            },
            {
                name: 'manifest.json',
                code: ex.synthetixPositionManifest,
            }
        ],
    },
]

const style = {
    lineHeight: 21.08,
    paddingTop: 21,
}

const timing = {
    initialDelay: 1500,
    initialFadeInTime: 1000,
    autoPlayStepDelay: 9000,
}

function LiveObjectCodeExamples() {
    const [exampleIndex, setExampleIndex] = useState(0)
    const [fileIndex, setFileIndex] = useState(0)
    const [annoIndex, setAnnoIndex] = useState(-1)
    const [showAnnos, setShowAnnos] = useState(false)
    const example = examples[exampleIndex]
    const file = example.files[fileIndex]
    const annotations = file.annotations || []
    const annoRefs = useRef({})
    const observerCreated = useRef(false)
    const observerCalled = useRef(false)
    const autoPlayTimer = useRef(null)
    const clones = useRef({
        annoIndex: -1,
        numAnnotations: annotations.length,
    })
    const hasAutoPlayedIndexes = useRef(new Set([0]))

    const clearAutoPlayTimer = useCallback(() => {
        autoPlayTimer.current && clearInterval(autoPlayTimer.current)
    }, [])

    const createAutoPlayTimer = useCallback((duration) => {
        clearAutoPlayTimer()
        autoPlayTimer.current = setInterval(() => {
            if (clones.current.annoIndex < clones.current.numAnnotations - 1) {
                setAnnoIndex(clones.current.annoIndex + 1)
            } else {
                clearAutoPlayTimer()
                setAnnoIndex(-1)
            }
        }, duration || timing.autoPlayStepDelay)
    }, [])

    const startAnimation = useCallback(() => {
        if (!annotations.length) return
        setShowAnnos(true)
        setTimeout(() => {
            setAnnoIndex(0)
            createAutoPlayTimer()    
        }, timing.initialFadeInTime)
    }, [annotations, createAutoPlayTimer])

    const createIntersectionObserver = useCallback(() => {
        const observer = new IntersectionObserver( entries => {
            if (entries && entries[0] && entries[0].isIntersecting && !observerCalled.current) {
                observerCalled.current = true
                setTimeout(startAnimation, timing.initialDelay)
            }
        }, { threshold: 0 })

        const el = document.querySelector( `#${observerTargetId}` )
        el && observer.observe( el )
    }, [startAnimation])

    useEffect(() => {
        if (observerCreated.current) return
        observerCreated.current = true

        if ( window.IntersectionObserver ) {
            setTimeout(() => {
                isElementInView( `#${observerTargetId}` )
                    ? setTimeout(startAnimation, timing.initialDelay)
                    : createIntersectionObserver()
            }, 500)
        } else {
            setTimeout( () => startAnimation(), timing.initialDelay )
        }
    }, [createIntersectionObserver, startAnimation])

    useEffect(() => {
        clones.current.annoIndex = annoIndex
        for (const [i, ref] of Object.entries(annoRefs.current)) {
            i == annoIndex ? ref?.show() : ref?.hide()
        }
    }, [annoIndex])

    useEffect(() => {
        clones.current.numAnnotations = annotations.length
    }, [annotations])

    useEffect(() => {
        if (hasAutoPlayedIndexes.current.has(exampleIndex)) return
        hasAutoPlayedIndexes.current.add(exampleIndex)
        createAutoPlayTimer()
    }, [exampleIndex, createAutoPlayTimer])

    const renderAnnotations = useCallback(() => {
        const annos = annotations.map((anno, i) => {
            const top = (style.lineHeight * (anno.lineNumber - 1)) + style.paddingTop
            const isCurrent = i == annoIndex

            return (
                <div key={i} className={pcn('__anno', isCurrent ? '__anno--current' : '')} style={{ top }}>
                    <div
                        className={pcn('__anno-ln', anno.lineNumber > 9 ? '__anno-ln-2' : '')}
                        onClick={() => {
                            clearAutoPlayTimer()
                            setAnnoIndex(i)
                        }}>
                        {anno.lineNumber}
                    </div>
                    <Anno
                        title={anno.title}
                        ref={r => {
                            if (!r) return
                            annoRefs.current[i] = r
                        }}>
                        {anno.desc}
                    </Anno>
                </div>
            )
        })
        return (
            <div className={pcn('__annos', showAnnos ? '__annos--show' : '')}>
                { annos }
            </div>
        )
    }, [annotations, annoIndex, showAnnos])

    return (
        <div className={className}>
            <MdIconCarouselControls
                items={examples}
                index={exampleIndex}
                onNav={i => {
                    clearAutoPlayTimer()
                    setExampleIndex(i)
                    setFileIndex(0)
                    setAnnoIndex(-1)
                }}
            />
            <div className={pcn('__editor-container')}>
                <div id={observerTargetId}></div>
                <div className={editorClassName}>
                    <div className={epcn('__header')}>
                        { example.files.map((f, i) => (
                            <div
                                key={i}
                                className={epcn(
                                    '__header-tab', 
                                    fileIndex === i ? '__header-tab--current' : ''
                                )}
                                onClick={() => setFileIndex(i)}>
                                <span className={epcn('__header-tab-file-name')}>{f.name}</span>
                            </div>
                        ))}
                    </div>
                    <div className={epcn('__body')}>
                        <div
                            className={epcn('__code')}
                            dangerouslySetInnerHTML={{ __html: file.code }}>
                        </div>
                        { renderAnnotations() }
                    </div>
                    <div className={pcn('__footer')}>
                        <div
                            onClick={() => {
                                let nextIndex = exampleIndex + 1
                                nextIndex = nextIndex >= examples.length ? 0 : nextIndex
                                clearAutoPlayTimer()
                                setExampleIndex(nextIndex)
                                setFileIndex(0)
                                setAnnoIndex(-1)
                            }}>
                            <span>{example.next}</span>
                            <span dangerouslySetInnerHTML={{ __html: arrowRight }}></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LiveObjectCodeExamples