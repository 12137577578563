import React, { useEffect, useState, useCallback, useRef } from 'react'
import { getPCN, cn } from '../../../utils/classes'
import { isElementInView } from '../../../utils/doc'
import $ from 'jquery'
import { tables } from '../../../data/dapps'
import NewColumnDropdown from '../../shared/NewColumnDropdown'
import modelRelationshipIcon from '../../../svgs/svgjs/model-relationship'
import keyIcon from '../../../svgs/svgjs/key'
import linkIcon from '../../../svgs/svgjs/link'
import filterIcon from '../../../svgs/svgjs/filter'
import lockIcon from '../../../svgs/svgjs/lock'
import blistIcon from '../../../svgs/svgjs/blist'
import searchIcon from '../../../svgs/svgjs/search'
import { compoundUserHealthSpec } from '../../../data/specs'
import ColumnSliderGroup from './ColumnSliderGroup'

const className = 'live-column'
const observerTargetId = 'liveColumnObserverTarget'
const pcn = getPCN(className)

const status = {
    BACKFILLING: {
        id: 'backfilling',
        title: 'Backfilling records...',
    },
    POPULATING: {
        id: 'populating',
        title: 'Backfilling records...',
    },
    IN_SYNC: {
        id: 'in-sync',
        title: 'IN SYNC',
    }
}

const table = { ...tables.borrowers, status: status.IN_SYNC }

const getColHeaderIcon = col => {
    if (col.isLiveLinkColumn && col.liveSource) {
        return [linkIcon, 'link']
    }

    if (col.isPrimaryKey) {
        return [keyIcon, 'key']
    }

    if (col.isForeignKey) {
        return [modelRelationshipIcon, 'rel']
    }

    return [null, null]
}

const getColType = (col, table) => {
    if (!col.liveSource) {
        return <span className={pcn('__col-header-type')}>{ col.type }</span>
    }

    return (
        <span className={pcn('__col-header-type')}>
            <span>{ col.liveSource === 'Aave User Health' && window.innerWidth <= 658 ? 'Aave Health' : col.liveSource }</span>
        </span>
    )
}

const timing = {
    ticker: 1600,
    initialDelay: window.innerWidth <= 580 ? 10 : 1000,
}

function LiveColumnSection() {
    const [showTable, setShowTable] = useState(true)
    const [clickNewColumn, setClickNewColumn] = useState(false)
    const [showSlider, setShowSlider] = useState(false)
    const observerCreated = useRef(false)
    const observerCalled = useRef(false)
    const newColumnDropdownRef = useRef()
    const sliderRef = useRef()
    const sliderContainerRef = useRef()
    const startedSearching = useRef(false)
    const tableRef = useRef()

    const startSearching = useCallback(() => {
        sliderRef.current?.search('compound health')

        setTimeout(() => {
            sliderRef.current?.addTop()

            setTimeout(() => {
                sliderRef.current?.selectLiveObject(compoundUserHealthSpec)

                if (window.innerWidth > 1000) { 
                    sliderRef.current?.initObservers()
                    return
                }
                
                setTimeout(() => {
                    sliderContainerRef.current && $(sliderContainerRef.current).addClass(pcn('__slider--move'))
                    setTimeout(() => {
                        sliderRef.current?.initObservers()
                    }, 0)
                }, 2000)
            }, 200)
        }, 4000)
    }, [])

    const startAnimation = useCallback(() => {
        setClickNewColumn(true)
        setTimeout(() => {
            newColumnDropdownRef.current?.show()
            setTimeout(() => {
                newColumnDropdownRef.current?.setShowHover([1])
                setTimeout(() => {
                    newColumnDropdownRef.current?.setShowClick([1])
                    setTimeout(() => {
                        newColumnDropdownRef.current?.hide()
                    }, 200)
                    setTimeout(() => {
                        setShowSlider(true)
                    }, 300)
                }, 2000)
            }, 500)
        }, 200)
    }, [])

    const createIntersectionObserver = useCallback(() => {
        const observer = new IntersectionObserver( entries => {
            if (entries && entries[0] && entries[0].isIntersecting && !observerCalled.current) {
                observerCalled.current = true
                setTimeout(startAnimation, timing.initialDelay)
            }
        }, { threshold: 0 })

        const el = document.querySelector( `#${observerTargetId}` )
        el && observer.observe( el )
    }, [startAnimation])

    useEffect(() => {
        if (observerCreated.current) return
        observerCreated.current = true

        if ( window.IntersectionObserver ) {
            setTimeout( () => {
                isElementInView( `#${observerTargetId}` )
                    ? setTimeout(startAnimation, timing.initialDelay)
                    : createIntersectionObserver()
            }, 500 )
        } else {
            setTimeout( () => startAnimation(), timing.initialDelay )
        }
    }, [createIntersectionObserver, startAnimation])

    useEffect(() => {
        if (showSlider && !startedSearching.current) {
            startedSearching.current = true
            setTimeout(startSearching, 1000)
        }
    }, [showSlider, startSearching])

    const renderStatus = useCallback((statusId) => (
        <div className={pcn('__status-container')}>
            <div className={pcn('__status', `__status--in-sync`)}>
                <span className='blink-indicator'><span></span></span>
                <span>{ status.IN_SYNC.title }</span>
            </div>
        </div>
    ), [])

    const renderNumRecords = useCallback(() => (
        <div className={pcn('__header-num-records')}>
            <span>120 Records</span>
        </div>
    ), [])

    const renderFilterButton = useCallback(() => (
        <div className={pcn('__filter-button')}>
            <span dangerouslySetInnerHTML={{ __html: filterIcon }}></span>
            <span>Filter</span>
        </div>
    ), [])

    const renderSortButton = useCallback(() => (
        <div className={pcn('__sort-button')}>
            <span dangerouslySetInnerHTML={{ __html: blistIcon }}></span>
            <span>Sort</span>
        </div>
    ), [])

    const renderSearchButton = useCallback(() => (
        <div className={pcn('__search-button')}>
            <span dangerouslySetInnerHTML={{ __html: searchIcon }}></span>
            <span>Search</span>
        </div>
    ), [])

    const renderRLSStatus = useCallback(() => (
        <div className={pcn('__rls-status')}>
            <span dangerouslySetInnerHTML={{ __html: lockIcon }}></span>
            <span>RLS Enabled</span>
        </div>
    ), [])

    const renderNewColumnButton = useCallback(() => (
        <div className={pcn('__new-col-button')}>
            <span className={clickNewColumn ? '--click' : ''}>
                <span>+</span>
                <span>New Column</span>
            </span>
            <NewColumnDropdown
                key='newColDropdown'
                id='newColDropdown'
                ref={newColumnDropdownRef}
            />
        </div>
    ), [clickNewColumn])

    const renderColHeaders = useCallback(() => {
        const colHeaders = [(
            <div key='check' className={pcn('__col-header', '__col-header--check-col')}>
                <span></span>
            </div>
        )]

        table.columns.forEach(col => {
            const [icon, mod] = getColHeaderIcon(col)
            const colType = getColType(col, table)

            colHeaders.push((
                <div
                    key={col.name}
                    className={pcn(
                        '__col-header',
                        `__col-header--${table.name}-${col.hide === false ? col.liveSource : col.name}`,
                        !!col.liveSource ? '__col-header--live' : '',
                        col.isLiveLinkColumn ? '__col-header--live-link' : '',
                        col.isPrimaryKey ? '__col-header--primary' : '',
                    )}>
                    { icon &&
                        <span
                            className={pcn('__col-header-type-icon', `__col-header-type-icon--${mod}`)}
                            dangerouslySetInnerHTML={{ __html: icon }}>
                        </span>
                    }
                    { !icon && !col.isLiveLinkColumn && !!col.liveSource && table.status?.id === status.IN_SYNC.id &&
                        <span className='blink-indicator'><span></span></span>
                    }
                    { !icon && !col.isLiveLinkColumn && !!col.liveSource && (table.status?.id === status.BACKFILLING.id || table.status?.id === status.POPULATING.id) &&
                        <span className={pcn('__col-header-type-icon', `__col-header-type-icon--circle`)}><span></span></span>
                    }
                    <span className={pcn('__col-header-name')}>{window.innerWidth <= 890 && col.name === 'aave_health_factor' ? 'aave_health' : col.name }</span>
                    { colType }
                </div>
            ))
        })

        colHeaders.push((
            <div key='add' className={pcn('__col-header', '__col-header--new-col')}>
                <span>
                    <span>+</span>
                </span>
            </div>
        ))

        return colHeaders
    }, [])

    const renderRecords = useCallback(() => table.records.map((record, i) => {
        const cells = [(
            <div key='check' className={pcn('__cell', '__cell--check-col')}>
                <span></span>
            </div>
        )]

        table.columns.forEach(col => {
            let value = record[col.hide === false ? col.liveSource : col.name]
            if (value === null) {
                value = 'NULL'
            } else if (!value) {
                value = ''
            }

            cells.push((
                <div
                    key={col.name}
                    className={pcn(
                        '__cell', 
                        `__cell--${table.name}-${col.hide === false ? col.liveSource : col.name}`,
                        !!col.liveSource ? '__cell--live' : '',
                        col.isLiveLinkColumn ? '__cell--live-link' : '',
                        value === 'NULL' ? '__cell--null' : '',
                        col.isPrimaryKey ? '__cell--primary' : '',
                    )}>
                    <span>
                        { value }
                    </span>
                </div>
            ))
        })

        cells.push((<div key='empty' className={pcn('__cell')}></div>))

        return (
            <div key={i} className={pcn('__row')}>
                { cells }
            </div>
        )
    }), [])

    const renderTable = useCallback(() => (
        <div className={pcn(
            '__table',
            `__table--${table.name}`,
            `__table--${table.status?.id}`,
            showTable ? '__table--show' : '',
            showSlider ? '__table--dim' : ''
        )} ref={tableRef}>
            <div className={pcn('__header')}>
                <div className={pcn('__header-left')}>
                    <div className={pcn('__header-left-liner')}>
                        <div className={pcn('__header-left-top')}>
                            <div className={pcn('__table-name')}>
                                <span>{ table.name }</span>
                            </div>
                            <div className={pcn('__table-desc')}>
                                <span>{ table.desc }</span>
                            </div>
                        </div>
                        <div className={pcn('__header-left-bottom')}>
                            { table.status && renderStatus() }
                            { renderNumRecords() }
                            { renderFilterButton() }
                            { renderSortButton() }
                            { renderSearchButton() }
                        </div>
                    </div>
                </div>
                <div className={pcn('__header-right')}>
                    <div className={pcn('__header-right-liner')}>
                    <div className={pcn('__header-right-top')}>
                            { renderRLSStatus() }
                        </div>
                        <div className={pcn('__header-right-bottom')}>
                            { renderNewColumnButton() }
                        </div>
                    </div>
                </div>
            </div>
            <div className={pcn('__main')}>
                <div className={pcn('__col-headers')}>
                    { renderColHeaders() }
                </div>
                <div className={pcn('__records')}>
                    { renderRecords() }
                </div>
            </div>
        </div>
    ), [showTable, showSlider])

    const renderSlider = useCallback(() => (
        <div className={pcn('__slider', showSlider ? '__slider--show' : '')} ref={sliderContainerRef}>
            <ColumnSliderGroup table={table} ref={sliderRef} />
        </div>
    ), [showSlider])

    return (
        <div
            id='liveColumn'
            className={cn(className)}>
            <div className={pcn('__liner')}>
                <div className={pcn('__text-container')}>
                    <div className={pcn('__name')}>
                        Live Columns
                    </div>
                    <div className={pcn('__title')}>
                        Add custom insights to any table
                    </div>
                    <div className={pcn('__subtitle')}>
                        Embed live NFT or DeFi data into the column of any table &mdash; place data exactly where you need it and personalize it with custom transforms.
                    </div>
                </div>
                <div className={pcn('__graphic')}>
                    <div className={pcn('__graphic-liner')}>
                        <span id={observerTargetId}></span>
                        { renderTable() }
                        { renderSlider() }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LiveColumnSection
