import React, { useMemo, useRef, useEffect, useCallback } from 'react'
import { cn, getPCN } from '../../../utils/classes'
import { titles } from '.'
import { toSlug } from '../../../utils/formatters'
import { paths, links } from '../../../utils/nav'
import { s3 } from '../../../utils/path'
import branchIcon from '../../../svgs/svgjs/branch'
import MdIconBullets from '../../shared/md/MdIconBullets'
import MdVideo from '../../shared/md/MdVideo'
import LiveObjectCodeExamples from '../../shared/LiveObjectCodeExamples'
import addToListIcon from '../../../svgs/svgjs/add-to-list'
import linkIcon from '../../../svgs/svgjs/link'
import arrowSwitchIcon from '../../../svgs/svgjs/arrow-switch'
import manualIcon from '../../../svgs/svgjs/manual'
import multichainIcon from '../../../svgs/svgjs/multichain'
import gqlIcon from '../../../svgs/svgjs/gql'
import ecosystemIcon from '../../../svgs/svgjs/ecosystem'
import dbIcon from '../../../svgs/svgjs/db'
import blistIcon from '../../../svgs/svgjs/blist'
import Anno from '../../shared/Anno'
import ActionButton from '../../shared/ActionButton'
import ActionLink from '../../shared/ActionLink'
import DataSources from '../../shared/DataSources'
import twitterIcon from '../../../svgs/svgjs/twitter'
import githubIcon from '../../../svgs/svgjs/github'
import DotSplit from '../../shared/DotSplit'
import AddContractsTerminal from '../../shared/AddContractsTerminal'
import AddTablesTerminal from '../../shared/AddTablesTerminal'

const className = 'post'
const pcn = getPCN(className)

const getLiveTablesOverviewBullets = () => [
    {
        icon: addToListIcon,
        title: titles.AUTO_POPULATING,
        desc: 'When a Live Table is added to your database, it instantly starts backfilling its own records.',
    },
    {
        icon: `<span></span>`,
        title: titles.AUTO_UPDATING,
        desc: 'Live Tables always reflect the latest on-chain state, automatically updating records where needed.',
    },
    {
        icon: linkIcon,
        title: 'Create live relationships',
        desc: 'Easily auto-populate one Live Table based on the the contents of another.',
    },
    {
        icon: arrowSwitchIcon,
        title: 'Native re-org correction',
        desc: 'Live Tables automatically course-correct during chain re-orgs, ensuring long-term data accuracy.',
    },
]

const getLiveObjectsOverviewBullets = () => [
    {
        icon: `<span>TS</span>`,
        title: 'TypeScript driven',
        desc: 'Leverage the full power of TypeScript to transform and enrich the data for your table.',
    },    
    {
        icon: multichainIcon,
        title: 'Multi-chain native',
        desc: 'Live Tables are naturally multi-chain, absorbing chain ids from the events that power them.',
    },
    {
        icon: branchIcon,
        title: 'Intelligently versioned',
        desc: 'All Live Tables are version-controlled for distribution and seamlessly upgradable.',
    },
    {
        icon: manualIcon,
        title: 'Open-source',
        desc: 'Our framework for writing Live Tables is fully open-source and powered by the community.',
    },
]

const getPortabilityBullets = () => [
    {
        icon: dbIcon,
        title: 'Any schema',
        desc: 'Localhost, dev, staging, prod — easily deploy Live Tables across environments and teams.',
    },
    {
        icon: ecosystemIcon,
        title: 'Any host',
        desc: 'Add your Live Tables to your existing database, or have Spec host them for you.',
    },
    {
        icon: gqlIcon,
        title: 'Any layer of the stack',
        desc: <span>Query your data at the Postgres level, or spin up an <span className='--bright'>Instant GraphQL API</span>.</span>,
    },
]

const getOrderedSections = () => [
    titles.INTRODUCTION,
    titles.POSTGRES_TO_THE_RESCUE,
    titles.A_BRIGHTER_FUTURE,
    titles.LIVE_TABLES,
    titles.LIVE_OBJECTS,
    titles.EARLY_ACCESS,
].map(title => toSlug(title))

const getInitialSectionVisibility = () => {
    const m = {}
    getOrderedSections().forEach(id => {
        m[id] = false
    })
    return m
}

const getPrevSections = () => {
    const sections = getOrderedSections()
    const prevSectionForId = {}
    for (let i = 0; i < sections.length; i++) {
        const id = sections[i]
        prevSectionForId[id] = sections[i - 1]
    }
    return prevSectionForId
}

const timing = {
    selectStepStart: 7,
    filterStepStart: 14,
    celebrateStepStart: 35,
}

function IntroducingSpec({ onCurrentSectionChange }) {
    const liveTablesOverviewBullets = useMemo(getLiveTablesOverviewBullets, [])
    const liveObjectsOverviewBullets = useMemo(getLiveObjectsOverviewBullets, [])
    const portabilityBullets = useMemo(getPortabilityBullets, [])
    const observerCreated = useRef(false)
    const sectionVisibility = useRef(getInitialSectionVisibility())
    const prevSectionForId = useRef(getPrevSections())
    const lastY = useRef(0)
    const liveRelationshipStepsAnnoRef = useRef()
    const postRef = useRef()
    const selectStepRef = useRef()
    const filterStepRef = useRef()
    const celebrateStepRef = useRef()

    const createIntersectionObserver = useCallback(() => {
        const observer = new IntersectionObserver( entries => {
            entries.forEach((entry) => {
                let { id } = entry.target
                if (!id) return
                id = id.slice(0, id.length - 3)
                const scrollingDown = window.scrollY > lastY.current
                lastY.current = window.scrollY

                const didLeave = sectionVisibility.current[id] && !entry.isIntersecting
                const didEnter = !sectionVisibility.current[id] && entry.isIntersecting
                if (didLeave) {
                    const currentSectionId = scrollingDown ? id : prevSectionForId.current[id]
                    onCurrentSectionChange(currentSectionId || null)
                } else if (didEnter && !scrollingDown) {
                    onCurrentSectionChange(id)
                }

                sectionVisibility.current[id] = entry.isIntersecting
            })
        }, { threshold: 0 })
        const sections = document.querySelectorAll(`.${pcn('__anchor-point')}`)
        sections.forEach((section) => observer.observe(section))
    }, [onCurrentSectionChange])

    useEffect(() => {
        if (observerCreated.current) return
        observerCreated.current = true
        if (!window.IntersectionObserver) return
        setTimeout(() => createIntersectionObserver(), 10)
    }, [createIntersectionObserver])

    return (
        <div className={cn('md', className)} ref={postRef}>
            <section>
                <DotSplit id='leadSplit'/>
                <a className={pcn('__anchor-point')} id={toSlug(titles.INTRODUCTION) + '-ap'}></a>
                <p style={{ marginTop: 17 }}>
                    One of the biggest challenges to building in web3 is the lack of a single source-of-truth for data. 
                    Primarily, this stems from the split between on-chain and off-chain data. However, the recent rise in multi-chain 
                    popularity — and sheer number of new L2s — is leaving protocols additionally fractured across an ever-increasing 
                    number of chains.
                </p>
                <DataSources/>
                <p>
                    Despite the siloed nature of underlying data, most web3 applications need <i>higher-level, contextual</i> data for a protocol or set of contracts. More specifically, data that is...
                </p>
                <ul>
                    <li><span className='--'>Enriched & integrated</span></li>
                    <li><span className='--'>Multi-chain</span></li>
                    <li><span className='--'>Contextually indexed</span></li>
                    <li><span className='--'>Real-time</span></li>
                </ul>
                <p>
                    Developers then need an efficient way of repeatedly accessing this data, ideally from a schema that's personalized to their application.
                    Without this, teams are stuck fetching and stitching together data on-the-fly, which not only is tedious, but limits functionality and doesn't scale.
                </p>
            </section>
            <section>
                <a className={pcn('__anchor-point')} id={toSlug(titles.POSTGRES_TO_THE_RESCUE) + '-ap'}></a>
                <h1>{titles.POSTGRES_TO_THE_RESCUE}</h1>
                <p>
                    When building multi-chain apps, the benefits of having all data you need in a single location is, honestly, unbeatable:
                </p>
                <ul>
                    <li>Can easily curate holistic, multi-chain insights, filtering by chain where needed</li>
                    <li>Can fully own the structure and format of the data — schemas, indexes, constraints, etc.</li>
                    <li>Can establish direct relationships between on-chain data and off-chain data</li>
                    <li>Can access data with minimal friction and zero rate limits</li>
                    <li>Downstream apps can easily source all data from a single location</li>
                </ul>
                <p>
                    Because of this, many teams in web3 use Postgres as a manufactured source-of-truth.
                </p>
                <p>
                    Unfortunately, curating a database like this is extremely non-trivial. 
                    Teams can spend months building the indexing infrastructure required for this type of caching 
                    pattern to be fast, accurate, and reliable enough for production. The difficulty level 
                    only increases when realtime data is needed — without strategies in-place to handle chain 
                    re-orgs, apps run the risk of being fast but inaccurate.
                </p>
            </section>
            <section>
                <a className={pcn('__anchor-point')} id={toSlug(titles.A_BRIGHTER_FUTURE) + '-ap'}></a>
                <h1>Seamless multi-chain indexing with Spec</h1>
                <p>
                    With a single command, Spec can generate <a href={`#${toSlug(titles.LIVE_TABLES)}`}>live, multi-chain, Postgres tables</a> for all events 
                    and transactions in a protocol or group of custom contracts.
                </p>
                <p>Take <a href='https://allo.gitcoin.co' target='_blank'>Gitcoin's Allo protocol</a> for example:</p>
                <AddContractsTerminal/>
                <p>
                    When new contracts are added to Spec, all unique events are indexed, merged across chains, and then published as <a href={`#${toSlug(titles.LIVE_TABLES)}`}>Live Postgres Tables</a> in Spec's global ecosystem of data. 
                </p>
                <p>Developers everywhere can then...</p>
                <ol style={{ marginTop: 20, marginBottom: 15 }}>
                    <li>
                        { false ? 
                            <a href='https://spec.dev/allov2/events' target='_blank'>Browse this data in the Spec ecosystem</a> :
                            <span>Browse this data in the Spec ecosystem</span>
                        }
                    </li>
                    <li><a href={`#${toSlug(titles.LIVE_TABLES)}`}>Instantly add these Live Tables to their own database</a></li>
                    <li><a href={`#${toSlug(titles.LIVE_OBJECTS)}`}>Create custom Live Tables powered by these events</a></li>
                </ol>
            </section>
            <section>
                <a className={pcn('__anchor-point')} id={toSlug(titles.LIVE_TABLES) + '-ap'}></a>
                <h1 className='--icon-shift'>
                    <span className='blink-indicator blink-indicator--white'><span></span></span>
                    {window.innerWidth > 600 ? titles.LIVE_TABLES : 'Live Postgres Tables'}
                </h1>
                <p>
                    What's better than scraping APIs and dodging rate-limits to get the on-chain data you need? 
                    Postgres tables that automatically source their own data.
                </p>
                <AddTablesTerminal/>
                <p style={{ letterSpacing: 0.05, marginTop: -20 }}>
                    Live Tables can exist anywhere in your database, and you can fully control everything about them —
                    how they're filtered, indexed, named, etc. But unlike regular tables, Live Tables are fully autonomous:
                </p>
                <MdIconBullets items={liveTablesOverviewBullets}/>
                <h2>
                    { window.innerWidth >= 600 ? 'Create live, dynamic relationships' : 'Create dynamic relationships' }
                </h2>
                <p>
                    One of the most powerful features of Live Tables is the ability to create live relationships — where one table is dynamically populated based on the contents of another.
                </p>
                <p>Live relationships are perfect for a variety of situations, such as when...</p>
                <ol>
                    <li>You only need a filtered subset of data.</li>
                    <li>You're interested in joining data between seemingly unrelated protocols.</li>
                    <li>You need one table to populate based on another table that you're actively <i>writing</i> to.</li>
                </ol>
                <p>
                    For example, say you have a table of user wallets, and you need a new Live Table 
                    with all <a href='https://lens.xyz' target='_blank'>Lens</a> profiles 
                    <i> owned by</i> those wallets{window.innerWidth <= 1160 ? '.' : ':'}
                    { window.innerWidth <= 1160 && <span> Spec makes this dead simple — just add a new Live Table that's <i>filtered on</i> the wallets table:</span> }
                </p>
                <div style={{
                    position: 'relative', 
                    marginTop: 25, 
                    width: window.innerWidth < 600 ? 'calc(100% + 18px)' : '100%',
                    left: window.innerWidth < 600 ? -9 : 0,
                }}>
                    <MdVideo
                        id='liveRelationships'
                        src={s3(window.innerWidth < 600 ? 'lm.mp4' : 'rel.mp4')}
                        ph={s3(window.innerWidth < 600 ? 'lm.jpg' : 'rel.jpg')}
                        type='video/mp4'
                        playOnScrollEnter={true}
                        controls={false}
                        muted={true}
                        loop={true}
                        onPlay={() => liveRelationshipStepsAnnoRef.current?.show()}
                        onTimeUpdate={time => {
                            if (time >= timing.selectStepStart && selectStepRef.current) {
                                selectStepRef.current.style.opacity = 1
                            }
                            if (time >= timing.filterStepStart && filterStepRef.current) {
                                filterStepRef.current.style.opacity = 1
                            }
                            if (time >= timing.celebrateStepStart && celebrateStepRef.current) {
                                celebrateStepRef.current.style.opacity = 1
                            }
                        }}
                    />
                    <Anno className='--auto-populate-steps' title='LIVE RELATIONSHIP' icon={blistIcon} ref={liveRelationshipStepsAnnoRef}>
                        <ol className='custom-ol'>
                            <li>Search Live Tables</li>
                            <li style={{ opacity: 0 }} ref={selectStepRef}>Choose Lens Profiles</li>
                            <li style={{ opacity: 0 }} ref={filterStepRef}>Filter on the wallets table</li>
                            <li style={{ opacity: 0 }} ref={celebrateStepRef}>Create 🎉</li>
                        </ol>
                    </Anno>
                </div>
            </section>
            <section>
                <a className={pcn('__anchor-point')} id={toSlug(titles.LIVE_OBJECTS) + '-ap'}></a>
                <h1>{titles.LIVE_OBJECTS}</h1>
                <p>
                    Hell yeah you can write your own Live Tables...&nbsp;😎
                </p>
                <p>
                    Spec's indexing framework lets you easily define custom Live Tables that build upon our ecosystem of multi-chain events.
                    This is perfect for curating higher-level, contextual data models or data that needs to update in-place. 
                </p>
                <MdIconBullets items={liveObjectsOverviewBullets}/>
                <h2>Write your own Live Tables</h2>
                <p>
                    Programmatically, we designed Live Tables to be as simple and intuitive as possible — both to read and to write.
                    To create one, simply:
                </p>
                <ol style={{ marginBottom: 5 }}>
                    <li>Create a new class (table)</li>
                    <li>Define its properties (columns)</li>
                    <li>Specify what causes it to change over time{ window.innerWidth < 600 ? '' : ' (events)' }</li>
                </ol>
                <DotSplit id='liveObjectExampleDotSplit'/>
                <LiveObjectCodeExamples/>
                <h2>Publish once, access anywhere.</h2>
                <p>
                    Once your Live Tables are published and indexed by Spec, they become 100% portable and accessible from anywhere.
                </p>
                <MdIconBullets items={portabilityBullets}/>
            </section>
            <section id={toSlug(titles.EARLY_ACCESS)}>
                <a className={pcn('__anchor-point')} id={toSlug(titles.EARLY_ACCESS) + '-ap'}></a>
                <h1>{titles.EARLY_ACCESS}</h1>
                <p>
                    We're currently partnering with leading protocols and builders from across web3, offering early access to 
                    Spec's ecosystem and our suite of new data tooling. To reserve your protocol's namespace in the ecosystem, 
                    request early access below and join our dev community on <a href={'#'} target='_blank' rel="noreferrer">Discord</a>!
                </p>
                <div class='md-row md-row--between'>
                    <div className='button-wrapper'>
                        <ActionButton
                            text='Request early access'
                            icon='slide-arrow'
                            className=''
                            fill={true}
                            onClick={() => {
                                window.location = ('https://spec.dev' + paths.JOIN_WAITLIST)
                            }}
                        />
                        <ActionButton
                            text='Join our Discord'
                            icon='slide-arrow'
                            ghost={true}
                            onClick={() => window.open(links.DISCORD, '_blank')}
                        />
                    </div>
                    <div className={pcn('__cta-icon-links')}>
                        <a
                            className={cn('custom', pcn('__cta-icon-link', '__cta-icon-link--github'))}
                            target='_blank'
                            tabIndex='-1'
                            rel="noreferrer"
                            href={ links.GITHUB_ORG }
                            dangerouslySetInnerHTML={{ __html: githubIcon }}>
                        </a>
                        <a
                            className={cn('custom', pcn('__cta-icon-link', '__cta-icon-link--twitter'))}
                            target='_blank'
                            tabIndex='-1'
                            rel="noreferrer"
                            href={ links.TWITTER }
                            dangerouslySetInnerHTML={{ __html: twitterIcon }}>
                        </a>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default IntroducingSpec