const multichain = `<svg viewBox="0 0 93.978 66.964">
    <g fill-rule="evenodd">
        <g>
            <path d="M14.167,0 C21.985,0 28.333,6.348 28.333,14.167 C28.333,21.985 21.985,28.333 14.167,28.333 C6.348,28.333 0,21.985 0,14.167 C0,6.348 6.348,0 14.167,0 Z M14.167,5.605 C18.892,5.605 22.728,9.441 22.728,14.167 C22.728,18.892 18.892,22.728 14.167,22.728 C9.441,22.728 5.605,18.892 5.605,14.167 C5.605,9.441 9.441,5.605 14.167,5.605 Z" id="Shape"></path>
            <path d="M14.167,38.631 C21.985,38.631 28.333,44.979 28.333,52.797 C28.333,60.616 21.985,66.964 14.167,66.964 C6.348,66.964 0,60.616 0,52.797 C0,44.979 6.348,38.631 14.167,38.631 Z M14.167,44.236 C18.892,44.236 22.728,48.072 22.728,52.797 C22.728,57.523 18.892,61.359 14.167,61.359 C9.441,61.359 5.605,57.523 5.605,52.797 C5.605,48.072 9.441,44.236 14.167,44.236 L14.167,44.236 Z" id="Shape"></path>
            <path d="M79.811,0 C87.63,0 93.978,6.348 93.978,14.167 C93.978,21.985 87.63,28.333 79.811,28.333 C71.993,28.333 65.645,21.985 65.645,14.167 C65.645,6.348 71.993,0 79.811,0 Z M79.811,5.605 C84.537,5.605 88.373,9.441 88.373,14.167 C88.373,18.892 84.537,22.728 79.811,22.728 C75.086,22.728 71.25,18.892 71.25,14.167 C71.25,9.441 75.086,5.605 79.811,5.605 Z" id="Shape"></path>
            <path d="M79.811,38.631 C87.63,38.631 93.978,44.979 93.978,52.797 C93.978,60.616 87.63,66.964 79.811,66.964 C71.993,66.964 65.645,60.616 65.645,52.797 C65.645,44.979 71.993,38.631 79.811,38.631 Z M79.811,44.236 C84.537,44.236 88.373,48.072 88.373,52.797 C88.373,57.523 84.537,61.359 79.811,61.359 C75.086,61.359 71.25,57.523 71.25,52.797 C71.25,48.072 75.086,44.236 79.811,44.236 L79.811,44.236 Z" id="Shape"></path>
            <rect id="Rectangle" x="24.095" y="49.995" width="44.373" height="5.605"></rect>
            <rect id="Rectangle" x="24.095" y="11.364" width="44.373" height="5.605"></rect>
        </g>
    </g>
</svg>`

export default multichain