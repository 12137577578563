import React, { useCallback, useState, useMemo } from 'react'
import { getPCN } from '../../../utils/classes'
import SelectInput from '../../shared/inputs/SelectInput'
import gearIcon from '../../../svgs/svgjs/gear-thin'
import linkIcon from '../../../svgs/svgjs/link'
import triangleIcon from '../../../svgs/svgjs/triangle'
import codeCaretsIcon from '../../../svgs/svgjs/code-carets'

const className = 'required-links'
const pcn = getPCN(className)

const getTableColOptions = liveObjectSpec => {
    let values = []
    if (liveObjectSpec.typeDef.name === 'ENSProfile') {
        values = [
            'wallets.id',
            'wallets.user_id',
            'wallets.address',
            'users.id',
        ]
    } else if (liveObjectSpec.typeDef.name === 'NFT') {
        values = [
            'nfts.id',
            'nfts.wallet_id',
            'nfts.collection',
            'nfts.contract',
            'nfts.token_id',
            'nfts.name',
            'nfts.description',
            'nfts.image',
            'nfts.attributes',
            'wallets.id',
            'wallets.user_id',
            'wallets.address',
            'wallets.domain',
            'wallets.avatar',
            'users.id',
        ]
    } else if (liveObjectSpec.typeDef.name === 'AaveUserPosition') {
        values = [
            'nfts.id',
            'nfts.wallet_id',
            'nfts.collection',
            'nfts.contract',
            'nfts.token_id',
            'nfts.name',
            'nfts.description',
            'nfts.image',
            'nfts.attributes',
            'wallet_aave_positions.id',
            'wallet_aave_positions.wallet_id',
            'wallet_aave_positions.asset',
            'wallet_aave_positions.deposited',
            'wallet_aave_positions.stable_debt',
            'wallet_aave_positions.variable_debt',
            'wallet_aave_positions.updated_at',
            'wallets.id',
            'wallets.user_id',
            'wallets.address',
            'wallets.domain',
            'wallets.avatar',
            'users.id',
        ]
    } else if (liveObjectSpec.typeDef.name === 'CompoundAccountHealth') {
        values = [
            'borrowers.address',
        ]
    }

    return values.map(v => ({ value: v, label: v }))
}

const getTableColValues = liveObjectSpec => {
    if (liveObjectSpec.typeDef.name === 'ENSProfile') {
        return []
    }
    if (liveObjectSpec.typeDef.name === 'NFT') {
        return [
            null,
            'nfts.contract',
            'nfts.token_id',
        ]
    }
    if (liveObjectSpec.typeDef.name === 'AaveUserPosition') {
        return [
            null,
            'wallet_aave_positions.asset',
        ]
    }
    if (liveObjectSpec.typeDef.name === 'CompoundAccountHealth') {
        return ['borrowers.address']
    }
    return []
}

function RequiredLinks(props) {
    const { properties = [], liveObjectSpec = {} } = props
    const tableColOptions = useMemo(() => getTableColOptions(liveObjectSpec), [liveObjectSpec])
    const [values, setValues] = useState(getTableColValues(liveObjectSpec))

    const updateValueAtIndex = useCallback((value, index) => {
        const newValues = []
        for (let i = 0; i < properties.length; i++) {
            newValues.push(i === index ? value : values[i])
        }
        setValues(newValues)
    }, [properties, values])

    const renderLinks = useCallback(() => properties.map((p, i) => (
        <div key={i} className={pcn('__link', values[i] ? '__link--linked' : '')}>
            <div className={pcn('__link-root')}>
                <span>{liveObjectSpec.typeDef.name}.</span>
                <span>{p.name}</span>
                <span dangerouslySetInnerHTML={{ __html: linkIcon }}></span>
            </div>
            <div className={pcn('__link-arrow')}>
                <span></span>
                <span>is</span>
                <span dangerouslySetInnerHTML={{ __html: triangleIcon }}></span>
            </div>
            <SelectInput
                className={pcn('__link-field')}
                classNamePrefix='spec'
                value={values[i]}
                options={tableColOptions}
                placeholder='Select column'
                isRequired={true}
                updateFromAbove={true}
                includeMenuIsOpen={true}
                onChange={value => updateValueAtIndex(value, i)}
            />
            <div
                className={pcn('__link-icon-button', '__link-icon-button--settings')}
                dangerouslySetInnerHTML={{ __html: gearIcon }}>
            </div>
            <div
                className={pcn('__link-icon-button', '__link-icon-button--function')}
                dangerouslySetInnerHTML={{ __html: codeCaretsIcon }}>
            </div>
        </div>
    )), [properties, values, liveObjectSpec, updateValueAtIndex])

    return (
        <div className={className}>
            { renderLinks() }
        </div>
    )
}

export default RequiredLinks