import React, { useCallback, useRef, useEffect, useImperativeHandle, forwardRef, useLayoutEffect } from 'react'
import { getPCN } from '../../../utils/classes'
import { noop } from '../../../utils/nodash'
import { isElementInView } from '../../../utils/doc'
import { isMobileSafari } from '../../../utils/device'

const className = 'md-video'
const pcn = getPCN(className)

const timing = {
    initialDelay: 1000
}

const events = {
    timeUpdate: 'timeupdate',
}

function MdVideo(props, ref) {
    const { id, ph, controls, muted, loop, src, type, playOnScrollEnter, onPlay = noop, onTimeUpdate = noop } = props
    const observerTargetId = `${id}ObserverTarget`
    const observerCreated = useRef(false)
    const observerCalled = useRef(false)
    const videoRef = useRef()
    const lastTime = useRef(0)

    const playVideo = useCallback(() => {
        videoRef.current?.play()
        onPlay()
    })

    useImperativeHandle(ref, () => ({
        seek: time => {
            if (!videoRef.current) return
            videoRef.current.currentTime = time
        }
    }))

    const createIntersectionObserver = useCallback(() => {
        const observer = new IntersectionObserver( entries => {
            if (entries && entries[0] && entries[0].isIntersecting && !observerCalled.current) {
                observerCalled.current = true
                setTimeout(playVideo, timing.initialDelay)
            }
        }, { threshold: 0 })

        const el = document.querySelector( `#${observerTargetId}` )
        el && observer.observe( el )
    }, [playVideo])

    useEffect(() => {
        if (!playOnScrollEnter || observerCreated.current) return
        observerCreated.current = true

        if ( window.IntersectionObserver ) {
            setTimeout( () => {
                isElementInView( `#${observerTargetId}` )
                    ? setTimeout(playVideo, timing.initialDelay)
                    : createIntersectionObserver()
            }, 500 )
        } else {
            setTimeout( () => playVideo(), timing.initialDelay )
        }
    }, [createIntersectionObserver, playVideo])

    const handleTimeUpdate = useCallback(() => {
        if (!videoRef.current) return

        const currentTime = Math.floor(videoRef.current.currentTime)
        if (currentTime === lastTime.current) return

        lastTime.current = currentTime
        onTimeUpdate(currentTime)
    }, [onTimeUpdate])

    useEffect(() => {
        if (!videoRef.current) return
        videoRef.current.addEventListener(events.timeUpdate, handleTimeUpdate)
        return () => {
            if (!videoRef.current) return
            videoRef.current.removeEventListener(events.timeUpdate, handleTimeUpdate)
        }
    }, [handleTimeUpdate])

    return (
        <div className={className}>
            <video controls={isMobileSafari()} muted loop playsInline poster={ph} ref={videoRef}>
                <source src={src} type={type}/>
            </video>
            { playOnScrollEnter && (
                <div id={observerTargetId} className={pcn('__observer-target')}>
                </div>
            )}
        </div>
    )
}

MdVideo = forwardRef(MdVideo)
export default MdVideo