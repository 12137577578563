export const chainIds = {
    ETHEREUM: '1',
    GOERLI: '5',
    POLYGON: '137',
    MUMBAI: '80001',
    BASE: '8453',
    OPTIMISM: '10',
    ARBITRUM: '42161',
    ARBITRUM_SEPOLIA: '421614',
    PGN: '424',
    CELO: '42220',
    LINEA: '59144',
    SEPOLIA: '11155111',
}

export const chainNames = {
    [chainIds.ETHEREUM]: 'Ethereum',
    [chainIds.GOERLI]: 'Goerli',
    [chainIds.POLYGON]: 'Polygon',
    [chainIds.MUMBAI]: 'Mumbai',
    [chainIds.BASE]: 'Base',
    [chainIds.OPTIMISM]: 'Optimism',
    [chainIds.ARBITRUM]: 'Arbitrum',
    [chainIds.ARBITRUM_SEPOLIA]: 'Arbitrum Sepolia',
    [chainIds.PGN]: 'PGN',
    [chainIds.CELO]: 'Celo',
    [chainIds.LINEA]: 'Linea',
    [chainIds.SEPOLIA]: 'Sepolia',
}

export const chainNamespaces = {
    ETHEREUM: 'eth',
    GOERLI: 'goerli',
    POLYGON: 'polygon',
    MUMBAI: 'mumbai',
    BASE: 'base',
    OPTIMISM: 'optimism',
    ARBITRUM: 'arbitrum',
    ARBITRUM_SEPOLIA: 'arbitrumsepolia',
    PGN: 'pgn',
    CELO: 'celo',
    LINEA: 'linea',
    SEPOLIA: 'sepolia',
}

export const namespaceForChainId = {
    [chainIds.ETHEREUM]: chainNamespaces.ETHEREUM,
    [chainIds.GOERLI]: chainNamespaces.GOERLI,
    [chainIds.POLYGON]: chainNamespaces.POLYGON,
    [chainIds.MUMBAI]: chainNamespaces.MUMBAI,
    [chainIds.BASE]: chainNamespaces.BASE,
    [chainIds.OPTIMISM]: chainNamespaces.OPTIMISM,
    [chainIds.ARBITRUM]: chainNamespaces.ARBITRUM,
    [chainIds.ARBITRUM_SEPOLIA]: chainNamespaces.ARBITRUM_SEPOLIA,
    [chainIds.PGN]: chainNamespaces.PGN,
    [chainIds.CELO]: chainNamespaces.CELO,
    [chainIds.LINEA]: chainNamespaces.LINEA,
    [chainIds.SEPOLIA]: chainNamespaces.SEPOLIA,
}

export const avgBlockTimesForChainId = {
    [chainIds.ETHEREUM]: 12,
    [chainIds.GOERLI]: 12,
    [chainIds.POLYGON]: 2,
    [chainIds.MUMBAI]: 2,
    [chainIds.BASE]: 2,
    [chainIds.OPTIMISM]: 2,
    [chainIds.ARBITRUM]: 0.3,
    [chainIds.ARBITRUM_SEPOLIA]: 2,
    [chainIds.PGN]: 2,
    [chainIds.CELO]: 5,
    [chainIds.LINEA]: 12,
    [chainIds.SEPOLIA]: 12,
}

const blockExplorerOrigins = {
    [chainIds.ETHEREUM]: 'https://etherscan.io',
    [chainIds.GOERLI]: 'https://goerli.etherscan.io',
    [chainIds.POLYGON]: 'https://polygonscan.com',
    [chainIds.MUMBAI]: 'https://mumbai.polygonscan.com',
    [chainIds.BASE]: 'https://basescan.org',
    [chainIds.OPTIMISM]: 'https://optimistic.etherscan.io',
    [chainIds.ARBITRUM]: 'https://arbiscan.io',
    [chainIds.ARBITRUM_SEPOLIA]: 'https://sepolia.arbiscan.io',
    [chainIds.PGN]: 'https://explorer.publicgoods.network',
    [chainIds.CELO]: 'https://explorer.celo.org/mainnet',
    [chainIds.LINEA]: 'https://lineascan.build',
    [chainIds.SEPOLIA]: 'https://sepolia.etherscan.io',
}

export const primitiveNamespaces = new Set([...Object.values(chainNamespaces), 'tokens'])

export const isContractNamespace = (nsp) => nsp.includes('.')

export const toBlockExplorerAddressLink = (address, chainId) => {
    const origin = blockExplorerOrigins[chainId]
    if (!origin) return ''
    return [origin, 'address', address].join('/')
}