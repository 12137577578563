import caret from './svgjs/caret'
import specIcon from './svgjs/spec-icon'
import specIconDark from './svgjs/spec-icon-dark'
import star from './svgjs/star'
import metamask from './svgjs/metamask'
import walletconnect from './svgjs/walletconnect'
import walletlink from './svgjs/walletlink'
import fortmatic from './svgjs/fortmatic'
import torus from './svgjs/torus'
import github from './svgjs/github'
import letter from './svgjs/letter'
import tx1 from './svgjs/tx1'
import tx2 from './svgjs/tx2'
import tx3 from './svgjs/tx3'
import nfts from './svgjs/nfts'
import identity from './svgjs/identity'
import defi from './svgjs/defi'
import governance from './svgjs/governance'
import chainAnalytics from './svgjs/chain-analytics'
import heart from './svgjs/heart'
import check from './svgjs/check'
import arrowRight from './svgjs/arrow-right'
import link from './svgjs/link'
import deploy from './svgjs/deploy'
import sub from './svgjs/sub'
import invoke from './svgjs/invoke'
import slideArrow from './svgjs/slide-arrow'
import ethereum from './svgjs/ethereum'
import polygon from './svgjs/polygon'
import arbitrum from './svgjs/arbitrum'
import solana from './svgjs/solana'
import optimism from './svgjs/optimism'
import base from './svgjs/base'

const icons = {
    ethereum,
    polygon,
    arbitrum,
    solana,
    optimism,
    base,
    link,
    check,
    caret,
    star,
    metamask,
    walletconnect,
    walletlink,
    fortmatic,
    torus,
    letter,
    github,
    tx1,
    tx2,
    tx3,
    identity,
    defi,
    governance,
    nfts,
    heart,
    arrowRight,
    deploy,
    sub,
    invoke,
    'slide-arrow': slideArrow,
    'chain-analytics': chainAnalytics,
    'spec-icon': specIcon,
    'spec-icon-dark': specIconDark,
}

export default icons
