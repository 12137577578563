import Header from '../shared/header/Header'
import SplashSection from './splash/SplashSection'
import DataCategoriesSection from './data-categories/DataCategoriesSection'
import LayersSection from './layers/LayersSection'
import RewardsSection from './rewards/RewardsSection'
import ClientSection from './client/ClientSection'
import Footer from '../shared/footer/Footer'
import LiveTableSection from './live-table/LiveTableSection'
import LiveColumnSection from './live-table/LiveColumnSection'

function HomePage() {
    return (
        <div id='home'>
            <Header/>
            <SplashSection/>
            <DataCategoriesSection/>
            <LiveTableSection/>
            <LiveColumnSection/>
            <ClientSection/>
            <Footer/>
        </div>
    )
}

export default HomePage
