import { useCallback, useEffect, useState } from 'react'
import { chainNames } from '../../utils/chains'
import { getPCN } from '../../utils/classes'
import { Link } from 'react-router-dom'
import { extractCustomerNspFromContractNsp } from '../../utils/formatters'
import { format as toTimeAgo } from 'timeago.js'
import ImageComp from '../shared/ImageComp'
import PH from '../shared/PH'
import verifiedIcon from '../../svgs/svgjs/verified'
import { paths } from '../../utils/nav'

const className = 'contract-group-header'
const pcn = getPCN(className)

export const ContractGroupHeader = ({ contractGroup, isLoading }) => {
    const nsp = contractGroup.namespace || {}
    const customerNsp = extractCustomerNspFromContractNsp(nsp.slug) || ''
    const timeSinceCreated = contractGroup.createdAt ? toTimeAgo(contractGroup.createdAt) : ''
    const timeSinceUpdated = contractGroup.updatedAt ? toTimeAgo(contractGroup.updatedAt) : ''
    const chainIds = nsp.chainIds || []
    const sortedChainIds = chainIds 
        .map(v => Number(v))
        .sort((a, b) => a !== 42161 ? -1 : a - b)
        .map(v => v.toString())

    const supportedChainNames = sortedChainIds.map(chainId => chainNames[chainId])

    const [recordStats, setRecordStats] = useState({
        count: contractGroup.numInstances,
        lastInteraction: contractGroup.updatedAt ? new Date(contractGroup.updatedAt) : null,
    })

    const renderChains = useCallback(() => {
        return supportedChainNames?.map((chain, i) => 
            <div key={i} className={pcn('__chain', `__chain--${chain.replace(' ', '').toLowerCase()}`)}>
                <span>{chain.split(' ').pop()[0]}</span>
            </div>
    )}, [supportedChainNames])

    useEffect(() => {
        setRecordStats({ 
            count: contractGroup.numInstances,
            lastInteraction: contractGroup.updatedAt ? new Date(contractGroup.updatedAt) : null,
        })
    }, [contractGroup])

    return <div className={className}>
        <div className={pcn('__top')}>
            <div className={pcn('__top-left')}>
                <ImageComp
                    phId='subject-header-icon'
                    src={nsp.icon}
                    blurhash={nsp.blurhash}
                    withBgBlur={true}
                    height={150}
                    width={150}
                />
                <div className={pcn('__body')}>
                    <div className={pcn('__label')}>Contract Group</div>
                    <h2>
                        { contractGroup.name || <PH id='subject-header-name'/> }
                    </h2>
                    <div className={pcn('__icons')}>
                        { isLoading ? <PH id='subject-header-icons'/> : <>
                            <Link className={pcn('__tag')} to={paths.toNamespace(customerNsp)}>
                                { nsp.verified && 
                                    <span
                                        className={pcn('__verified-icon')}
                                        dangerouslySetInnerHTML={{ __html: verifiedIcon }}>    
                                    </span> 
                                }
                                <span>@{customerNsp}</span>
                            </Link>
                            <span className={pcn('__dot')}></span>
                            <div className={pcn('__chains')}>
                                { renderChains() }
                            </div>
                            <span className={pcn('__dot')}></span>
                            <div className={pcn('__created-at')}>created {timeSinceCreated}</div>
                        </> }
                    </div>
                </div>
            </div>
            <div className={pcn('__top-right')}>
                <div>
                    <h4>CONTRACTS</h4>
                    { isLoading ? '--' : contractGroup.numInstances }
                </div>
                <div>
                    <h4>LAST UPDATED</h4>
                    { isLoading ? '--' : <span className='timeago'>{timeSinceUpdated}</span> }
                </div>
            </div>
        </div>
        <div className={pcn('__bottom')}>
            { isLoading ? <PH id='subject-header-bottom'/> : `Deployments of the ${[nsp.slug, contractGroup.name].join('.')} contract.` }
        </div>
    </div>
}