import React, { useEffect, useState, useCallback, useRef, forwardRef, useImperativeHandle} from 'react'
import { getPCN } from '../../../utils/classes'
import NewLiveColumnPanel from './NewLiveColumnPanel'
import linkIcon from '../../../svgs/svgjs/link'
import { isElementInView } from '../../../utils/doc'
import { transform, returnStatement } from '../../../data/custom-transform'
import CustomTransform from './CustomTransform'

const className = 'column-slider-group'
const pcn = getPCN(className)
const objectSectionTargetId = 'objectSectionTargetId'
const colSectionTargetId = 'colSectionTargetId'
const connectSectionTargetId = 'connectSectionTargetId'

const timing = {
    objectSectionAnnoDelay: 600,
    colSectionAnnoDelay: 1000,
    connectSectionAnnoDelay: 1000,
}

function ColumnSliderGroup(props, ref) {
    const { table = {} } = props
    const newLiveColumnPanelRef = useRef()
    const [showObjectAnno, setShowObjectAnno] = useState(false)
    const [showColAnno, setShowColAnno] = useState(false)
    const [showTransformAnno, setShowTransformAnno] = useState(false)
    const [showConnectAnno, setShowConnectAnno] = useState(false)
    const observersCreated = useRef(false)
    const objectObserverCalled = useRef(false)
    const colObserverCalled = useRef(false)
    const connectObserverCalled = useRef(false)
    const canShowConnectAnno = useRef(false)

    const showObjectSectionAnno = useCallback(() => setShowObjectAnno(true), [])

    const startColSectionAnimation = useCallback(() => {
        newLiveColumnPanelRef.current?.selectDataSource(window.innerWidth > 1000 ? 0 : 1, 'healthFactor', 3500)
        setTimeout(() => setShowColAnno(true), 50)

        setTimeout(() => {
            if (window.innerWidth > 1000) {
                newLiveColumnPanelRef.current?.typeColumnName(0, 'compound_health')
                setTimeout(() => {
                    newLiveColumnPanelRef.current?.selectDataSource(1, 'healthFactor', 0)
    
                    setTimeout(() => {
                        newLiveColumnPanelRef.current?.typeColumnName(1, 'can_liquidate')
    
                        setTimeout(() => {
                            newLiveColumnPanelRef.current?.setTransform(1)
    
                            setTimeout(() => {
                                setShowTransformAnno(true)
    
                                setTimeout(() => {
                                    canShowConnectAnno.current = true
    
                                    if (connectObserverCalled.current && !showConnectAnno) {
                                        setShowConnectAnno(true)
                                    }
                                }, 4600)
                            }, 100)
                        }, 1400)
                    }, 700)
                }, 1800)    
            } else {
                newLiveColumnPanelRef.current?.typeColumnName(1, 'can_liquidate')
                setTimeout(() => {
                    newLiveColumnPanelRef.current?.setTransform(1)
                    setTimeout(() => {
                        setShowTransformAnno(true)
                    }, 100)
                }, 2000)
            }
        }, 4000)
    }, [showConnectAnno])

    const startConnectSectionAnimation = useCallback(() => {
        if (canShowConnectAnno.current && !showConnectAnno) {
            setShowConnectAnno(true)
        }
    }, [showConnectAnno])

    const createObjectObserver = useCallback(() => {
        const objectObserver = new IntersectionObserver( entries => {
            if (entries && entries[0] && entries[0].isIntersecting && !objectObserverCalled.current) {
                objectObserverCalled.current = true
                setTimeout(showObjectSectionAnno, timing.objectSectionAnnoDelay)
            }
        }, { threshold: 0 })

        const el = document.querySelector( `#${objectSectionTargetId}` )
        el && objectObserver.observe( el )
    }, [showObjectSectionAnno])

    const createColObserver = useCallback(() => {
        const colObserver = new IntersectionObserver( entries => {
            if (entries && entries[0] && entries[0].isIntersecting && !colObserverCalled.current) {
                colObserverCalled.current = true
                setTimeout(startColSectionAnimation, timing.colSectionAnnoDelay)
            }
        }, { threshold: 0 })

        const el = document.querySelector( `#${colSectionTargetId}` )
        el && colObserver.observe( el )
    }, [startColSectionAnimation])

    const scheduleConnectAnno = useCallback(() => {
        // if ()
        setTimeout(startConnectSectionAnimation, timing.connectSectionAnnoDelay)
    }, [])

    const createConnectObserver = useCallback(() => {
        const connectObserver = new IntersectionObserver( entries => {
            if (entries && entries[0] && entries[0].isIntersecting && !connectObserverCalled.current) {
                connectObserverCalled.current = true
                scheduleConnectAnno()
            }
        }, { threshold: 0 })

        const el = document.querySelector( `#${connectSectionTargetId}` )
        el && connectObserver.observe( el )
    }, [scheduleConnectAnno])

    const initObservers = useCallback(() => {
        if (observersCreated.current) return
        observersCreated.current = true

        if ( window.IntersectionObserver ) {
            setTimeout( () => {
                // Object section
                isElementInView( `#${objectSectionTargetId}` )
                    ? setTimeout(showObjectSectionAnno, timing.objectSectionAnnoDelay)
                    : createObjectObserver()

                // Col section
                isElementInView( `#${colSectionTargetId}` )
                    ? setTimeout(startColSectionAnimation, timing.colSectionAnnoDelay)
                    : createColObserver()

                // Connect section
                isElementInView( `#${connectSectionTargetId}` )
                    ? scheduleConnectAnno()
                    : createConnectObserver()
            }, 50)
        } else {
            setTimeout(showObjectSectionAnno, timing.objectSectionAnnoDelay)
            setTimeout(startColSectionAnimation, timing.colSectionAnnoDelay)
            scheduleConnectAnno()
        }
    }, [createObjectObserver, showObjectSectionAnno, createColObserver, startColSectionAnimation, createConnectObserver, scheduleConnectAnno])

    useImperativeHandle(ref, () => ({
        search: (...args) => newLiveColumnPanelRef.current?.search(...args),
        addTop: () => newLiveColumnPanelRef.current?.addTop(),
        selectLiveObject: spec => newLiveColumnPanelRef.current?.selectLiveObject(spec),
        initObservers,
    }))

    const renderObjectAnnotation = useCallback(() => (
        <div className={pcn(
            '__object-anno',
            '__object-anno--animate',
            !showObjectAnno ? '__object-anno--hide' : '',
        )}>
            <div className={pcn('__object-anno-liner')}>
                <div className={pcn('__object-anno-top')}>
                    <span>{ '{}' }</span>
                    <span>Select a Live Object</span>
                </div>
                <div className={pcn('__object-anno-bottom')}>
                    <span>{ window.innerWidth <= 1000 
                        ? 'Search the Spec ecosystem for the object you want to use as a source of data for your new column.'
                        : 'Spec curates data as bite-sized objects with specific type definitions, called Live Objects, which power all Live Tables & Columns.'
                    }</span>
                </div>
            </div>
        </div>
    ), [showObjectAnno])

    const renderColAnnotation = useCallback(() => (
        <div className={pcn(
            '__col-anno',
            '__col-anno--animate',
            !showColAnno ? '__col-anno--hide' : '',
        )}>
            <div className={pcn('__col-anno-liner')}>
                <div className={pcn('__col-anno-top')}>
                    <span></span>
                    <span>Select a Property</span>
                </div>
                <div className={pcn('__col-anno-bottom')}>
                    <span>{ window.innerWidth <= 580 ? 'Choose a specific property to be your column\'s source of data.' : 'Assign a property of the Live Object as the source of data for your new column.'}</span>
                </div>
            </div>
        </div>
    ), [showColAnno])

    const renderTransformAnnotation = useCallback(() => (
        <div className={pcn(
            '__transform-anno',
            '__transform-anno--animate',
            !showTransformAnno ? '__transform-anno--hide' : '',
        )}>
            <div className={pcn('__transform-anno-liner')}>
                <div className={pcn('__transform-anno-top')}>
                    <span>TS</span>
                    <span>Write Custom Transforms</span>
                </div>
                <div className={pcn('__transform-anno-bottom')}>
                    <CustomTransform
                        code={transform}
                        startTyping={showTransformAnno}
                        lines={['boolean', returnStatement]}
                    />
                </div>
            </div>
        </div>
    ), [showTransformAnno])

    const renderConnectAnnotation = useCallback(() => (
        <div className={pcn(
            '__connect-anno',
            '__connect-anno--animate',
            !showConnectAnno ? '__connect-anno--hide' : '',
        )}>
            <div className={pcn('__connect-anno-liner')}>
                <div className={pcn('__connect-anno-top')}>
                    <span dangerouslySetInnerHTML={{ __html: linkIcon }}></span>
                    <span>Link the Live Object</span>
                </div>
                <div className={pcn('__connect-anno-bottom')}>
                    <span>Link the Live Object to your table schema so that your Live Columns know how to sync their own data.</span>
                </div>
            </div>
        </div>
    ), [showConnectAnno])

    return (
        <div className={className}>
            <NewLiveColumnPanel
                table={table}
                ref={newLiveColumnPanelRef}
            />
            { renderObjectAnnotation() }
            { renderColAnnotation() }
            { renderTransformAnnotation() }
            { renderConnectAnnotation() }
            <div id={objectSectionTargetId}></div>
            <div id={colSectionTargetId}></div>
            <div id={connectSectionTargetId}></div>
        </div>
    )
}

ColumnSliderGroup = forwardRef(ColumnSliderGroup)
export default ColumnSliderGroup