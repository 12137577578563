import { useCallback } from 'react'
import { getPCN, cn } from '../../utils/classes'
import ChainAvatar from '../shared/ChainAvatar'
import { formatDesc } from '../../utils/formatters'
import { useHistory, Link } from 'react-router-dom'
import PH from '../shared/PH'
import docIcon from '../../svgs/svgjs/doc'
import { paths, sections } from '../../utils/nav'
import DotSplit from '../shared/DotSplit'

const className = 'namespace-resource'
const pcn = getPCN(className)

export const resourceNames = {
    ALL: 'all',
    LIVE_OBJECTS: sections.LIVE_OBJECTS,
    CONTRACT_GROUPS: sections.CONTRACT_GROUPS,
    EVENTS: sections.EVENTS,
}

export const resources = [
    {
        name: resourceNames.ALL,
        displayName: 'All',
        desc: '',
        width: 65,
        link: nsp => paths.toNamespace(nsp),
    },
    {
        name: resourceNames.LIVE_OBJECTS,
        displayName: 'Live Tables',
        desc: '',
        width: 88.47,
        previewSize: 5,
        maxPlaceholders: 10,
        link: nsp => paths.toLiveObjects(nsp),
    },
    {
        name: resourceNames.CONTRACT_GROUPS,
        displayName: 'Contract Groups',
        desc: 'Multi-chain contract deployments.',
        width: 122.26,
        previewSize: 4,
        maxPlaceholders: 8,
        link: nsp => paths.toContractGroups(nsp),
    },
    {
        name: resourceNames.EVENTS,
        displayName: 'Events',
        desc: 'Multi-chain contract events.',
        width: 65,
        previewSize: 5,
        maxPlaceholders: 10,
        link: nsp => paths.toEvents(nsp),
    },
]

export const NamespaceResource = ({ slug, resource, resourceData, isPreview }) => {
    const history = useHistory()
    const listData = isPreview ? resourceData?.slice(0, resource.previewSize) : resourceData
    const isLoading = !listData

    const onClickItem = useCallback((item) => {
        if (resource.name === resourceNames.LIVE_OBJECTS) {
            history.push(paths.toLiveObject(slug, item.latestVersion.id))
        } else if (resource.name === resourceNames.CONTRACT_GROUPS) {
            const group = item.groupName.split('.')[1]
            history.push(paths.toContractGroup(slug, group))
        } else if (resource.name === resourceNames.EVENTS) {
            history.push(paths.toEvent(slug, item.latestVersion.id))
        }
    }, [resource, history, slug])

    const renderHeader = useCallback(() => {
        const showViewAll = isPreview &&
            !isLoading &&
            resource.name === resourceNames.CONTRACT_GROUPS &&
            (resourceData || []).length > resource.previewSize

        return isPreview && resource.name !== resourceNames.LIVE_OBJECTS 
            ? <div className={pcn('__header')}>
                <h3>{resource.displayName}</h3>
                <p>{resource.desc}</p>
                { showViewAll && (
                    <div className={pcn('__see-more', '__view-all')} onClick={() => {
                        history.push(resource.link(slug))
                    }}>
                        View all
                    </div>
                )}
            </div> 
            : ''
    }, [isPreview, resource, isLoading, resourceData, history, slug])

    const renderLiveObject = useCallback((liveObject) => {
        const chainIds = Object.keys(liveObject.latestVersion.config.chains)
        return (
            <div className={pcn('__item-body', '__item-body--live-tables')}>
                <div>
                    <h4>{liveObject.name}</h4>
                    <span className={pcn('__desc', `__desc--${resource.name.toLowerCase()}`)}>
                        { liveObject.desc }
                    </span>
                </div>
                <ChainAvatar chainIds={chainIds} className={className}/>
                <p className={pcn('__records')}>{liveObject.records?.toLocaleString('en-US') || 0}</p>
            </div> 
        )
    }, [resource])

    const renderContract = useCallback((contract) => {
        return <div className={pcn('__item-body', '__item-body--contract-groups')}>
            <h4>
                <span dangerouslySetInnerHTML={{ __html: docIcon }}></span>
                <span>{contract.groupName.split('.')[1]}</span>
            </h4>
            <span className={pcn('__desc', `__desc--${resource.name.toLowerCase()}`)}>{formatDesc(contract.desc)}</span>
            <div className={pcn('__detail-wrapper')}>
                <span className={pcn('__detail')}>{`${contract.contractCount}` + (contract.contractCount === 1 ? ' contract' : ' contracts')}</span>
                <span className={pcn('__dot')}></span>
                <ChainAvatar chainIds={contract.chainIds} className={className} single={true}/>
            </div>
        </div>
    }, [resource])

    const renderEvent = useCallback((liveObject) => {
        const chainIds = Object.keys(liveObject.latestVersion.config.chains)
        const descClassNames = [
            '__desc',
            `__desc--${resource.name.toLowerCase()}`,
            ...chainIds.map(id => `__desc--chain-${id}`),
        ]
        const contractGroup = liveObject.latestVersion.nsp
        return (
            <div className={pcn('__item-body', '__item-body--events')}>
                <div>
                    <h4>{liveObject.name}</h4>
                    <span className={pcn(...descClassNames)}>
                        { contractGroup }
                    </span>
                </div>
                <ChainAvatar chainIds={chainIds} className={className}/>
                <p className={pcn('__records')}>
                    <span>{liveObject.records?.toLocaleString('en-US') || 0}</span>
                    <span>{liveObject.latestVersion.version}</span>
                </p>
            </div> 
        )
    }, [resource])

    const renderBody = useCallback((data) => {
        if (resource.name === resourceNames.LIVE_OBJECTS) {
            return renderLiveObject(data)
        } else if (resource.name === resourceNames.CONTRACT_GROUPS) {
            return renderContract(data)
        } else if (resource.name === resourceNames.EVENTS) {
            return renderEvent(data)
        }
    }, [resource, renderEvent, renderContract, renderLiveObject])

    const renderPlaceholders = useCallback(() => {
        const numPlaceholders = isPreview ? resource.previewSize : resource.maxPlaceholders
        const empty = Array.apply(null, Array(numPlaceholders)).map(() => null)
        return empty.map((_, i) => (
            <li
                key={i}
                className={pcn('__item', '__item--ph', `__item--${resource.name.toLowerCase()}`)}>
                <div className={pcn('__item-liner', '__item-liner--ph', `__item-liner--${resource.name.toLowerCase()}`)}>
                    <PH id={resource.name}/>
                </div>
            </li>
        ))
    }, [resource, isPreview])

    const renderSeeMoreLink = useCallback(() => {
        if (!isPreview || 
            isLoading ||
            resource.name === resourceNames.CONTRACT_GROUPS ||
            (resourceData || []).length <= resource.previewSize
        ) {
            return null
        }
        return (
            <>
                <DotSplit/>
                <div className={pcn('__see-more')} onClick={() => {
                    if (resource.name === resourceNames.LIVE_OBJECTS) {
                        window.skipJumpToTop = true
                    }
                    history.push(resource.link(slug))
                }}>
                    See more
                </div>
            </>
        )
    }, [isPreview, isLoading, resource, resourceData, slug])

    return <div className={cn(className, `${className}--${resource.name.toLowerCase()}`)}>
        { renderHeader() }
        <ol className={pcn('__list', `__list--${resource.name.toLowerCase()}`)}>
            { isLoading ? renderPlaceholders() : listData?.map((data, i) => 
                <li
                    key={i}
                    className={pcn('__item', `__item--${resource.name.toLowerCase()}`)}
                    onClick={() => onClickItem(data)}>
                    <div className={pcn('__item-liner', `__item-liner--${resource.name.toLowerCase()}`)}>
                        { renderBody(data) }
                    </div>
                </li>
            )}
        </ol>
        { renderSeeMoreLink() }
    </div>
}