import { getPCN } from '../../../utils/classes'
import DataCategory from './DataCategory'
import pixelsLeft from '../../../svgs/svgjs/banner-left-pixels'
import pixelsRight from '../../../svgs/svgjs/banner-right-pixels'

const dataCategories = [
    'ethereum',
    'polygon',
    'base',
    'optimism',
]

function DataCategoriesSection() {
    const className = 'data-categories'
    const pcn = getPCN(className)

    return (
        <div id='data-categories' className={className}>
            <div
                className='pixels-left'
                dangerouslySetInnerHTML={{ __html: pixelsLeft }}>
            </div>
            <div
                className='pixels-right'
                dangerouslySetInnerHTML={{ __html: pixelsRight }}>
            </div>
            <div className={pcn('__liner')}>
                { dataCategories.map(name => (
                    <DataCategory
                        key={name}
                        iconName={name.replace(' ', '-')}
                        categoryName={name}
                    />
                ))}
            </div>
        </div>
    )
}

export default DataCategoriesSection
