import React from 'react'
import { getPCN } from '../../utils/classes'

const className = 'blog-author'
const pcn = getPCN(className)

function BlogAuthor({ name, title, avatar, link }) {
    return (
        <a className={className} target='_blank' href={link}>
            <div className={pcn('__liner')}>
                <div className={pcn('__avatar')}>
                    <img src={avatar}/>
                </div>
                <div className={pcn('__text')}>
                    <div className={pcn('__name')}>{name}</div>
                    <div className={pcn('__title')}>{title}</div>
                </div>
            </div>
        </a>
    )
}

export default BlogAuthor