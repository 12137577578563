const restApi = `<div class="editor__v-scroll">
    <div class="editor__line-numbers">
        <span>1</span><br/>
        <span>2</span><br/>
        <span>3</span><br/>
        <span>4</span><br/>
        <span>5</span><br/>
        <span>6</span><br/>
        <span>7</span><br/>
        <span>8</span><br/>
        <span>9</span><br/>
        <span>10</span><br/>
        <span>11</span><br/>
        <span>12</span><br/>
        <span>13</span><br/>
        <span>14</span><br/>
        <span>15</span><br/>
    </div>
    <div class="editor__h-scroll">
        <span id="rest-indent1"></span>
        <span id="rest-indent2"></span>
        <span id="rest-indent3"></span>
        <span class="line"><span class="keyword">import</span><span class="default"> spec </span><span class="keyword">from </span><span class="string">'./initClient'</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="comment">// Get all Meebit NFTs from your nfts table.</span></span><br/>
        <span class="line"><span class="keyword">const</span><span class="default"> { data: nfts } = </span><span class="keyword">await</span><span class="default"> spec</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">.</span><span class="function-call">from</span><span class="default">(</span><span class="string">'nfts'</span><span class="default">)</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">.</span><span class="function-call">select</span><span class="default">(</span><span class="string">'*'</span><span class="default">)</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">.</span><span class="function-call">match</span><span class="default">({ collection: </span><span class="string">'Meebits'</span><span class="default"> })</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="comment">// Add new records to your wallets table.</span></span><br/>
        <span class="line"><span class="keyword">const</span><span class="default"> { data: newWallets } = </span><span class="keyword">await</span><span class="default"> spec</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">.</span><span class="function-call">from</span><span class="default">(</span><span class="string">'wallets'</span><span class="default">)</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">.</span><span class="function-call">insert</span><span class="default">([</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="default">{ address: </span><span class="string">'0x...'</span><span class="default"> },</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="default">{ address: </span><span class="string">'0x...'</span><span class="default"> },</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">])</span></span><br/>
    </div>
</div>`

const graphQLApi = `<div class="editor__v-scroll">
    <div class="editor__line-numbers">
        <span>1</span><br/>
        <span>2</span><br/>
        <span>3</span><br/>
        <span>4</span><br/>
        <span>5</span><br/>
        <span>6</span><br/>
        <span>7</span><br/>
        <span>8</span><br/>
        <span>9</span><br/>
        <span>10</span><br/>
        <span>11</span><br/>
        <span>12</span><br/>
        <span>13</span><br/>
        <span>14</span><br/>
        <span>15</span><br/>
        <span>16</span><br/>
    </div>
    <div class="editor__h-scroll">
        <span id="graphql-indent1"></span>
        <span id="graphql-indent2"></span>
        <span id="graphql-indent3"></span>
        <span id="graphql-indent4"></span>
        <span class="line"><span class="keyword">import</span><span class="default"> { gql } </span><span class="keyword">from </span><span class="string">'@spec.dev/client'</span></span><br/>
        <span class="line"><span class="keyword">import</span><span class="default"> spec </span><span class="keyword">from </span><span class="string">'./initClient'</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="comment">// Get all wallets and their associated NFTs.</span></span><br/>
        <span class="line"><span class="keyword">const</span><span class="default"> { data } = </span><span class="keyword">await</span><span class="default"> spec.graph.</span><span class="function-call">query</span><span class="default">(</span><span class="function-call">gql</span><span class="string">&#96;</span></span><br/>
        <span class="line"><span class="tab"></span><span class="string">query getWalletsAndNFTs {</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="string">wallets {</span></span><br/>        
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="tab"></span><span class="string">address</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="tab"></span><span class="string">nfts {</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="tab"></span><span class="tab"></span><span class="string">collection</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="tab"></span><span class="tab"></span><span class="string">tokenId</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="tab"></span><span class="tab"></span><span class="string">name</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="tab"></span><span class="string">}</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="string">}</span></span><br/>
        <span class="line"><span class="tab"></span><span class="string">}</span></span><br/>
        <span class="line"><span class="string">&#96;</span><span class="default">)</span></span><br/>
    </div>
</div>`

const connectDirectly = `<div class="editor__bash">
    <div class="editor__v-scroll">
        <div class="editor__h-scroll">
            <div class="editor__bash-cmd">
                <span class="cmd"><span class="workspace"><span>~/my-app</span><span>&nbsp;$&nbsp;</span></span><span>cat flexibility.txt</span></span>
                <span class="output"><span>Connect directly to your database using psql or your favorite ORM :)</span></span><br/>
                <span class="output"><span></span></span><br/>
            </div>
            <div class="editor__bash-cmd">
                <span class="cmd"><span class="workspace"><span>~/my-app</span><span>&nbsp;$&nbsp;</span></span><span>psql &lt;YOUR_SPEC_DATABASE_URL&gt;</span></span>
            </div>
        </div>
    </div>
</div>`

const subscribeToUpdates = `<div class="editor__v-scroll">
    <div class="editor__line-numbers">
        <span>1</span><br/>
        <span>2</span><br/>
        <span>3</span><br/>
        <span>4</span><br/>
        <span>5</span><br/>
        <span>6</span><br/>
    </div>
    <div class="editor__h-scroll">
        <span id="sub-indent1"></span>
        <span class="line"><span class="keyword">import</span><span class="default"> spec </span><span class="keyword">from </span><span class="string">'./initClient'</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="comment">// Listen for record updates in your aave_positions table.</span></span><br/>
        <span class="line"><span class="default">spec.</span><span class="function-call">from</span><span class="default">(</span><span class="string">'aave_positions'</span><span class="default">).</span><span class="function-call">onUpdate</span><span class="default">(event =&gt; {</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">console.</span><span class="function-call">log</span><span class="default">(event)</span></span><br/>
        <span class="line"><span class="default">})</span></span><br/>
    </div>
</div>`

const subscribeToInserts = `<div class="editor__v-scroll">
    <div class="editor__line-numbers">
        <span>1</span><br/>
        <span>2</span><br/>
        <span>3</span><br/>
        <span>4</span><br/>
        <span>5</span><br/>
        <span>6</span><br/>
    </div>
    <div class="editor__h-scroll">
        <span id="sub-indent1"></span>
        <span class="line"><span class="keyword">import</span><span class="default"> spec </span><span class="keyword">from </span><span class="string">'./initClient'</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="comment">// Listen for all new token_holding records.</span></span><br/>
        <span class="line"><span class="default">spec.</span><span class="function-call">from</span><span class="default">(</span><span class="string">'token_holdings'</span><span class="default">).</span><span class="function-call">onInsert</span><span class="default">(event =&gt; {</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">console.</span><span class="function-call">log</span><span class="default">(event)</span></span><br/>
        <span class="line"><span class="default">})</span></span><br/>
    </div>
</div>`

const subscribeToAll = `<div class="editor__v-scroll">
    <div class="editor__line-numbers">
        <span>1</span><br/>
        <span>2</span><br/>
        <span>3</span><br/>
        <span>4</span><br/>
        <span>5</span><br/>
        <span>6</span><br/>
    </div>
    <div class="editor__h-scroll">
        <span id="sub-indent1"></span>
        <span class="line"><span class="keyword">import</span><span class="default"> spec </span><span class="keyword">from </span><span class="string">'./initClient'</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="comment">// Listen for all changes across all tables.</span></span><br/>
        <span class="line"><span class="default">spec.</span><span class="function-call">from</span><span class="default">(</span><span class="string">'*'</span><span class="default">).</span><span class="function-call">on</span><span class="default">(</span><span class="string">'*'</span><span class="default">, event =&gt; {</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">console.</span><span class="function-call">log</span><span class="default">(event)</span></span><br/>
        <span class="line"><span class="default">})</span></span><br/>
    </div>
</div>`

const updateEventOutput = `<div class="output">
    <span class="line"><span class="default">{</span></span><br/>
    <span class="line"><span class="tab"></span><span class="default">type: </span><span class="string">'{{event}}'</span><span class="default">,</span></span><br/>
    <span class="line"><span class="tab"></span><span class="default">timestamp: </span><span class="string">'{{timestamp}}'</span><span class="default">,</span></span><br/>
    <span class="line"><span class="tab"></span><span class="default">schema: </span><span class="string">'public'</span><span class="default">,</span></span><br/>
    <span class="line"><span class="tab"></span><span class="default">table: </span><span class="string">'{{table}}'</span><span class="default">,</span></span><br/>
    <span class="line"><span class="tab"></span><span class="default">new: </span>{{new}}<span class="default">,</span></span><br/>
    <span class="line"><span class="tab"></span><span class="default">old: </span>{{old}}<span class="default">,</span></span><br/>
    <span class="line"><span class="default">}</span></span><br/>
</div>`

const insertEventOutput = `<div class="output">
    <span class="line"><span class="default">{</span></span><br/>
    <span class="line"><span class="tab"></span><span class="default">type: </span><span class="string">'{{event}}'</span><span class="default">,</span></span><br/>
    <span class="line"><span class="tab"></span><span class="default">timestamp: </span><span class="string">'{{timestamp}}'</span><span class="default">,</span></span><br/>
    <span class="line"><span class="tab"></span><span class="default">schema: </span><span class="string">'public'</span><span class="default">,</span></span><br/>
    <span class="line"><span class="tab"></span><span class="default">table: </span><span class="string">'{{table}}'</span><span class="default">,</span></span><br/>
    <span class="line"><span class="tab"></span><span class="default">new: </span>{{new}}<span class="default">,</span></span><br/>
    <span class="line"><span class="default">}</span></span><br/>
</div>`

const deleteEventOutput = `<div class="output">
    <span class="line"><span class="default">{</span></span><br/>
    <span class="line"><span class="tab"></span><span class="default">type: </span><span class="string">'{{event}}'</span><span class="default">,</span></span><br/>
    <span class="line"><span class="tab"></span><span class="default">timestamp: </span><span class="string">'{{timestamp}}'</span><span class="default">,</span></span><br/>
    <span class="line"><span class="tab"></span><span class="default">schema: </span><span class="string">'public'</span><span class="default">,</span></span><br/>
    <span class="line"><span class="tab"></span><span class="default">table: </span><span class="string">'{{table}}'</span><span class="default">,</span></span><br/>
    <span class="line"><span class="tab"></span><span class="default">old: </span>{{old}}<span class="default">,</span></span><br/>
    <span class="line"><span class="default">}</span></span><br/>
</div>`

const aavePosition = `<span class="default">{ ... }</span>`

const alloProfile = `<div class="editor__v-scroll">
    <div class="editor__line-numbers">
        <span>1</span><br/>
        <span>2</span><br/>
        <span>3</span><br/>
        <span>4</span><br/>
        <span>5</span><br/>
        <span>6</span><br/>
        <span>7</span><br/>
        <span>8</span><br/>
        <span>9</span><br/>
        <span>10</span><br/>
        <span>11</span><br/>
        <span>12</span><br/>
        <span>13</span><br/>
        <span>14</span><br/>
        <span>15</span><br/>
        <span>16</span><br/>
        <span>17</span><br/>
        <span>18</span><br/>
        <span>19</span><br/>
        <span>20</span><br/>
        <span>21</span><br/>
        <span>22</span><br/>
        <span>23</span><br/>
        <span>24</span><br/>
        <span>25</span><br/>
        <span>26</span><br/>
        <span>27</span><br/>
        <span>28</span><br/>
        <span>29</span><br/>
        <span>30</span><br/>
        <span>31</span><br/>
        <span>32</span><br/>
        <span>33</span><br/>
    </div>
    <div class="editor__h-scroll">
        <span id="alloProfile-indent1"></span>
        <span id="alloProfile-indent2"></span>
        <span id="alloProfile-indent3"></span>
        <span id="alloProfile-indent4"></span>
        <span id="alloProfile-indent5"></span>
        <span id="alloProfile-indent6"></span>
        <span class="line"><span class="decorator">@Spec</span><span class="default">({</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">uniqueBy: [</span><span class="string">'profileId'</span><span class="default">, </span><span class="string">'chainId'</span><span class="default">]</span></span><br/>
        <span class="line"><span class="default">})</span></span><br/>
        <span class="line"><span class="keyword">class </span><span class="type">Profile </span><span class="keyword">extends </span><span class="type">LiveTable </span><span class="default">{</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@Property</span><span class="default">()</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">profileId: </span><span class="type">Address</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@Property</span><span class="default">()</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">name: </span><span class="type">string</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@Property</span><span class="default">()</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">metadata: </span><span class="type">JSON</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@Property</span><span class="default">()</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">createdAt: </span><span class="type">Timestamp</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="comment">// ==== Event Handlers =================</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@OnEvent</span><span class="default">(</span><span class="string">'allov2.Registry.ProfileCreated'</span><span class="default">)</span></span><br/>
        <span class="line"><span class="tab"></span><span class="keyword">async </span><span class="function-call">createProject</span><span class="default">(</span><span class="default">event: </span><span class="type">Event</span><span class="default">) {</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">this</span><span class="default">.profileId = event.data.profileId</span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">this</span><span class="default">.</span>name = event.data.name</span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">this</span><span class="default">.</span>createdAt = </span><span class="keyword">this</span>.blockTimestamp<br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">this</span><span class="default">.metadata = </span><span class="keyword">await </span><span class="function-call">resolveMetadata</span><span class="default">(event.data.metadata)</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">}</s><pan></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@OnEvent</span><span class="default">(</span><span class="string">'allov2.Registry.ProfileNameUpdated'</span><span class="default">)</span></span><br/>
        <span class="line"><span class="tab"></span><span class="function-call">updateName</span><span class="default">(</span><span class="default">event: </span><span class="type">Event</span><span class="default">) {</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">this</span><span class="default">.profileId = event.data.profileId</span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">this</span><span class="default">.</span>name = event.data.name</span><br/>
        <span class="line"><span class="tab"></span><span class="default">}</span></span><br/>
        <span class="line"><span class="default">}</span></span><br/>
    </div>
</div>`

const alloProfileManifest = `<div class="editor__v-scroll">
    <div class="editor__line-numbers">
        <span>1</span><br/>
        <span>2</span><br/>
        <span>3</span><br/>
        <span>4</span><br/>
        <span>5</span><br/>
        <span>6</span><br/>
        <span>7</span><br/>
        <span>8</span><br/>
        <span>9</span><br/>
        <span>10</span><br/>
        <span>11</span><br/>
        <span>12</span><br/>
        <span>13</span><br/>
        <span>14</span><br/>
        <span>15</span><br/>
        <span>16</span><br/>
        <span>17</span><br/>
    </div>
    <div class="editor__h-scroll">
        <span id="alloProfileManifest-indent1"></span>
        <span id="alloProfileManifest-indent2"></span>
        <span class="line"><span class="default">{</span></span><br/>
        <span class="line"><span class="tab"></span><span class="string">"namespace"</span><span class="default">: </span><span class="string">"allov2"</span></span><br/>
        <span class="line"><span class="tab"></span><span class="string">"name"</span><span class="default">: </span><span class="string">"Profile"</span></span><br/>
        <span class="line"><span class="tab"></span><span class="string">"version"</span><span class="default">: </span><span class="string">"0.0.1"</span></span><br/>
        <span class="line"><span class="tab"></span><span class="string">"displayName"</span><span class="default">: </span><span class="string">"Allo Profiles"</span></span><br/>
        <span class="line"><span class="tab"></span><span class="string">"description"</span><span class="default">: </span><span class="string">"Profiles on Allo V2."</span></span><br/>
        <span class="line"><span class="tab"></span><span class="string">"chains"</span><span class="default">: [</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="number">1</span><span class="default">,</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="number">5</span><span class="default">,</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="number">10</span><span class="default">,</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="number">137</span><span class="default">,</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="number">424</span><span class="default">,</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="number">8453</span><span class="default">,</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="number">42161</span><span class="default">,</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="number">42220</span><span class="default"></span></span><br/>
        <span class="line"><span class="tab"></span>]</span></span><br/>
        <span class="line"><span class="default">}</span></span><br/>
    </div>
</div>`

const splitContract = `<div class="editor__v-scroll">
    <div class="editor__line-numbers">
        <span>1</span><br/>
        <span>2</span><br/>
        <span>3</span><br/>
        <span>4</span><br/>
        <span>5</span><br/>
        <span>6</span><br/>
        <span>7</span><br/>
        <span>8</span><br/>
        <span>9</span><br/>
        <span>10</span><br/>
        <span>11</span><br/>
        <span>12</span><br/>
        <span>13</span><br/>
        <span>14</span><br/>
        <span>15</span><br/>
        <span>16</span><br/>
        <span>17</span><br/>
        <span>18</span><br/>
        <span>19</span><br/>
        <span>20</span><br/>
        <span>21</span><br/>
        <span>22</span><br/>
        <span>23</span><br/>
        <span>24</span><br/>
        <span>25</span><br/>
        <span>26</span><br/>
        <span>27</span><br/>
        <span>28</span><br/>
        <span>29</span><br/>
        <span>30</span><br/>
        <span>31</span><br/>
        <span>32</span><br/>
        <span>33</span><br/>
        <span>34</span><br/>
        <span>35</span><br/>
        <span>36</span><br/>
        <span>37</span><br/>
        <span>38</span><br/>
    </div>
    <div class="editor__h-scroll">
        <span id="splitContract-indent1"></span>
        <span id="splitContract-indent2"></span>
        <span id="splitContract-indent3"></span>
        <span id="splitContract-indent4"></span>
        <span class="line"><span class="decorator">@Spec</span><span class="default">({</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">uniqueBy: [</span><span class="string">'address'</span><span class="default">, </span><span class="string">'chainId'</span><span class="default">]</span></span><br/>
        <span class="line"><span class="default">})</span></span><br/>
        <span class="line"><span class="keyword">class </span><span class="type">Split </span><span class="keyword">extends </span><span class="type">LiveTable </span><span class="default">{</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@Property</span><span class="default">()</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">address: </span><span class="type">Address</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@Property</span><span class="default">()</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">distributorFee: </span><span class="type">BigInt</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@Property</span><span class="default">()</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">controller: </span><span class="type">Address</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@Property</span><span class="default">({ default: ZERO_ADDRESS })</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">newPotentialController: </span><span class="type">Address</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@Property</span><span class="default">()</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">createdAt: </span><span class="type">Timestamp</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="comment">// ==== Call Handlers ====================</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@OnCall</span><span class="default">(</span><span class="string">'splits.SplitMain.createSplit'</span><span class="default">)</span></span><br/>
        <span class="line"><span class="tab"></span><span class="function-call">createSplit</span><span class="default">(</span><span class="default">call: </span><span class="type">Call</span><span class="default">) {</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">this</span><span class="default">.address = call.outputs.split</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">this</span><span class="default">.distributorFee = BigInt.</span><span class="function-call">from</span><span class="default">(call.inputs.distributorFee)</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">this</span><span class="default">.controller = call.inputs.controller</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">this</span><span class="default">.</span>createdAt = call.origin.blockTimestamp</span><br/>
        <span class="line"><span class="tab"></span><span class="default">}</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@OnCall</span><span class="default">(</span><span class="string">'splits.SplitMain.updateSplit'</span><span class="default">)</span></span><br/>
        <span class="line"><span class="tab"></span><span class="function-call">updateSplit</span><span class="default">(</span><span class="default">call: </span><span class="type">Call</span><span class="default">) {</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">this</span><span class="default">.address = call.outputs.split</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">this</span><span class="default">.distributorFee = BigInt.</span><span class="function-call">from</span><span class="default">(call.inputs.distributorFee)</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">}</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="comment">// ...</span></span><br/>
        <span class="line"><span class="default">}</span></span><br/>
    </div>
</div>`

const splitContractManifest = `<div class="editor__v-scroll">
    <div class="editor__line-numbers">
        <span>1</span><br/>
        <span>2</span><br/>
        <span>3</span><br/>
        <span>4</span><br/>
        <span>5</span><br/>
        <span>6</span><br/>
        <span>7</span><br/>
        <span>8</span><br/>
    </div>
    <div class="editor__h-scroll">
        <span id="splitContractManifest-indent1"></span>
        <span class="line"><span class="default">{</span></span><br/>
        <span class="line"><span class="tab"></span><span class="string">"namespace"</span><span class="default">: </span><span class="string">"splits"</span></span><br/>
        <span class="line"><span class="tab"></span><span class="string">"name"</span><span class="default">: </span><span class="string">"Split"</span></span><br/>
        <span class="line"><span class="tab"></span><span class="string">"version"</span><span class="default">: </span><span class="string">"0.0.1"</span></span><br/>
        <span class="line"><span class="tab"></span><span class="string">"displayName"</span><span class="default">: </span><span class="string">"Split Contracts"</span></span><br/>
        <span class="line"><span class="tab"></span><span class="string">"description"</span><span class="default">: </span><span class="string">"A split contract on the 0xSplits protocol."</span></span><br/>
        <span class="line"><span class="tab"></span><span class="string">"chains"</span><span class="default">: [</span><span class="number">1</span><span class="default">, </span><span class="number">5</span><span class="default">, </span><span class="number">137</span><span class="default">]</span></span><br/>
        <span class="line"><span class="default">}</span></span><br/>
    </div>
</div>`

const uniswapPool = () => window.innerWidth <= 1180 ? `<div class="editor__v-scroll">
    <div class="editor__line-numbers">
        <span>1</span><br/>
        <span>2</span><br/>
        <span>3</span><br/>
        <span>4</span><br/>
        <span>5</span><br/>
        <span>6</span><br/>
        <span>7</span><br/>
        <span>8</span><br/>
        <span>9</span><br/>
        <span>10</span><br/>
        <span>11</span><br/>
        <span>12</span><br/>
        <span>13</span><br/>
        <span>14</span><br/>
        <span>15</span><br/>
        <span>16</span><br/>
        <span>17</span><br/>
        <span>18</span><br/>
        <span>19</span><br/>
        <span>20</span><br/>
        <span>21</span><br/>
        <span>22</span><br/>
        <span>23</span><br/>
        <span>24</span><br/>
        <span>25</span><br/>
        <span>26</span><br/>
        <span>27</span><br/>
        <span>28</span><br/>
        <span>29</span><br/>
        <span>30</span><br/>
        <span>31</span><br/>
        <span>32</span><br/>
        <span>33</span><br/>
        <span>34</span><br/>
        <span>35</span><br/>
        <span>36</span><br/>
        <span>37</span><br/>
        <span>38</span><br/>
        <span>39</span><br/>
        <span>40</span><br/>
        <span>41</span><br/>
        <span>42</span><br/>
        <span>43</span><br/>
    </div>
    <div class="editor__h-scroll">
        <span id="uniswapPool-indent1"></span>
        <span id="uniswapPool-indent2"></span>
        <span id="uniswapPool-indent3"></span>
        <span id="uniswapPool-indent4"></span>
        <span class="line"><span class="decorator">@Spec</span><span class="default">({</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">uniqueBy: [</span><span class="string">'address'</span><span class="default">, </span><span class="string">'chainId'</span><span class="default">]</span></span><br/>
        <span class="line"><span class="default">})</span></span><br/>
        <span class="line"><span class="keyword">class </span><span class="type">Pool </span><span class="keyword">extends </span><span class="type">LiveTable </span><span class="default">{</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@Property</span><span class="default">()</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">address: </span><span class="type">Address</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@Property</span><span class="default">()</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">token0: </span><span class="type">Address</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@Property</span><span class="default">()</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">token1: </span><span class="type">Address</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@Property</span><span class="default">()</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">fee: </span><span class="type">BigInt</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@Property</span><span class="default">()</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">sqrtPrice: </span><span class="type">BigInt</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@Property</span><span class="default">()</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">tick: </span><span class="type">BigInt</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="comment">// ==== Event Handlers =================</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@OnEvent</span><span class="default">(</span><span class="string">'uniswapv3.Factory.PoolCreated'</span><span class="default">)</span></span><br/>
        <span class="line"><span class="tab"></span><span class="function-call">createPool</span><span class="default">(</span><span class="default">event: </span><span class="type">Event</span><span class="default">) {</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">this</span><span class="default">.address = event.data.pool</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">this</span><span class="default">.token0 = event.data.token0</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">this</span><span class="default">.token1 = event.data.token1</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">this</span><span class="default">.fee = BigInt.</span><span class="function-call">from</span><span class="default">(event.data.fee)</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="comment">// Factory support made easy.</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">this</span><span class="default">.</span><span class="function-call">addContractToGroup</span><span class="default">(</span><span class="keyword">this</span><span class="default">.address, </span><span class="string">'uniswapv3.Pool'</span><span class="default">)</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">}</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@OnEvent</span><span class="default">(</span><span class="string">'uniswapv3.Pool.Initialize'</span><span class="default">)</span></span><br/>
        <span class="line"><span class="tab"></span><span class="function-call">initializePool</span><span class="default">(</span><span class="default">event: </span><span class="type">Event</span><span class="default">) {</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">this</span><span class="default">.address = event.origin.contractAddress</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">this</span><span class="default">.sqrtPrice = BigInt.</span><span class="function-call">from</span><span class="default">(event.data.sqrtPriceX96)</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">this</span><span class="default">.tick = BigInt.</span><span class="function-call">from</span><span class="default">(event.data.tick)</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">}</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="comment">// ...</span></span><br/>
        <span class="line"><span class="default">}</span></span><br/>
    </div>
</div>` :
`<div class="editor__v-scroll">
    <div class="editor__line-numbers">
        <span>1</span><br/>
        <span>2</span><br/>
        <span>3</span><br/>
        <span>4</span><br/>
        <span>5</span><br/>
        <span>6</span><br/>
        <span>7</span><br/>
        <span>8</span><br/>
        <span>9</span><br/>
        <span>10</span><br/>
        <span>11</span><br/>
        <span>12</span><br/>
        <span>13</span><br/>
        <span>14</span><br/>
        <span>15</span><br/>
        <span>16</span><br/>
        <span>17</span><br/>
        <span>18</span><br/>
        <span>19</span><br/>
        <span>20</span><br/>
        <span>21</span><br/>
        <span>22</span><br/>
        <span>23</span><br/>
        <span>24</span><br/>
        <span>25</span><br/>
        <span>26</span><br/>
        <span>27</span><br/>
        <span>28</span><br/>
        <span>29</span><br/>
        <span>30</span><br/>
        <span>31</span><br/>
        <span>32</span><br/>
        <span>33</span><br/>
        <span>34</span><br/>
        <span>35</span><br/>
        <span>36</span><br/>
        <span>37</span><br/>
        <span>38</span><br/>
        <span>39</span><br/>
        <span>40</span><br/>
        <span>41</span><br/>
        <span>42</span><br/>
        <span>43</span><br/>
    </div>
    <div class="editor__h-scroll">
        <span id="uniswapPool-indent1"></span>
        <span id="uniswapPool-indent2"></span>
        <span id="uniswapPool-indent3"></span>
        <span id="uniswapPool-indent4"></span>
        <span class="line"><span class="decorator">@Spec</span><span class="default">({</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">uniqueBy: [</span><span class="string">'address'</span><span class="default">, </span><span class="string">'chainId'</span><span class="default">]</span></span><br/>
        <span class="line"><span class="default">})</span></span><br/>
        <span class="line"><span class="keyword">class </span><span class="type">Pool </span><span class="keyword">extends </span><span class="type">LiveTable </span><span class="default">{</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@Property</span><span class="default">()</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">address: </span><span class="type">Address</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@Property</span><span class="default">()</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">token0: </span><span class="type">Address</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@Property</span><span class="default">()</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">token1: </span><span class="type">Address</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@Property</span><span class="default">()</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">fee: </span><span class="type">BigInt</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@Property</span><span class="default">()</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">sqrtPrice: </span><span class="type">BigInt</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@Property</span><span class="default">()</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">tick: </span><span class="type">BigInt</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="comment">// ==== Event Handlers =================</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@OnEvent</span><span class="default">(</span><span class="string">'uniswapv3.Factory.PoolCreated'</span><span class="default">)</span></span><br/>
        <span class="line"><span class="tab"></span><span class="function-call">createPool</span><span class="default">(</span><span class="default">event: </span><span class="type">Event</span><span class="default">) {</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">this</span><span class="default">.address = event.data.pool</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">this</span><span class="default">.token0 = event.data.token0</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">this</span><span class="default">.token1 = event.data.token1</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">this</span><span class="default">.fee = BigInt.</span><span class="function-call">from</span><span class="default">(event.data.fee)</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">this</span><span class="default">.</span><span class="function-call">addContractToGroup</span><span class="default">(</span><span class="keyword">this</span><span class="default">.address, </span><span class="string">'uniswapv3.Pool'</span><span class="default">)</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">}</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@OnEvent</span><span class="default">(</span><span class="string">'uniswapv3.Pool.Initialize'</span><span class="default">)</span></span><br/>
        <span class="line"><span class="tab"></span><span class="function-call">initializePool</span><span class="default">(</span><span class="default">event: </span><span class="type">Event</span><span class="default">) {</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">this</span><span class="default">.address = event.origin.contractAddress</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">this</span><span class="default">.sqrtPrice = BigInt.</span><span class="function-call">from</span><span class="default">(event.data.sqrtPriceX96)</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">this</span><span class="default">.tick = BigInt.</span><span class="function-call">from</span><span class="default">(event.data.tick)</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">}</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="comment">// ...</span></span><br/>
        <span class="line"><span class="default">}</span></span><br/>
    </div>
</div>`


const uniswapPoolManifest = `<div class="editor__v-scroll">
    <div class="editor__line-numbers">
        <span>1</span><br/>
        <span>2</span><br/>
        <span>3</span><br/>
        <span>4</span><br/>
        <span>5</span><br/>
        <span>6</span><br/>
        <span>7</span><br/>
        <span>8</span><br/>
        <span>9</span><br/>
        <span>10</span><br/>
        <span>11</span><br/>
        <span>12</span><br/>
        <span>13</span><br/>
        <span>14</span><br/>
        <span>15</span><br/>
        <span>16</span><br/>
    </div>
    <div class="editor__h-scroll">
        <span id="uniswapPoolManifest-indent1"></span>
        <span id="uniswapPoolManifest-indent2"></span>
        <span class="line"><span class="default">{</span></span><br/>
        <span class="line"><span class="tab"></span><span class="string">"namespace"</span><span class="default">: </span><span class="string">"uniswapv3"</span></span><br/>
        <span class="line"><span class="tab"></span><span class="string">"name"</span><span class="default">: </span><span class="string">"Pool"</span></span><br/>
        <span class="line"><span class="tab"></span><span class="string">"version"</span><span class="default">: </span><span class="string">"0.0.1"</span></span><br/>
        <span class="line"><span class="tab"></span><span class="string">"displayName"</span><span class="default">: </span><span class="string">"Uniswap Pools"</span></span><br/>
        <span class="line"><span class="tab"></span><span class="string">"description"</span><span class="default">: </span><span class="string">"Pools on Uniswap V3."</span></span><br/>
        <span class="line"><span class="tab"></span><span class="string">"chains"</span><span class="default">: [</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="number">1</span><span class="default">,</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="number">5</span><span class="default">,</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="number">10</span><span class="default">,</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="number">137</span><span class="default">,</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="number">8453</span><span class="default">,</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="number">42161</span><span class="default">,</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="number">42220</span><span class="default"></span></span><br/>
        <span class="line"><span class="tab"></span>]</span></span><br/>
        <span class="line"><span class="default">}</span></span><br/>
    </div>
</div>`

const synthetixPosition = `<div class="editor__v-scroll">
    <div class="editor__line-numbers">
        <span>1</span><br/>
        <span>2</span><br/>
        <span>3</span><br/>
        <span>4</span><br/>
        <span>5</span><br/>
        <span>6</span><br/>
        <span>7</span><br/>
        <span>8</span><br/>
        <span>9</span><br/>
        <span>10</span><br/>
        <span>11</span><br/>
        <span>12</span><br/>
        <span>13</span><br/>
        <span>14</span><br/>
        <span>15</span><br/>
        <span>16</span><br/>
        <span>17</span><br/>
        <span>18</span><br/>
        <span>19</span><br/>
        <span>20</span><br/>
        <span>21</span><br/>
        <span>22</span><br/>
        <span>23</span><br/>
        <span>24</span><br/>
        <span>25</span><br/>
        <span>26</span><br/>
        <span>27</span><br/>
        <span>28</span><br/>
        <span>29</span><br/>
        <span>30</span><br/>
        <span>31</span><br/>
        <span>32</span><br/>
        <span>33</span><br/>
        <span>34</span><br/>
        <span>35</span><br/>
        <span>36</span><br/>
        <span>37</span><br/>
        <span>38</span><br/>
        <span>39</span><br/>
        <span>40</span><br/>
        <span>41</span><br/>
        <span>42</span><br/>
        <span>43</span><br/>
        <span>44</span><br/>
        <span>45</span><br/>
        <span>46</span><br/>
        <span>47</span><br/>
        <span>48</span><br/>
        <span>49</span><br/>
        <span>50</span><br/>
    </div>
    <div class="editor__h-scroll">
        <span id="synthetixPosition-indent1"></span>
        <span id="synthetixPosition-indent2"></span>
        <span id="synthetixPosition-indent3"></span>
        <span id="synthetixPosition-indent4"></span>
        <span id="synthetixPosition-indent5"></span>
        <span id="synthetixPosition-indent6"></span>
        <span class="line"><span class="decorator">@Spec</span><span class="default">({</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">uniqueBy: [</span><span class="string">'accountId'</span><span class="default">, <span class="string">'poolId'</span>, <span class="string">'collateralType'</span>, </span><span class="string">'chainId'</span><span class="default">]</span></span><br/>
        <span class="line"><span class="default">})</span></span><br/>
        <span class="line"><span class="keyword">class </span><span class="type">Position </span><span class="keyword">extends </span><span class="type">LiveTable </span><span class="default">{</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@Property</span><span class="default">()</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">accountId: </span><span class="type">BigInt</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@Property</span><span class="default">()</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">poolId: </span><span class="type">BigInt</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@Property</span><span class="default">()</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">collateralType: </span><span class="type">Address</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@Property</span><span class="default">()</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">collateralAmount: </span><span class="type">BigFloat</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@Property</span><span class="default">()</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">leverage: </span><span class="type">BigFloat</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@Property</span><span class="default">()</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">netIssuance: </span><span class="type">BigFloat</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="comment">// ==== Event Handlers ============</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@BeforeAll</span><span class="default">()</span></span><br/>
        <span class="line"><span class="tab"></span><span class="function-call">setCommonProperties</span><span class="default">(</span><span class="default">event: </span><span class="type">Event</span><span class="default">) {</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">this</span><span class="default">.accountId = BigInt.</span><span class="function-call">from</span><span class="default">(event.data.accountId)</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">this</span><span class="default">.poolId = BigInt.</span><span class="function-call">from</span><span class="default">(event.data.poolId)</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">this</span><span class="default">.collateralType = event.data.collateralType</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">}</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@OnEvent</span><span class="default">(</span><span class="string">'synthetixv3.Core.DelegationUpdated'</span><span class="default">)</span></span><br/>
        <span class="line"><span class="tab"></span><span class="function-call">createPool</span><span class="default">(</span><span class="default">event: </span><span class="type">Event</span><span class="default">) {</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">this</span><span class="default">.collateralAmount = BigFloat.</span><span class="function-call">from</span><span class="default">(event.data.amount)</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">this</span><span class="default">.leverage = BigFloat.</span><span class="function-call">from</span><span class="default">(event.data.leverage)</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">}</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@OnEvent</span><span class="default">(</span><span class="string">'synthetixv3.Core.UsdMinted'</span><span class="default">)</span></span><br/>
        <span class="line"><span class="tab"></span><span class="keyword">async </span><span class="function-call">increment</span><span class="default">(</span><span class="default">event: </span><span class="type">Event</span><span class="default">) {</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">await this</span>.<span class="function-call">load</span>()</span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">this</span><span class="default">.netIssuance = </span><span class="keyword">this</span>.netIssuance.<span class="function-call">plus</span><span class="default">(event.data.amount)</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">}</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@OnEvent</span><span class="default">(</span><span class="string">'synthetixv3.Core.UsdBurned'</span><span class="default">)</span></span><br/>
        <span class="line"><span class="tab"></span><span class="keyword">async </span><span class="function-call">decrement</span><span class="default">(</span><span class="default">event: </span><span class="type">Event</span><span class="default">) {</span></span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">await this</span>.<span class="function-call">load</span>()</span><br/>
        <span class="line"><span class="tab"></span><span class="tab"></span><span class="keyword">this</span><span class="default">.netIssuance = </span><span class="keyword">this</span>.netIssuance.<span class="function-call">minus</span><span class="default">(event.data.amount)</span></span><br/>
        <span class="line"><span class="tab"></span><span class="default">}</span></span><br/>
        <span class="line"><span class="default">}</span></span><br/>
    </div>
</div>`

const synthetixPositionManifest = `<div class="editor__v-scroll">
    <div class="editor__line-numbers">
        <span>1</span><br/>
        <span>2</span><br/>
        <span>3</span><br/>
        <span>4</span><br/>
        <span>5</span><br/>
        <span>6</span><br/>
        <span>7</span><br/>
        <span>8</span><br/>
    </div>
    <div class="editor__h-scroll">
        <span id="synthetixPositionManifest-indent1"></span>
        <span class="line"><span class="default">{</span></span><br/>
        <span class="line"><span class="tab"></span><span class="string">"namespace"</span><span class="default">: </span><span class="string">"synthetixv3"</span></span><br/>
        <span class="line"><span class="tab"></span><span class="string">"name"</span><span class="default">: </span><span class="string">"Position"</span></span><br/>
        <span class="line"><span class="tab"></span><span class="string">"version"</span><span class="default">: </span><span class="string">"0.0.1"</span></span><br/>
        <span class="line"><span class="tab"></span><span class="string">"displayName"</span><span class="default">: </span><span class="string">"Synthetix Positions"</span></span><br/>
        <span class="line"><span class="tab"></span><span class="string">"description"</span><span class="default">: </span><span class="string">"Positions on Synthetix V3."</span></span><br/>
        <span class="line"><span class="tab"></span><span class="string">"chains"</span><span class="default">: [</span><span class="number">1</span><span class="default">, </span><span class="number">5</span><span class="default">, </span><span class="number">10</span><span class="default">]</span></span><br/>
        <span class="line"><span class="default">}</span></span><br/>
    </div>
</div>`

const eventDecoratorExamples = `<div class="editor__v-scroll">
    <div class="editor__h-scroll">
        <span class="line"><span class="tab"></span><span class="comment">// Multi-chain</span></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@OnEvent</span><span class="default">(</span><span class="string">'uniswapv3.Pool.Mint'</span><span class="default">)</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="comment">// Chain-specific</span></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@OnEvent</span><span class="default">(</span><span class="string">'polygon.contracts.uniswapv3.Pool.Mint'</span><span class="default">)</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="tab"></span><span class="comment">// Topic-specific</span></span><br/>
        <span class="line"><span class="tab"></span><span class="decorator">@OnEvent</span><span class="default">(</span><span class="string">'uniswapv3.Pool.Mint'</span><span class="default">, { signature: </span><span class="string">'0x...' </span><span class="default">})</span></span><br/>
    </div>
</div>`

const eventSubscriptionExamples = `<div class="editor__v-scroll">
    <div class="editor__line-numbers">
        <span>1</span><br/>
        <span>2</span><br/>
        <span>3</span><br/>
        <span>4</span><br/>
        <span>5</span><br/>
        <span>6</span><br/>
        <span>7</span><br/>
        <span>8</span><br/>
        <span>9</span><br/>
        <span>10</span><br/>
        <span>11</span><br/>
        <span>12</span><br/>
        <span>13</span><br/>
    </div>
    <div class="editor__h-scroll">
        <span class="line"><span class="keyword">import</span><span class="default"> { createEventClient } </span><span class="keyword">from </span><span class="string">'@spec.dev/event-client'</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="comment">// Tap into any event in the ecosystem by name.</span></span><br/>
        <span class="line"><span class="keyword">const</span><span class="default"> spec = </span><span class="function-call">createEventClient</span><span class="default">()</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="comment">// Multi-chain</span></span><br/>
        <span class="line"><span class="default">spec.</span><span class="function-call">on</span><span class="default">(</span><span class="string">'uniswapv3.Pool.Mint'</span><span class="default">, event => { </span><span class="comment">... </span><span class="default">})</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="comment">// Chain-specific</span></span><br/>
        <span class="line"><span class="default">spec.</span><span class="function-call">on</span><span class="default">(</span><span class="string">'polygon.contracts.uniswapv3.Pool.Mint'</span><span class="default">, event => { </span><span class="comment">... </span><span class="default">})</span></span><br/>
        <span class="line"></span><br/>
        <span class="line"><span class="comment">// Topic-specific</span></span><br/>
        <span class="line"><span class="default">spec.</span><span class="function-call">on</span><span class="default">(</span><span class="string">'polygon.contracts.uniswapv3.Pool.Mint@0x...'</span><span class="default">, event => { </span><span class="comment">... </span><span class="default">})</span></span><br/>
    </div>
</div>`

export default {
    restApi,
    graphQLApi,
    connectDirectly,
    subscribeToUpdates,
    subscribeToInserts,
    subscribeToAll,
    updateEventOutput,
    insertEventOutput,
    deleteEventOutput,
    aavePosition,
    alloProfile,
    alloProfileManifest,
    splitContract,
    splitContractManifest,
    uniswapPool,
    uniswapPoolManifest,
    synthetixPosition,
    synthetixPositionManifest,
    eventDecoratorExamples,
    eventSubscriptionExamples,
}
