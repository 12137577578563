import { useCallback } from 'react'
import { getPCN, cn } from '../../utils/classes'
import { ContractGroupResource, resourceNames, resources } from './ContractGroupResource'
import { useHistory, useLocation } from 'react-router-dom'

const className = 'contract-group-resources'
const pcn = getPCN(className)

const categorySliderClassName = 'category-slider'
const csPcn = getPCN(categorySliderClassName)

const sliderStyle = {
    gutter: 18,
    minWidth: 65,
}

export const ContractGroupResources = ({ nsp, contractGroup, isLoading }) => {
    const filter = useLocation().hash.slice(1) || resourceNames.CONTRACTS
    const history = useHistory()

    const renderCategories = useCallback(() => {
        let sliderLeft = 0
        let sliderWidth = sliderStyle.minWidth
        for (const resource of resources) {
            if (resource.name === filter) {
                sliderWidth = resource.width
                break
            }
            sliderLeft += (sliderStyle.gutter + resource.width)
        }
    
        return (
            <div className={cn(categorySliderClassName, `${categorySliderClassName}--${filter}`)}>
                <div
                    className={csPcn('__slider')}
                    style={{ width: sliderWidth, left: sliderLeft }}>
                </div>
                { resources.map(resource => (
                    <div
                        key={resource.name}
                        className={csPcn(
                            '__category',
                            resource.name === filter ? '__category--selected' : '',
                        )}
                        onClick={() => {
                            window.skipJumpToTop = true
                            history.push(resource.link(nsp, contractGroup.name, resource.name))
                        }}>
                        <span>{resource.displayName}</span>
                    </div>
                ))}
            </div>
        )
    }, [filter, contractGroup])

    return <div className={className}>
        <div className={pcn('__filters', `__filters--${filter}`)}>
            { renderCategories() }
        </div>
        <div className={pcn('__content', `__content--${filter}`)}>
            <ContractGroupResource
                nsp={nsp}
                filter={filter}
                contractGroup={contractGroup}
                isLoading={isLoading}
            />
        </div>
    </div>
}