import React, { useCallback, useEffect, useRef } from 'react'
import { getPCN } from '../../utils/classes'
import { Blurhash } from 'react-blurhash'
import PH from './PH'

const className = 'image'
const pcn = getPCN(className)

function ImageComp({ phId, src, blurhash, withBgBlur, height, width }) {
    const frontImageRef = useRef()
    const backImageRef = useRef()
    const style = { height, width }

    const downloadImageData = useCallback(async () => {
        const downloadingImage = new Image()
        downloadingImage.onload = () => {
            if (frontImageRef.current) {
                frontImageRef.current.src = downloadingImage.src
            }
            if (backImageRef.current) {
                backImageRef.current.src = downloadingImage.src
            }
        }
        downloadingImage.src = src
    }, [src])

    useEffect(() => {
        downloadImageData()
    }, [src])

    return (
        <div className={className} style={style}>
            { !src && <PH id={phId} /> }
            { blurhash && (
                <Blurhash
                    hash={blurhash}
                    width={width}
                    height={height}
                    resolutionX={32}
                    resolutionY={32}
                    punch={1}
                />
            )} 
            { src && withBgBlur && <img style={style} className={pcn('__back')} ref={backImageRef} /> }
            { src && <img style={style} className={pcn('__front')} ref={frontImageRef} /> }
        </div>
    )
}

export default ImageComp