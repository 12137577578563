
import React, { useState, useMemo, useCallback, useReducer } from 'react'
import { getPCN } from '../../../utils/classes';
import { noop } from '../../../utils/nodash'
import ex from '../../../data/examples'
import checkIcon from '../../../svgs/svgjs/bens-check'

const className = 'client'
const pcn = getPCN(className)
const editorClassName = 'editor'
const epcn = getPCN(editorClassName)

const examples = [
    {
        title: 'REST API',
        code: ex.restApi,
    },
    {
        title: 'GraphQL API',
        code: ex.graphQLApi,
    },
    {
        title: 'Connect Directly',
        code: ex.connectDirectly,
    },
]

const features = [
    'Instant REST or GraphQL API',
    'Client and server-side querying',
    'Full CRUD functionality',
    'Postgres row-level security',
]

function QuerySection() {
    const [exampleIndex, setExampleIndex] = useState(0)
    const currentExample = useMemo(() => examples[exampleIndex] || {}, [exampleIndex, examples])

    const renderSelectExamplesBar = useCallback(() => (
        <div className={epcn(
            '__examples-titles', 
            `__examples-titles--index-${exampleIndex}`,
            `__examples-titles--${currentExample.title?.toLowerCase()?.replaceAll(' ', '-')}`,
        )}>
            <div className={epcn('__selected-example-slider')}></div>
            { examples.map((example, i) => (
                <div
                    key={i}
                    className={epcn(
                        '__examples-title', 
                        i === exampleIndex ? '__examples-title--selected' : '',
                    )}
                    onClick={i === exampleIndex ? noop : () => setExampleIndex(i) }>
                    <span>{example.title}</span>
                </div>
            ))}
        </div>
    ), [examples, exampleIndex, currentExample])

    const renderFeature = useCallback((feature, i) => (
        <div className={pcn('__feature')} key={i}>
            <span dangerouslySetInnerHTML={{ __html: checkIcon }}></span>
            <span>{feature}</span>
        </div>
    ), [])

    return (
        <div className={pcn('__section', '__section--query')}>
            <div className={pcn('__liner')}>
                <div className={pcn('__text-container')}>
                    <div className={pcn('__text-container-liner')}>
                        <div className={pcn('__name')}>
                            Instant APIs
                        </div>
                        <div className={pcn('__title')}>
                            Use your tables to power any application
                        </div>
                        <div className={pcn('__subtitle')}>
                            Spec introspects your database and auto-generates your API 
                            of choice &mdash; REST or GraphQL &mdash; so that you can instantly use 
                            your tables to power any type of application.
                        </div>
                    </div>
                    <div className={pcn('__features')}>
                        { features.map(renderFeature) }
                    </div>
                </div>
                <div className={pcn('__editor-container')}>
                    <div className={editorClassName}>
                        { renderSelectExamplesBar() }
                        <div className={epcn('__body')}>
                            <div
                                className={epcn('__code')}
                                dangerouslySetInnerHTML={{ __html: currentExample.code }}>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuerySection