import React, { useState, useEffect } from 'react'
import { cn, getPCN } from '../../utils/classes'
import { s3 } from '../../utils/path'
import icons from '../../svgs/icons'

function Tile(props) {
    const className = 'tile'
    const pcn = getPCN(className)
    const { name, type, icon, desc, likes, author } = props
    const [added, _] = useState(props.added || false)
    const [animateToAdded, setAnimateToAdded] = useState(false)

    useEffect(() => {
        if (props.added === true && !added) {
            setAnimateToAdded(true)
        }
    }, [props.added, added])

    return (
        <div className={cn(className, `${className}--${type.id}`)}>
            <div className={pcn('__top')}>
                <div
                    className={pcn(
                        '__icon-container',
                        props.added ? '__icon-container--added' : '',
                        animateToAdded ? '__icon-container--animate' : '',
                    )}>
                    <img
                        className={pcn('__icon')}
                        src={s3(icon)}
                        alt=""
                    />
                    <div dangerouslySetInnerHTML={{ __html: icons.check }}>
                    </div>
                </div>
                <div className={pcn('__main')}>
                    <div className={pcn('__name')}>
                        { name }
                    </div>
                    <div className={pcn('__desc')}>
                        <span>{ desc }</span>
                    </div>
                </div>
            </div>
            <div className={pcn('__bottom')}>
                <div className={pcn('__likes')}>
                    <span dangerouslySetInnerHTML={{ __html: icons.heart }}>
                    </span>
                    <span>{ likes }</span>
                </div>
                <span></span>
                <div className={pcn('__comp-type')}>
                    { type.name }
                </div>
                <span></span>
                <div className={pcn('__author')}>
                    { author }
                </div>
                <div
                    className={pcn(
                        '__button',
                        props.added ? '__button--added' : '',
                        animateToAdded ? '__button--animate' : '',
                    )}>
                    <span>+</span>
                    <span>Add</span>
                    <div dangerouslySetInnerHTML={{ __html: icons.check }}>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tile
