import React, { useState, useCallback, useRef, forwardRef, useImperativeHandle } from 'react'
import { getPCN } from '../../../utils/classes'
import LiveObjectSearch from './LiveObjectSearch'
import NewLiveColumnSpecs from './NewLiveColumnSpecs'
import { animated, useTransition } from 'react-spring'
import { specs } from '../../../data/specs'

const className = 'new-live-column-panel'
const pcn = getPCN(className)

function NewLiveColumnPanel(props, ref) {
    const { table = {} } = props
    const [state, setState] = useState({ index: 0, liveObjectSpec: {} })
    const [result, setResult] = useState({ cols: null, status: 'default' })
    const liveObjectSearchRef = useRef()
    const newLiveColumnSpecsRef = useRef()
    const transitions = useTransition(state.index === 0, {
        initial: { opacity: 1 },
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: { tension: 400, friction: 32 },
    })

    useImperativeHandle(ref, () => ({
        search: (...args) => liveObjectSearchRef.current?.search(...args),
        addTop: () => liveObjectSearchRef.current?.addTop(),
        selectLiveObject: spec => setState({ index: 1, liveObjectSpec: spec }),
        selectDataSource: (...args) => newLiveColumnSpecsRef.current?.selectDataSource(...args),
        typeColumnName: (...args) => newLiveColumnSpecsRef.current?.typeColumnName(...args),
        setTransform: (...args) => newLiveColumnSpecsRef.current?.setTransform(...args),
    }))

    const onSelectLiveObject = useCallback(result => {
        setState({ index: 1, liveObjectSpec: specs[result.id] || {} })
    }, [])

    const renderHeader = useCallback(() => (
        <div className={pcn('__header')}>
            <div className={pcn('__header-liner')}>
                <div className={pcn('__header-title')}>
                    { window.innerWidth > 580 && <span>Borrowers Table&nbsp;&mdash;&nbsp;</span> }
                    <span>{ window.innerWidth > 580 ? 'Add Live Columns' : 'New Live Column' }</span>
                </div>
                <div className={pcn('__header-bc', `__header-bc--${state.index}`)}>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    ), [table, state.index])

    const renderFooter= useCallback(() => (
        <div className={pcn('__footer')}>
            <div className={pcn('__footer-liner')}>
                <div
                    className={pcn('__footer-button', '__footer-button--shown')}>
                    <span>{ state.index === 1 ? 'Back' : 'Cancel'}</span>
                </div>
                <button
                    className={pcn(
                        '__footer-button',
                        state.index === 1 ? '__footer-button--shown' : '',
                        result.status === 'saving' ? '__footer-button--show-loader' : ''
                    )}>
                    { result.status === 'saving'
                        ? <span>Saving</span>
                        : <span>{ window.innerWidth <= 1000 ? 'Add Column' : 'Create'}</span>
                    }
                </button>
            </div>
        </div>
    ), [state.index, result.status])

    return (
        <div className={className}>
            { renderHeader() }
            { transitions(({ opacity }, item) =>
                item ? (
                    <animated.div
                        className={pcn('__section', '__section--0')}
                        style={{ opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] }) }}>
                        <LiveObjectSearch
                            onSelectLiveObject={onSelectLiveObject}
                            ref={liveObjectSearchRef}
                        />
                    </animated.div>
                ) : (
                    <animated.div
                        className={pcn('__section', '__section--1')}
                        style={{ opacity: opacity.to({ range: [1.0, 0.0], output: [1, 0] }) }}>
                        <NewLiveColumnSpecs
                            table={table}
                            liveObjectSpec={state.liveObjectSpec}
                            ref={newLiveColumnSpecsRef}
                        />
                    </animated.div>
                )
            )}
            { renderFooter() }
        </div>
    )
}

NewLiveColumnPanel = forwardRef(NewLiveColumnPanel)
export default NewLiveColumnPanel