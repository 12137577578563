import React, { useCallback } from 'react'
import { getPCN } from '../../../utils/classes'
import pixels from '../../../svgs/svgjs/future-pixels'
import pixelsBottom from '../../../svgs/svgjs/banner-left-pixels'
import RewardsStat from './RewardsStat'

const stats = {
    tables: {
        name: 'Live Tables & Objects',
        desc: 'When others use the Live Tables & Objects you publish.',
        min: 202,
        icon: 'deploy',
    },
    streams: {
        name: 'Event Streams',
        desc: 'When events you create are used by others for their own Live Objects.',
        min: 906,
        icon: 'sub',
    },
    functions: {
        name: 'Edge Functions',
        desc: 'When functions you write are used by others for their own Live Objects.',
        min: 860,
        icon: 'invoke',
    },
}

function RewardsSection() {
    const className = 'rewards'
    const pcn = getPCN(className)

    const renderStat = useCallback(stat => (
        <RewardsStat key={stat.icon} { ...stat }/>
    ), [])

    return (
        <div id='rewards' className={className}>
            <div
                className='pixels'
                dangerouslySetInnerHTML={{ __html: pixels }}>
            </div>
            <div
                className='bottom-pixels'
                dangerouslySetInnerHTML={{ __html: pixelsBottom }}>
            </div>
            <div className={pcn('__liner')}>
                <div className={pcn('__text-container')}>
                    <div className={pcn('__title')}>
                        <div>Get rewarded</div>
                        <span>for your impact <br/>on the ecosytem</span>
                    </div>
                    <div className={pcn('__subtitle')}>
                        Earn redeemable credits for curating impactful data for the ecosystem. We track usage of what you publish and make sure your contributions are rewarded.
                    </div>
                </div>
                <div className={pcn('__stats')}>
                    <div className={pcn('__stats-liner')}>
                        { renderStat(stats.tables) }
                        { renderStat(stats.streams) }
                        { renderStat(stats.functions) }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RewardsSection