const identity = `<svg viewBox="0 0 46 36">
    <g id="Exports" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group-94">
            <path d="M19.8181818,9.81818182 L19.8181818,-2.29150032e-13 L46,-1.14575016e-13 L46,36 L10,36 L10,9.81818182 L19.8181818,9.81818182 Z" id="Combined-Shape" fill="#0E0E23"></path>
            <rect id="Rectangle" fill="#0E0E23" x="0" y="0" width="10" height="10"></rect>
            <g id="noun-fingerprint-4027308" transform="translate(19.000000, 10.000000)" fill="#FFFFFF" fill-rule="nonzero">
                <path d="M1.96186881,14.7222904 C3.69459389,13.5759918 4.84174716,11.577955 4.84174716,9.30470348 C4.84174716,8.87206544 4.89513964,8.45218814 4.99554842,8.05182004 C5.0914381,7.669182 4.8668095,7.27840491 4.4941421,7.18 C4.1213153,7.08158691 3.74055812,7.31212679 3.64467642,7.69460123 C3.51562666,8.20920245 3.44697918,8.74887526 3.44697918,9.30470348 C3.44697918,11.0729652 2.55439463,12.6274847 1.20627235,13.5190184 C0.88310398,13.7331043 0.789549578,14.1764417 0.998142101,14.508589 C1.20673462,14.8407362 1.63870841,14.9362781 1.96190069,14.7221963 L1.96186881,14.7222904 Z" id="Path"></path>
                <path d="M6.49492947,5.54683027 C7.35547376,4.76732106 8.48592942,4.29460123 9.72378376,4.29460123 C12.4199486,4.29460123 14.6055414,6.53770961 14.6055414,9.30482618 C14.6055414,12.7086299 13.5991422,15.8712883 11.875264,18.4954192 C11.6601319,18.8229366 11.7445046,19.2680573 12.0636241,19.4888344 C12.3827436,19.7096278 12.8164509,19.6230348 13.0315671,19.2955174 C14.9058034,16.4429202 16.0003134,13.0047198 16.0003134,9.30492434 C16.0003134,5.74729652 13.1901744,2.86320654 9.72376782,2.86320654 C8.13191631,2.86320654 6.67858717,3.471591 5.5716835,4.47396319 C5.28338483,4.73517791 5.25583179,5.18795092 5.5103487,5.48382004 C5.76517645,5.78018405 6.20634786,5.80846217 6.49479,5.54676892 L6.49492947,5.54683027 Z" id="Path"></path>
                <path d="M1.25889571,11.5770961 C1.75440407,10.9591411 2.05217136,10.1674847 2.05217136,9.30462168 C2.05217136,4.97333333 5.46007656,1.45881391 9.67369094,1.43141104 C10.0586604,1.42885481 10.3693713,1.1064499 10.3669152,0.711370143 C10.3644098,0.316269939 10.0499609,-0.00229038855 9.66500341,0.000230364307 C4.68481359,0.0325177914 0.657463164,4.18593047 0.657463164,9.30453988 C0.657463164,9.82200409 0.479221226,10.2970143 0.181880342,10.6676483 C-0.0625183787,10.9724785 -0.0197103456,11.4238037 0.277459179,11.6751329 C0.574939542,11.9259632 1.01454481,11.8820286 1.2591109,11.5770389 L1.25889571,11.5770961 Z" id="Path"></path>
                <path d="M12.2809082,1.8791411 C15.2602548,2.9599591 17.3949976,5.87660532 17.3949976,9.30449898 C17.3949976,11.3036401 17.0992269,13.23182 16.5505133,15.0451943 C16.4365632,15.4224008 16.6422027,15.8235583 17.0097174,15.9409816 C17.377252,16.0579305 17.7684421,15.8471984 17.8825358,15.4696933 C18.4722123,13.5224949 18.7897855,11.4514519 18.7897855,9.30486708 C18.7897855,5.25337423 16.2670127,1.80711656 12.7455718,0.529815951 C12.3828632,0.398167689 11.9840575,0.593562372 11.8557768,0.966134969 C11.7274962,1.33870757 11.9181996,1.74768916 12.281227,1.8793456 L12.2809082,1.8791411 Z" id="Path"></path>
                <path d="M9.02627025,9.30470348 C9.02627025,10.7283845 8.75634293,12.0874847 8.2661507,13.3307975 C8.12184594,13.6969775 8.29401537,14.1146012 8.65112013,14.2626994 C9.00791086,14.4108016 9.41482628,14.2341014 9.55912705,13.8675992 C10.1150098,12.4586421 10.4210661,10.9183558 10.4210661,9.304818 C10.4210661,8.90971779 10.1086416,8.58907157 9.72367217,8.58907157 C9.33870274,8.58907157 9.02627822,8.90971779 9.02627822,9.304818 L9.02627025,9.30470348 Z" id="Path"></path>
                <path d="M6.72925384,16.0179959 C6.24279167,16.6402863 5.6898579,17.2061759 5.08165067,17.7035174 C4.78074312,17.9500327 4.7312401,18.4007362 4.97143852,18.7095706 C5.21132211,19.0183967 5.65030171,19.0692025 5.95121724,18.823002 C6.6398838,18.259362 7.26614357,17.6187076 7.81704494,16.9137996 C8.05802045,16.6056115 8.00991621,16.1547403 7.70963431,15.9074601 C7.40934843,15.6604622 6.97003807,15.7099918 6.72909842,16.0180164 L6.72925384,16.0179959 Z" id="Path"></path>
                <path d="M3.57304808,17.0621677 C6.0192271,15.395501 7.63148236,12.5435583 7.63148236,9.30470348 C7.63148236,8.11877301 8.56814221,7.15746421 9.7236642,7.15746421 C10.2540423,7.15746421 10.7387908,7.36004499 11.1072539,7.69394683 C11.3960189,7.95548057 11.837007,7.92720245 12.0916952,7.63099796 C12.346523,7.33511247 12.31897,6.8823272 12.0303604,6.6211411 C11.4156176,6.06388139 10.6080354,5.72613088 9.72366022,5.72613088 C7.79773709,5.72613088 6.23669047,7.32825767 6.23669047,9.30486299 C6.23669047,12.0458037 4.87195029,14.4598732 2.80172639,15.8696892 C2.48120413,16.0879264 2.39373101,16.5328793 2.60651985,16.8616728 C2.81963148,17.1909489 3.25270515,17.2807321 3.57306801,17.062499 L3.57304808,17.0621677 Z" id="Path"></path>
                <path d="M11.7995071,9.98527607 C11.6363647,13.3871575 10.2547994,16.4605317 8.09287819,18.7562372 C7.82512674,19.0399796 7.83275822,19.4933742 8.10969057,19.7683436 C8.38615745,20.042818 8.82792663,20.0349898 9.09584546,19.7510888 C11.4855156,17.2137063 13.0124497,13.8165284 13.1925368,10.0557922 C13.211684,9.66149366 12.914982,9.32536278 12.5303313,9.30568998 C12.146143,9.28635828 11.8186308,9.59087198 11.7994625,9.98564908 L11.7995071,9.98527607 Z" id="Path"></path>
            </g>
        </g>
    </g>
</svg>`

export default identity