import React, { useCallback, useState, useImperativeHandle, forwardRef, useEffect } from 'react'
import { cn, getPCN } from '../../utils/classes'
import modelRelationshipIcon from '../../svgs/svgjs/model-relationship'
import keyIcon from '../../svgs/svgjs/key'
import linkIcon from '../../svgs/svgjs/link'
import { getRandom } from '../../utils/math'
import tableIcon from '../../svgs/svgjs/table-editor'
import { cloneDeep } from 'lodash-es'
import Counter from '../shared/Counter'
import CountUp from 'react-countup'
import { s3 } from '../../utils/path' 

const className = 'table'
const pcn = getPCN(className)

const status = {
    BACKFILLING: {
        id: 'backfilling',
        title: 'Backfilling...',
    },
    POPULATING: {
        id: 'populating',
        title: 'Backfilling...',
    },
    IN_SYNC: {
        id: 'in-sync',
        title: 'IN SYNC',
    }
}

const getColHeaderIcon = col => {
    if (col.isLiveLinkColumn && col.liveSource) {
        return [linkIcon, 'link']
    }

    if (col.isPrimaryKey) {
        return [keyIcon, 'key']
    }

    if (col.isForeignKey) {
        return [modelRelationshipIcon, 'rel']
    }

    return [null, null]
}

const getColType = (col, table, replaceLiveColType = true) => {
    if (!col.liveSource) {
        return <span className={pcn('__col-header-type')}>{ col.type }</span>
    }
    return (
        <span className={pcn('__col-header-type')}>
            <span>{ replaceLiveColType ? table.liveObjectSpec?.name : col.type }</span>
        </span>
    )
}

const timing = {
    rowFadeInDelay: 35,
}

function Table(props, ref) {
    const { addCols = [], liveObjectSpec = {}, replaceLiveColType = true } = props
    const [table, setTable] = useState(props.table || {})

    const createNewLiveColumns = useCallback(() => {
        const newTable = cloneDeep(table)
        const liveSourcesSelected = {}
        for (let c of addCols) {
            liveSourcesSelected[c.formatter?.config?.key ? c.formatter.config.key : c.dataSource] = c
        }

        let newCols = []
        for (let col of newTable.columns) {
            // Put live source on linked column.
            if (col.isLiveLinkColumn) {
                col.liveSource = liveObjectSpec.name
            } else if (col.hide && col.liveSource && liveSourcesSelected[col.liveSource]) {
                col.hide = false
                col.name = liveSourcesSelected[col.liveSource].columnName
            }
            newCols.push(col)
        }

        // Update table.
        newTable.columns = newCols
        newTable.status = status.BACKFILLING
        newTable.liveObjectSpec = liveObjectSpec

        setTable(newTable)
    }, [table, addCols, liveObjectSpec])

    const backfillLiveTable = useCallback(() => {
        const newTable = cloneDeep(table)
        newTable.status = status.BACKFILLING
        setTable(newTable)
    }, [table])

    useImperativeHandle(ref, () => ({
        createNewLiveColumns,
        backfillLiveTable,
    }))

    useEffect(() => {
        if (props.table.name !== table.name) {
            setTable(props.table)
            return
        }

        // Backfilling -> Populating
        if (table.status?.id === status.BACKFILLING.id) {
            setTimeout(() => {
                const newTable = cloneDeep(table)
                newTable.status = status.POPULATING
                setTable(newTable)
            }, table.isLiveTable ? 2500 : 3000)
        }
        
        // Populating -> In-Sync
        else if (table.status?.id === status.POPULATING.id) {
            setTimeout(() => {
                const newTable = cloneDeep(table)
                newTable.status = status.IN_SYNC
                setTable(newTable)
            }, table.records.length * timing.rowFadeInDelay + 2000)
        }
    }, [table, props.table, addCols, createNewLiveColumns])

    const renderColHeaders = useCallback(() => {
        const colHeaders = [(
            <div key='check' className={pcn('__col-header', '__col-header--check-col')}>
                <span></span>
            </div>
        )]

        table.columns.forEach(col => {
            const [icon, mod] = getColHeaderIcon(col)
            const colType = getColType(col, table, replaceLiveColType)

            colHeaders.push((
                <div
                    key={col.name || col.liveSource}
                    className={pcn(
                        '__col-header',
                        `__col-header--${table.name}-${col.name || col.liveSource}`,
                        !!col.liveSource ? '__col-header--live' : '',
                        col.isLiveLinkColumn ? '__col-header--live-link' : '',
                        col.isPrimaryKey ? '__col-header--primary' : '',
                    )}>
                    { icon &&
                        <span
                            className={pcn('__col-header-type-icon', `__col-header-type-icon--${mod}`)}
                            dangerouslySetInnerHTML={{ __html: icon }}>
                        </span>
                    }
                    { !icon && !col.isLiveLinkColumn && !!col.liveSource && table.status?.id === status.IN_SYNC.id &&
                        <span className='blink-indicator'><span></span></span>
                    }
                    { !icon && !col.isLiveLinkColumn && !!col.liveSource && (table.status?.id === status.BACKFILLING.id || table.status?.id === status.POPULATING.id) &&
                        <span className={pcn('__col-header-type-icon', `__col-header-type-icon--circle`)}><span></span></span>
                    }
                    <span className={pcn('__col-header-name')}>{col.name}</span>
                    { colType }
                </div>
            ))
        })

        colHeaders.push((
            <div key='add' className={pcn('__col-header', '__col-header--new-col')}>
                <span id='extendTableDropdownTarget'>
                    <span>+</span>
                </span>
            </div>
        ))

        return colHeaders
    }, [table, replaceLiveColType])

    const renderRecords = useCallback(() => table.records.map((record, i) => {
        const cells = [(
            <div key='check' className={pcn('__cell', '__cell--check-col')}>
                <span></span>
            </div>
        )]

        const delay = i * timing.rowFadeInDelay

        table.columns.forEach(col => {
            let value = record[col.hide === false ? col.liveSource : col.name]

            if (value === null) {
                value = 'NULL'
            }
            else if (table.status?.id === status.IN_SYNC.id && col.recordIdsToUpdate?.length && col.recordIdsToUpdate.includes(record.id)) {
                value = (
                    <Counter
                        initialValue={value}
                        incrementAmount={() => Number(getRandom(...col.incrementRange).toFixed(col.numDecimals))}
                        formatter={v => v == 0 ? 0 : parseFloat(v.toFixed(col.numDecimals))}
                        onlyOnce={true}
                        highlightFlip={true}
                        updateRange={[3, 18]}
                    />
                )
            }
            else if (value === 0) {
                value = 0
            }
            else if (!value) {
                value = ''
            }

            cells.push((
                <div
                    key={col.name || col.liveSource}
                    className={pcn(
                        '__cell', 
                        `__cell--${table.name}-${col.name || col.liveSource}`,
                        !!col.liveSource ? '__cell--live' : '',
                        col.isLiveLinkColumn ? '__cell--live-link' : '',
                        value === 'NULL' ? '__cell--null' : '',
                        col.isPrimaryKey ? '__cell--primary' : '',
                    )}>
                    <span style={{ transition: `opacity 0.25s ease ${table.isLiveTable ? 0 : delay}ms, color 0.25s ease 0s` }}>
                        { value }
                    </span>
                </div>
            ))
        })

        cells.push((<div key='empty' className={pcn('__cell')}></div>))

        return (
            <div
                key={i}    
                className={pcn('__row')}
                style={ table.isLiveTable && table.status?.id === status.POPULATING.id 
                    ? { transition: `opacity 0.25s ease ${delay}ms` } 
                    : {} 
                }>
                { cells }
            </div>
        )
    }), [table])

    const renderTableLoading = useCallback(() => (
        <div className={pcn('__table-loading')}>
            <div className='indeterminate'></div>
        </div>
    ), [])

    const renderStatus = useCallback(() => (
        <div className={pcn('__header-status-container')}>
            <div className={pcn('__header-status', `__header-status--backfilling`)}>
                <span>{ status.BACKFILLING.title }</span>
            </div>
            <div className={pcn('__header-status', `__header-status--in-sync`)}>
                { table.status?.id === status.IN_SYNC.id && <span className='blink-indicator'><span></span></span> }
                <span>{ status.IN_SYNC.title }</span>
            </div>
        </div>
    ), [table])

    const renderNumRecords = useCallback((mod) => {
        const numRecords = table.records.length
        const isPlural = numRecords !== 1

        let start = 0
        let end = 0
        if (table.status?.id === status.POPULATING.id) {
            end = numRecords
        } else if (table.status?.id === status.IN_SYNC.id) {
            start = numRecords
            end = numRecords
        } else if (table.status === null) {
            start = numRecords
            end = numRecords
        }

        return (
            <div className={pcn(`__${mod}-num-records`)}>
                <CountUp start={start} end={end} delay={0} duration={(table.records.length * timing.rowFadeInDelay) / 1000}>
                    {({ countUpRef }) => (
                        <span>
                            <span ref={countUpRef}></span>
                            <span>{ `Record${isPlural ? 's' : ''}` }</span>
                        </span>
                    )}
                </CountUp>
            </div>
        )
    }, [table])

    return (
        <div className={cn(
            className,
            `${className}--${table.name}`,
            `${className}--${table.status?.id}`,
            table.isLiveTable ? `${className}--live-table` : '',
        )}>
            <div className={pcn('__header')}>
                <div className={pcn('__header-liner')}>
                    { table.nsp ? (
                            <img className={pcn('__table-icon')} src={s3(`${table.nsp}.jpg`)}/>
                        ) : (
                            <div
                                className={pcn('__table-icon')}
                                dangerouslySetInnerHTML={{ __html: tableIcon }}>
                            </div>
                        )
                    }
                    <div className={pcn('__table-name')}>
                        <span>{ table.displayName || table.name }</span>
                    </div>
                    { renderStatus() }
                </div>
            </div>
            <div className={pcn('__main')}>
                <div className={pcn('__col-headers')}>
                    { table.status?.id === status.BACKFILLING.id && renderTableLoading() }
                    { renderColHeaders() }
                </div>
                <div className={pcn('__records')}>
                    { renderRecords() }
                </div>
            </div>
        </div>
    )
}

Table = forwardRef(Table)
export default Table