const blogSplashPixels = `<svg viewBox="0 0 171.588785 183.807684">
    <defs>
        <linearGradient x1="120.827226%" y1="100.316162%" x2="-15.8651338%" y2="51.5602689%" id="blog-splash-linearGradient-1">
            <stop stop-color="#0E0D24" offset="0%"></stop>
            <stop stop-color="#14142C" offset="68.5327684%"></stop>
            <stop stop-color="#161531" stop-opacity="0" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="117.581945%" y1="96.8583132%" x2="-15.8651338%" y2="56.9010683%" id="blog-splash-linearGradient-2">
            <stop stop-color="#161531" stop-opacity="0" offset="0%"></stop>
            <stop stop-color="#14142C" offset="31.4672316%"></stop>
            <stop stop-color="#0E0D24" offset="100%"></stop>
        </linearGradient>
    </defs>
    <g id="Exports" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="side-pixels" fill-rule="nonzero">
            <g id="Pixels-2" fill="url(#blog-splash-linearGradient-1)">
                <path d="M0.252336,57.1952663 L0.252336,78.9839391 L22.009345,78.9839391 L22.009345,57.1952663 L0.252336,57.1952663 Z M90,2.84217094e-14 L90,21.7886729 L111.757009,21.7886729 L111.757009,2.84217094e-14 L90,2.84217094e-14 Z M34,-1.42108547e-14 L34,21.7886729 L55.757009,21.7886729 L55.757009,-1.42108547e-14 L34,-1.42108547e-14 Z M-7.10542736e-15,28 L-7.10542736e-15,49.7886729 L21.757009,49.7886729 L21.757009,28 L-7.10542736e-15,28 Z M119.915887,28.1437025 L119.915887,49.9323753 L141.672897,49.9323753 L141.672897,28.1437025 L119.915887,28.1437025 Z M149.831775,28.1437025 L149.831775,49.9323753 L171.588785,49.9323753 L171.588785,28.1437025 L149.831775,28.1437025 Z M111.757009,57.1952663 L111.757009,78.9839391 L90,78.9839391 L90,57.1952663 L111.757009,57.1952663 Z" id="Pixels" transform="translate(85.7944, 39.492) scale(-1, 1) rotate(-180) translate(-85.7944, -39.492)"></path>
            </g>
            <g id="Pixels-2" transform="translate(3.1763, 104.8237)" fill="url(#blog-splash-linearGradient-2)">
                <path d="M-2.30926389e-14,0 L-2.30926389e-14,21.7886729 L21.757009,21.7886729 L21.757009,0 L-2.30926389e-14,0 Z M29.915887,28.1437025 L29.915887,49.9323753 L51.672897,49.9323753 L51.672897,28.1437025 L29.915887,28.1437025 Z M59.831775,28.1437025 L59.831775,49.9323753 L81.588785,49.9323753 L81.588785,28.1437025 L59.831775,28.1437025 Z M21.757009,57.1952663 L21.757009,78.9839391 L-1.59872116e-14,78.9839391 L-1.59872116e-14,57.1952663 L21.757009,57.1952663 Z" id="Pixels" transform="translate(40.7944, 39.492) scale(-1, 1) rotate(-180) translate(-40.7944, -39.492)"></path>
            </g>
        </g>
    </g>
</svg>`
export default blogSplashPixels