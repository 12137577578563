import { useCallback, useEffect, useState } from 'react'
import { getPCN } from '../../utils/classes'
import { NamespaceHeader } from './NamespaceHeader'
import { NamespaceResources } from './NamespaceResources'
import api from '../../utils/api'

const className = 'namespace-page'
const pcn = getPCN(className)

export const NamespacePage = ({ slug, section, subSection }) => {
    const [nsp, setNsp] = useState(null)
    const isLoading = nsp === null

    const getNamespace = useCallback(async () => {
        const { data, ok } = await api.core.getNamespace({ slug })
        if (!ok) {
            return
        }
        setNsp(data || {})
    }, [slug])

    useEffect(() => {
        getNamespace()
    }, [getNamespace])

    useEffect(() => {
        if (!nsp) return
        document.title = nsp.displayName || `@${slug}`
    }, [nsp, slug])

    return <div className={className}>
        <NamespaceHeader nsp={nsp || {}} isLoading={isLoading}/>
        <NamespaceResources
            slug={slug}
            section={section}
            subSection={subSection}
        />
    </div>
}