import React, { useState, useCallback, forwardRef, useMemo, useImperativeHandle, useEffect } from 'react'
import { getPCN, cn } from '../../../utils/classes'
import arrowRight from '../../../svgs/svgjs/arrow-right'
import { noop } from '../../../utils/nodash'
import { s3 } from '../../../utils/path'
import arrowIcon from '../../../svgs/svgjs/back'

const className = 'md-icon-carousel-controls'
const pcn = getPCN(className)

function MdIconCarouselControls(props, ref) {
    const items = props.items || []
    const [index, setIndex] = useState(props.index || 0)
    const currentItem = useMemo(() => items[index] || {}, [items, index])
    const onNav = props.onNav || noop

    useImperativeHandle(ref, () => ({
        setIndex: (i) => setIndex(i)
    }))
    
    useEffect(() => {
        setIndex(props.index || 0)
    }, [props.index])

    const renderBreadcrumbs = useCallback(() => (
        <div className={pcn('__bc')}>
            { items.map((_, i) => (
                <span key={i} className={i === index ? '--current' : ''}></span>
            ))}
        </div>
    ), [items, index])

    return (
        <div className={cn(className, currentItem.icon ? `${className}--with-icon` : '')}>
            <div className={pcn('__liner')}>
                <div className={pcn('__main')}>
                    { renderBreadcrumbs() }
                    { currentItem.icon && <img key={currentItem.icon} className={pcn('__icon')} src={s3(`${currentItem.icon}.jpg`)} /> }
                    <div className={pcn('__title')}>
                        <span>{currentItem.title}</span>
                    </div>
                    <div className={pcn('__subtitle')}>
                        <span>{currentItem.subtitle}</span>
                    </div>
                </div>
                <div className={pcn('__controls')}>
                    <div
                        className={pcn('__next-step')}
                        onClick={() => {
                            let nextIndex = index + 1
                            nextIndex = nextIndex >= items.length ? 0 : nextIndex
                            setIndex(nextIndex)
                            onNav(nextIndex)
                        }}>
                            <span>example</span>
                        <div>
                            <span>{currentItem.next}</span>
                            <span dangerouslySetInnerHTML={{ __html: arrowRight }}></span>
                        </div>
                        <span dangerouslySetInnerHTML={{ __html: arrowIcon }}></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

MdIconCarouselControls = forwardRef(MdIconCarouselControls)
export default MdIconCarouselControls