import { useCallback, useEffect, useState, useRef } from 'react'
import api from '../../utils/api'
import { getPCN } from '../../utils/classes'
import { LiveObjectComponents } from './LiveObjectComponents'
import { LiveObjectHeader } from './LiveObjectHeader'

const className = 'live-object-page'
const pcn = getPCN(className)

export const LiveObjectPage = ({ nsp, uid, isEvent }) => {
    const [liveObject, setLiveObject] = useState(null)
    const lastUid = useRef(uid)
    const isLoading = liveObject === null || lastUid.current !== uid
    lastUid.current = uid

    const getLiveObject = useCallback(async () => {
        const { data, ok } = await api.core.getLiveObject({ uid })
        if (!ok) {
            return
        }

        setLiveObject(data || {})
    }, [uid])

    useEffect(() => {
        getLiveObject()
    },[getLiveObject])

    useEffect(() => {
        if (!liveObject) return
        document.title = liveObject.displayName || `@${nsp}`
    }, [liveObject, nsp])

    return <div className={className}>
        <LiveObjectHeader liveObject={liveObject || {}} isLoading={isLoading} isEvent={isEvent}/>
        <LiveObjectComponents uid={uid} liveObject={liveObject} isEvent={isEvent} />
    </div>
}
