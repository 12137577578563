import { useCallback, useEffect, useState } from 'react'
import { chainNames } from '../../utils/chains'
import { getPCN, cn } from '../../utils/classes'
import { Link } from 'react-router-dom'
import { extractCustomerNspFromContractNsp } from '../../utils/formatters'
import { format as toTimeAgo } from 'timeago.js'
import ImageComp from '../shared/ImageComp'
import { withPeriod, toNamespacedVersion } from '../../utils/formatters'
import MultiFacetCounter from '../shared/MultiFacetCounter'
import PH from '../shared/PH'
import verifiedIcon from '../../svgs/svgjs/verified'
import github from '../../svgs/svgjs/github'
import TimeAgo from '../shared/TimeAgo'
import $ from 'jquery'
import { paths } from '../../utils/nav'
import { copyToClipboard } from '../../utils/clipboard'

const className = 'live-object-header'
const pcn = getPCN(className)

const tickerDuration = count => {
    let duration = Math.min(count / 1000000, 1)
    return Math.max(duration, 2)
}

export const LiveObjectHeader = ({ liveObject, isLoading, isEvent }) => {
    const customerNsp = extractCustomerNspFromContractNsp(liveObject.latestVersion?.nsp) || ''
    const timeSincePublished = liveObject.latestVersion?.createdAt ? toTimeAgo(liveObject.latestVersion.createdAt) : ''
    const chainIds = $.isEmptyObject(liveObject) ? [] : Object.keys(liveObject.latestVersion?.config.chains)
    const sortedChainIds = chainIds 
        .map(v => Number(v))
        .sort((a, b) => a !== 42161 ? -1 : a - b)
        .map(v => v.toString())

    const supportedChainNames = sortedChainIds.map(chainId => chainNames[chainId])
    const version = liveObject.latestVersion?.version

    const [recordStats, setRecordStats] = useState({
        count: liveObject.records,
        lastInteraction: liveObject.lastInteraction ? new Date(liveObject.lastInteraction) : null,
    })

    const copyFullVersion = useCallback(async () => {
        const { nsp, name, version } = liveObject.latestVersion || {}
        if (!nsp || !name || !version) return
        await copyToClipboard(toNamespacedVersion(nsp, name, version))
    }, [liveObject])

    const renderChains = useCallback(() => {
        return supportedChainNames?.map((chain, i) => 
            <div key={i} className={pcn('__chain', `__chain--${chain.replace(' ', '').toLowerCase()}`)}>
                <span>{chain.split(' ').pop()[0]}</span>
            </div>
    )}, [supportedChainNames])

    useEffect(() => {
        setRecordStats({ 
            count: liveObject.records,
            lastInteraction: liveObject.lastInteraction ? new Date(liveObject.lastInteraction) : null,
        })
    }, [liveObject])

    return <div className={cn(className, isEvent ? `${className}--event` : '')}>
        <div className={pcn('__top')}>
            <div className={pcn('__top-left')}>
                <ImageComp
                    phId='subject-header-icon'
                    src={liveObject.icon}
                    blurhash={liveObject.blurhash}
                    withBgBlur={true}
                    height={150}
                    width={150}
                />
                <div className={pcn('__body')}>
                    <div className={pcn('__label')}>{ isEvent ? 'Event' : 'Live Table' }</div>
                    <h2>
                        { isLoading ? <PH id='subject-header-name'/> : liveObject.displayName }
                    </h2>
                    <div className={pcn('__icons')}>
                        { isLoading ? <PH id='subject-header-icons'/> : <>
                            <Link className={pcn('__tag')} to={paths.toNamespace(customerNsp)}>
                                { liveObject.verified && 
                                    <span
                                        className={pcn('__verified-icon')}
                                        dangerouslySetInnerHTML={{ __html: verifiedIcon }}>    
                                    </span> 
                                }
                                <span>@{customerNsp}</span>
                            </Link>
                            <span className={pcn('__dot')}></span>
                            <div className={pcn('__chains')}>
                                { renderChains() }
                            </div>
                            <span className={pcn('__dot')}></span>
                            <div className={pcn('__version', isEvent ? '__version--is-event' : '')}>{
                                version && (
                                    <div onClick={copyFullVersion}>
                                        <span>{ isEvent ? version : `v${version}` }</span>
                                        <span>Copy</span>
                                    </div>
                                )
                            }</div>
                            <span className={pcn('__dot')}></span>
                            <div className={pcn('__published')}>published {timeSincePublished}</div>
                            { liveObject.codeUrl && <span className={pcn('__dot')}></span> }
                            <div className={pcn('__links')}>
                                { liveObject.codeUrl && (
                                    <a
                                        target='_blank'
                                        rel='noreferrer'
                                        href={liveObject.codeUrl}
                                        dangerouslySetInnerHTML={{ __html: github }}>
                                    </a>
                                )}
                            </div>                        
                        </> }
                    </div>
                </div>
            </div>
            <div className={pcn('__top-right')}>
                <div>
                    <h4>RECORDS</h4>
                    <MultiFacetCounter
                        key={liveObject.latestVersion?.id}
                        isLoading={isLoading}
                        value={recordStats.count}
                        duration={tickerDuration(recordStats.count || 0)}
                    />
                </div>
                <div>
                    <h4>LAST UPDATED</h4>
                    { isLoading || recordStats.count === 0 ? '--' : <TimeAgo value={recordStats.lastInteraction} /> }
                </div>
            </div>
        </div>
        <div className={pcn('__bottom')}>
            { isLoading ? <PH id='subject-header-bottom'/> : withPeriod(liveObject.desc) }
        </div>
    </div>
}