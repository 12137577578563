import React, { useCallback, useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react'
import $ from 'jquery';
import { getPCN, cn } from '../../utils/classes'
import { animated, useTransition } from 'react-spring'
import { noop } from '../../utils/nodash'
import newColIcon from '../../svgs/svgjs/new-col'
import newLiveColIcon from '../../svgs/svgjs/new-live-col'

const className = 'new-column-dropdown'
const pcn = getPCN(className)

const options = {
    standard: {
        id: 'standard',
        name: 'Standard Column',
        desc: 'An empty Postgres data column.',
        icon: newColIcon,
    },
    live: {
        id: 'live',
        name: 'Live Column',
        desc: 'Web3 data that auto-populates & auto-updates.',
        icon: newLiveColIcon,
    }
}

const orderedOptions = [
    options.standard,
    options.live,
]

function NewColumnDropdown(props, ref) {
    const { id } = props
    const [shown, setShown] = useState(false)
    const [showHover, setShowHover] = useState([])
    const [showClick, setShowClick] = useState([])
    const preventSelection = useRef(false)
    const transitions = useTransition(shown, {
        from: { opacity: 0, transform: 'translateY(-10px)' },
        enter: { opacity: 1, transform: 'translateY(0px)' },
        leave: { opacity: 0, transform: 'translateY(-10px)' },
        config: enter => key => {
            return enter
                ? { tension: 310, friction: key === 'opacity' ? 27 : 17 }
                : { tension: 310, friction: 27 }
        }
    })

    useImperativeHandle(ref, () => ({
        show: () => {
            if (preventSelection.current) return
            setShown(true)
        },
        hide: () => {
            if (preventSelection.current) return
            setShown(false)
        },
        setShowHover,
        setShowClick,
    }))

    return transitions(
        (styles, item) => item && (
            <animated.div
                id={id}
                className={cn('dropdown', className)}
                style={styles}>
                <div className={pcn('__liner')}>
                    { orderedOptions.map((option, i) => (
                        <div
                            key={option.id}
                            className={pcn(
                                '__option', 
                                `__option--${option.id}`,
                                showHover.includes(i) ? '__option--hover' : '',
                                showClick.includes(i) ? '__option--click' : '',    
                            )}>
                            <div dangerouslySetInnerHTML={{ __html: option.icon }}>
                            </div>
                            <div>
                                <span>{option.name}</span>
                                <span>{option.desc}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </animated.div>
        )
    )
}

NewColumnDropdown = forwardRef(NewColumnDropdown)
export default NewColumnDropdown