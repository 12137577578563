import React, { useState, useRef, useEffect, useCallback } from 'react'
import Table from '../../shared/Table'
import { tables } from '../../../data/dapps'
import { ensProfileSpec, nftSpec, aaveUserPositionSpec } from '../../../data/specs'
import { getPCN, cn } from '../../../utils/classes'
import Tile from '../../shared/Tile'
import { compTypes } from '../../../utils/comp-types'

const className = 'splash-graphic'
const pcn = getPCN(className)

const addWalletCols = [
    {
        formatter: null,
        dataSource: 'domain',
        columnName: 'domain',
    },
    {
        formatter: {
            config: {
                key: 'avatar'
            },
        },
        dataSource: 'textRecords',
        columnName: 'avatar',
    }
]

const tiles = [
    {
        id: 'NFT',
        name: 'NFT Sales Price',
        icon: 'user-nfts.jpg',
        desc: 'The latest sales price for an NFT.',
        likes: '1.7k',
        author: '@0xmerkle',
        type: compTypes.LIVE_OBJECT,
    },
    {
        id: 'AaveUserHealth',
        name: 'Aave User Health',
        icon: 'aave.jpg',
        desc: 'A user\'s current health on the Aave V3 protocol.',
        likes: '1.1k',
        author: '@aave',
        type: compTypes.LIVE_OBJECT,
    },
    {
        id: 'ENSProfile',
        name: 'ENS Profile',
        icon: 'ens.jpg',
        desc: 'Profile data for an ENS domain.',
        likes: '2.4k',
        author: '@spec',
        type: compTypes.LIVE_OBJECT,
    },
    {
        id: 'CompoundMarket',
        name: 'Compound Market',
        icon: 'compound.jpg',
        desc: 'Current market data for a Compound market.',
        likes: '3.2k',
        author: '@j1mmie',
        type: compTypes.LIVE_OBJECT,
    },
    {
        id: 'UniswapTradingPairs',
        name: 'Uniswap Trading Pairs',
        icon: 'uniswap.jpg',
        desc: 'Current UniswapV3 trading pairs with spot prices.',
        likes: '2.8k',
        author: '@paul',
        type: compTypes.LIVE_TABLE,
    },
    {
        id: 'WalletNFTs',
        name: 'Wallet NFTs',
        icon: 'get-nft-sales-history.jpg',
        desc: 'NFTs owned by wallet.',
        likes: '5.1k',
        author: '@spec',
        type: compTypes.LIVE_TABLE,
    },
    {
        id: 'YieldPosition',
        name: 'Balancer Rewards',
        icon: 'balancer.jpg',
        desc: 'A user\'s liquidity mining rewards on the Balancer protocol.',
        likes: '1.2k',
        author: '@balancer',
        type: compTypes.LIVE_OBJECT,
    },
    {
        id: 'AaveUserPositions',
        name: 'Aave User Positions',
        icon: 'aave.jpg',
        desc: 'Current user asset positions on Aave V3.',
        likes: '1.4k',
        author: '@aave',
        type: compTypes.LIVE_TABLE,
    },
    {
        id: 'FranciumUserFarmPosition',
        name: 'Francium Farm Position',
        icon: 'francium.jpg',
        desc: 'Current user farm position on Francium',
        likes: '2.0k',
        author: '@',
        type: compTypes.LIVE_OBJECT,
    }
]

function SplashGraphic(props) {
    const [state, setState] = useState({
        clickedAddCols: false,
        showAddedCols: false,
        clickedAddNFTsTable: false,
        showNFTsTable: false,
        clickedAddPositionsTable: false,
        showPositionsTable: false,
        featureIndex: 0
    })
    const walletsTableRef = useRef()
    const nftsTableRef = useRef()
    const positionsTableRef = useRef()

    const showNextFeature = useCallback(() => {
        if (state.featureIndex < tiles.length - 2) {
            setState(prevState => ({ ...prevState, featureIndex: prevState.featureIndex + 1 }))
        }
    }, [state.featureIndex])

    useEffect(() => {
        // Tick
        if (state.featureIndex === 0) {
            setTimeout(showNextFeature, window.innerWidth <= 580 ? 5 : 1000)
            return
        }

        // Tick
        if (state.featureIndex === 1) {
            setTimeout(showNextFeature, window.innerWidth <= 580 ? 5 : 1000)
            return
        }

        // At ENS.
        if (state.featureIndex === 2) {
            // Just clicked Add Columns button.
            if (state.clickedAddCols) {
                // Tick 
                if (state.showAddedCols) {
                    walletsTableRef.current?.createNewLiveColumns()
                    setTimeout(showNextFeature, 8000)
                }
                // Show columns if you haven't yet.
                else {
                    setTimeout(() => setState(prevState => ({ ...prevState, showAddedCols: true })), 125)                    
                }
            }
            // Click Add Columns button.
            else {
                setTimeout(() => setState(prevState => ({ ...prevState, clickedAddCols: true })), window.innerWidth <= 580 ? 1000 : 2000)                
            }
            return
        }

        if ([3, 4].includes(state.featureIndex)) {
            setTimeout(showNextFeature, window.innerWidth <= 580 ? 5 : 700)
            return
        }

        if (state.featureIndex === 5) {
            // Just clicked Add Table button.
            if (state.clickedAddNFTsTable) {
                // Tick 
                if (state.showNFTsTable) {
                    setTimeout(() => nftsTableRef.current?.backfillLiveTable(), 300);
                    setTimeout(showNextFeature, 8000)
                }
                // Show table if you haven't yet.
                else {
                    setTimeout(() => setState(prevState => ({ ...prevState, showNFTsTable: true })), 20)                    
                }
            }
            // Click Add Table button.
            else {
                setTimeout(() => setState(prevState => ({ ...prevState, clickedAddNFTsTable: true })), window.innerWidth <= 580 ? 5 : 2000)                
            }
            return            
        }

        if (state.featureIndex === 6) {
            setTimeout(showNextFeature, window.innerWidth <= 580 ? 5 : 900)
            return
        }

        if (state.featureIndex === 7) {
            // Just clicked Add Table button.
            if (state.clickedAddPositionsTable) {
                // Tick 
                if (state.showPositionsTable) {
                    setTimeout(() => positionsTableRef.current?.backfillLiveTable(), 300);
                }
                // Show table if you haven't yet.
                else {
                    setTimeout(() => setState(prevState => ({ ...prevState, showPositionsTable: true })), 20)                    
                }
            }
            // Click Add Table button.
            else {
                setTimeout(() => setState(prevState => ({ ...prevState, clickedAddPositionsTable: true })), window.innerWidth <= 580 ? 5 : 2000)                
            }
            return            
        }
    }, [state, showNextFeature])

    const renderFeatures = useCallback(() => {
        return tiles.map((tile, i) => (
            <div key={tile.id} className={pcn('__feature', `__feature--${i - state.featureIndex}`)}>
                <Tile
                    added={
                        (tile.id === 'ENSProfile' && state.clickedAddCols) || 
                        (tile.id === 'WalletNFTs' && state.clickedAddNFTsTable) ||
                        (tile.id === 'AaveUserPositions' && state.clickedAddPositionsTable)
                    }
                    { ...tile }
                />
            </div>
        ))
    }, [state])

    return (
        <div className={cn(
            className, 
            state.showAddedCols ? `${className}--added-cols` : '',
            state.showNFTsTable ? `${className}--show-nfts` : '',
            state.showPositionsTable ? `${className}--show-positions` : '',
        )}>
            <Table
                table={tables.wallets}
                addCols={addWalletCols}
                liveObjectSpec={ensProfileSpec}
                ref={walletsTableRef}
            />
            <Table
                table={tables.empty}
            />
            <Table
                table={tables.nfts}
                liveObjectSpec={nftSpec}
                ref={nftsTableRef}
            />
            <Table
                table={tables.aaveUserPositions}
                liveObjectSpec={aaveUserPositionSpec}
                ref={positionsTableRef}
            />
            <div className={pcn('__features')}>
                { renderFeatures() }
            </div>
        </div>
    )    
}

export default SplashGraphic