import DesktopHeader from './DesktopHeader'

function Header() {
    return (
        <header>
            <DesktopHeader/>
        </header>
    )
}

export default Header
