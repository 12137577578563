import React, { useCallback, useState, useEffect, useRef } from 'react'
import { getPCN, cn } from '../../utils/classes'
import { isElementInView } from '../../utils/doc'
import Typewriter from 'typewriter-effect'
import $ from 'jquery'
import { s3 } from '../../utils/path'
import CountUp from 'react-countup'

const className = 'add-contracts-terminal'
const pcn = getPCN(className)

const id = 'addContractsTerminal'
const observerTargetId = 'addContractsTerminalObserver'

const getCommand = () => window.innerWidth > 600 
    ? "<span>spec <span class='accent'>add contracts</span> <span class='variable'>./gitcoin/allov2/deployments.json</span></span>"
    : "<span>spec <span class='accent'>index</span> <span class='variable'>./allov2/deployments.json</span></span>"

const eventRows = [
    [
        {
            nsp: 'allov2',
            contract: 'Registry',
            name: 'ProfileCreated',
            recordCount: 4920
        },
        {
            nsp: 'allov2',
            contract: 'Allo',
            name: 'RoleGranted',
            recordCount: 1093
        },
        {
            nsp: 'allov2',
            contract: 'Allo',
            name: 'PoolCreated',
            recordCount: 822
        },
        {
            nsp: 'allov2',
            contract: 'RFPSimple',
            name: 'MaxBidIncreased',
            recordCount: 323
        },
    ],
    [
        {
            nsp: 'allov2',
            contract: 'Allo',
            name: 'StrategyApproved',
            recordCount: 1293
        },
        {
            nsp: 'allov2',
            contract: 'Registry',
            name: 'ProfileNameUpdated',
            recordCount: 200
        },
        {
            nsp: 'allov2',
            contract: 'Transactions',
            name: 'Transactions',
            recordCount: 10023
        },
        {
            nsp: 'allov2',
            contract: 'Allo',
            name: 'PoolFunded',
            recordCount: 106
        },
    ],
    [
        {
            nsp: 'allov2',
            contract: 'RFPSimple',
            name: 'MilestonesSet',
            recordCount: 392
        },
        {
            nsp: 'allov2',
            contract: 'MerkleDistribution',
            name: 'Allocated',
            recordCount: 29
        },
        {
            nsp: 'allov2',
            contract: 'RFPCommitteeStrategy',
            name: 'Distributed',
            recordCount: 29
        },
        {
            nsp: 'allov2',
            contract: 'Allo',
            name: 'BaseFeePaid',
            recordCount: 823
        },
    ]
]

const timing = {
    initialDelay: 0,
    tickerTime: 2,
    loadingTime: 2000,
}

function AddContractsTerminal() {
    const typewriter = useRef(null)
    const didType = useRef(false)
    const [isLoading, setIsLoading] = useState(false)
    const [showEvents, setShowEvents] = useState(false)
    const eventsRef = useRef()

    const startTyping = useCallback(() => {
        if (!typewriter.current || didType.current) return
        didType.current = true

        typewriter.current
            .callFunction(() => {
                $(`#${id}`).addClass(`${className}--did-type`)
            })
            .typeString(getCommand())
            .callFunction(() => {
                typewriter.current.stop()
                $(`#${id} .Typewriter__cursor`).addClass('done')
                setIsLoading(true)
            })
            .start()
    }, [])

    const setTypeWriterRef = useCallback(ref => {
        typewriter.current = ref
    }, [])

    const createIntersectionObserver = useCallback(() => {
        const observer = new IntersectionObserver( entries => (
            entries && entries[0] && entries[0].isIntersecting && setTimeout(() => startTyping(), timing.initialDelay)
        ), { threshold: 0 } )

        const el = document.querySelector( `#${observerTargetId}` )
        el && observer.observe( el )
    }, [startTyping])

    useEffect(() => {
        if ( window.IntersectionObserver ) {
            setTimeout( () => {
                isElementInView( `#${observerTargetId}` )
                    ? startTyping()
                    : createIntersectionObserver()
            }, 50 )
        } else {
            setTimeout( () => startTyping(), 5 )
        }
    }, [createIntersectionObserver, startTyping])

    const renderTerminal = useCallback(() => {
        return (
            <div className={pcn('__body')}>
                <div className={pcn('__body-liner')}>
                    <div className={pcn('__input')}>
                        <span className={pcn('__line')}>
                            <span className='prefix'>$</span>
                            <span>
                                <Typewriter
                                    onInit={setTypeWriterRef}
                                    options={{ delay: 50 }}
                                />
                            </span>
                        </span>
                    </div>
                </div>
                { isLoading && (
                    <div className={pcn('__loading')}>
                        <div className='indeterminate'></div>
                    </div> 
                )}
            </div>
        )
    }, [isLoading])

    const renderEvent = useCallback((event, i) => (
        <div key={i} className={pcn('__event')}>
            <div className={pcn('__event-liner')}>
                <div className={pcn('__icon')}>
                    <img src={`${s3(event.nsp)}.jpg`}/>
                </div>
                <div className={pcn('__main')}>
                    <span>{event.name}</span>
                    <span>{event.nsp}.{event.contract}</span>
                </div>
                <div className={pcn('__metadata')}>
                    { showEvents && <CountUp 
                        className={pcn('__count')}
                        start={0}
                        end={event.recordCount}
                        delay={.1}
                        duration={timing.tickerTime}
                        useEasing={true}
                        easingFn={(t, b, c, d) => c*((t=t/d-1)*t*t*t*t + 1) + b}
                        separator=','
                    /> }
                    <div className={pcn('__chains')}>
                        <span className={pcn('__chain', '__chain--1')}><span>E</span></span>
                        <span className={pcn('__chain', '__chain--137')}><span>P</span></span>
                        <span className={pcn('__chain', '__chain--8453')}><span>B</span></span>
                        <span className={pcn('__chain', '__chain--plus')}><span>+6</span></span>
                    </div>
                </div>
            </div>
        </div>
    ), [showEvents])

    const renderEvents = useCallback(() => {
        return (
            <div className={pcn('__events', showEvents ? '__events--show' : '')} ref={eventsRef}>
                <div className={pcn('__events-liner')}>
                    { eventRows.map((row, i) => (
                        <div key={i} className={pcn('__events-row')}>
                            { row.map((event, j) => renderEvent(event, j)) }
                        </div>
                    ))}
                </div>
                <div className={pcn('__events-liner')}>
                    { eventRows.map((row, i) => (
                        <div key={i} className={pcn('__events-row')}>
                            { row.map((event, j) => renderEvent(event, j)) }
                        </div>
                    ))}
                </div>
            </div>
        )
    }, [showEvents, renderEvent])

    useEffect(() => {
        if (showEvents) {
            setTimeout(() => {
                $(eventsRef.current).addClass(pcn('__events--done'))
            }, timing.tickerTime * 1000 + 100)
        }
    }, [showEvents])

    useEffect(() => {
        if (isLoading) {
            setTimeout(() => {
                setShowEvents(true)
            }, timing.loadingTime - 350)

            setTimeout(() => {
                setIsLoading(false)
            }, timing.loadingTime)
        }
    }, [isLoading])

    return (
        <div className={cn(className, didType.current ? `${className}--did-type` : '')} id={id}>
            { renderTerminal() }
            <div id={observerTargetId}></div>
            { renderEvents() }
        </div>
    )
}

export default AddContractsTerminal