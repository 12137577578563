const optimism = `<svg viewBox="0 0 500 500">
    <g fill-rule="evenodd">
        <g fill-rule="nonzero">
            <circle id="Oval" fill="#D2D2E6" cx="250" cy="250" r="250"></circle>
            <path d="M177.1,316.4 C162.2,316.4 150,312.9 140.5,305.9 C131.1,298.8 126.4,288.6 126.4,275.5 C126.4,272.7 126.7,269.4 127.3,265.4 C128.9,256.4 131.2,245.6 134.2,232.9 C142.7,198.5 164.7,181.3 200.1,181.3 C209.7,181.3 218.4,182.9 226,186.2 C233.6,189.3 239.6,194.1 244,200.5 C248.4,206.8 250.6,214.3 250.6,223 C250.6,225.6 250.3,228.9 249.7,232.9 C247.8,244 245.6,254.9 242.9,265.4 C238.5,282.5 231,295.4 220.2,303.9 C209.5,312.3 195.1,316.4 177.1,316.4 Z M179.8,289.4 C186.8,289.4 192.7,287.3 197.6,283.2 C202.6,279.1 206.2,272.8 208.3,264.2 C211.2,252.4 213.4,242.2 214.9,233.4 C215.4,230.8 215.7,228.1 215.7,225.3 C215.7,213.9 209.8,208.2 197.9,208.2 C190.9,208.2 184.9,210.3 179.9,214.4 C175,218.5 171.5,224.8 169.4,233.4 C167.1,241.8 164.9,252 162.6,264.2 C162.1,266.7 161.8,269.3 161.8,272.1 C161.7,283.7 167.8,289.4 179.8,289.4 Z" fill="#0e0d24"></path>
            <path d="M259.3,314.6 C257.9,314.6 256.9,314.2 256.1,313.3 C255.5,312.3 255.3,311.2 255.5,309.9 L281.4,187.9 C281.6,186.5 282.3,185.4 283.5,184.5 C284.6,183.6 285.8,183.2 287.1,183.2 L337,183.2 C350.9,183.2 362,186.1 370.4,191.8 C378.9,197.6 383.2,205.9 383.2,216.8 C383.2,219.9 382.8,223.2 382.1,226.6 C379,241 372.7,251.6 363.1,258.5 C353.7,265.4 340.8,268.8 324.4,268.8 L299.1,268.8 L290.5,309.9 C290.2,311.3 289.6,312.4 288.4,313.3 C287.3,314.2 286.1,314.6 284.8,314.6 L259.3,314.6 Z M325.7,242.9 C331,242.9 335.5,241.5 339.4,238.6 C343.4,235.7 346,231.6 347.3,226.2 C347.7,224.1 347.9,222.2 347.9,220.6 C347.9,217 346.8,214.2 344.7,212.3 C342.6,210.3 338.9,209.3 333.8,209.3 L311.3,209.3 L304.2,242.9 L325.7,242.9 L325.7,242.9 Z" fill="#0e0d24"></path>
        </g>
    </g>
</svg>`

export default optimism