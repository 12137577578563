import React, { useEffect, useState } from 'react'
import { cn } from '../../utils/classes'

const className = 'ph'
const shineDelay = 0

function PH({ id }) {
    const [shine, setShine] = useState(false)

    useEffect(() => {
        shine || setTimeout(() => setShine(true), shineDelay)
    }, [shine])

    return (
        <div
            className={cn(
                className, 
                `${className}--${id}`,
                shine ? `${className}--shine` : '',
            )}>
        </div>
    )
}

export default PH