import { getPCN } from '../../../utils/classes'
import icons from '../../../svgs/icons'
import { links } from '../../../utils/nav'
import twitterIcon from '../../../svgs/svgjs/twitter'

function DesktopFooter() {
    const className = 'desktop-footer'
    const pcn = getPCN(className)

    return (
        <div className={className}>
            <div className={pcn('__liner')}>
                <div className={pcn('__brand-container')}>
                    <a
                        href='/'
                        className={pcn('__brand-icon')}
                        dangerouslySetInnerHTML={{ __html: icons['spec-icon'] }}>
                    </a>
                </div>
                <div className={pcn('__icon-links')}>
                    <a
                        className={pcn('__icon-link', '__icon-link--github')}
                        target='_blank'
                        rel="noreferrer"
                        href={ links.GITHUB_ORG }
                        dangerouslySetInnerHTML={{ __html: icons.github }}>
                    </a>
                    <a
                        className={pcn('__icon-link', '__icon-link--twitter')}
                        target='_blank'
                        rel="noreferrer"
                        href={ links.TWITTER }
                        dangerouslySetInnerHTML={{ __html: twitterIcon }}>
                    </a>
                    <a
                        className={pcn('__icon-link', '__icon-link--mail')}
                        target='_blank'
                        rel="noreferrer"
                        href={ links.TEAM_EMAIL }
                        dangerouslySetInnerHTML={{ __html: icons.letter }}>
                    </a>
                </div>
                <div className={pcn('__copyright-container')}>
                    <span className={pcn('__copyright')}>
                        <span>©</span><span>spec</span>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default DesktopFooter
