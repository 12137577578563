import React from 'react'
import { useTransition } from 'react-spring'
import { getPCN, cn } from '../../../utils/classes'
import packageIcon from '../../../svgs/svgjs/package'
import { noop } from '../../../utils/nodash'

const className = 'toolkit'
const pcn = getPCN(className)

function Toolkit(props) {
    const { index = 0, comps = [], onRest = noop } = props
    const transitions = useTransition(index, {
        initial: { opacity: 1, transform: 'translateY(0px)' },
        from: { opacity: 0, transform: 'translateY(45px)' },
        enter: { opacity: 1, transform: 'translateY(0px)' },
        leave: { opacity: 0, transform: 'translateY(-20px)' },
        config: { tension: 270, friction: 30, mass: 1 },
        onRest: onRest,
    })

    return (
        <div className={cn(className, index > 0 ? `${className}--show-side-icons` : '')}>
            <div className={pcn('__liner')}>
                <div className={pcn('__pkg')} dangerouslySetInnerHTML={{ __html: packageIcon }}></div>
                { transitions(({ opacity, transform, key }, item) => {
                    const Comp = comps[item]
                    return <Comp style={{ opacity, transform }} />
                })}
                <div className={pcn('__ts')}>TS</div>
            </div>
        </div>
    )
}

export default Toolkit
