const arbitrum = `<svg viewBox="0 0 357 400">
    <g fill-rule="evenodd">
        <g fill-rule="nonzero">
            <polygon id="Path" points="179.239 258.102002 234.413 344.701002 285.384 315.162002 212.92 200.947002"></polygon>
            <path d="M331.799,280.423002 L331.761,256.755002 L252.582,133.431002 L223.207,183.277002 L299.643,306.897002 L327.28,290.882002 C329.99,288.679002 331.631,285.449002 331.803,281.963002 L331.799,280.423002 Z" id="Path"></path>
            <path d="M0.000854492,302.743002 L39.0279,325.232002 L168.902,116.950002 L146.86,116.369002 C128.08,116.103002 107.849,120.986002 98.5637,136.143002 L24.8124,250.454002 L0.000854492,288.577002 L0.000854492,302.743002 Z" id="Path"></path>
            <polygon id="Path" points="243.86 116.950002 185.767 117.161002 54.3377 334.045002 100.277 360.495002 112.769 339.307002"></polygon>
            <path d="M356.279,116.651002 C355.793,104.502002 349.215,93.3798021 338.911,86.9048021 L194.491,3.85192214 C184.298,-1.28012786 171.566,-1.28671786 161.355,3.84862214 C160.148,4.45748214 20.9097,85.2101021 20.9097,85.2101021 C18.9826,86.1345021 17.1263,87.2352021 15.3805,88.4851021 C6.18252,95.0771021 0.575903,105.321002 0,116.576002 L0,288.576002 L24.8116,250.454002 L24.595,117.738002 C24.6238,117.253002 24.6848,116.775002 24.7713,116.304002 C25.3323,113.181002 27.0757,110.396002 29.7006,108.513002 C30.3556,108.044002 171.973,26.0708021 172.423,25.8459021 C175.751,24.1742021 179.982,24.1544021 183.314,25.7939021 L325.867,107.791002 C329.239,109.938002 331.353,113.580002 331.541,117.561002 L331.541,281.963002 C331.369,285.449002 329.99,288.679002 327.28,290.882002 L299.643,306.896002 L285.383,315.161002 L234.413,344.699002 L182.725,374.654002 C179.941,375.660002 176.685,375.603002 173.925,374.481002 L112.768,339.306002 L100.276,360.494002 L155.234,392.137002 C157.052,393.171002 158.671,394.087002 160,394.833002 C162.057,395.986002 163.459,396.758002 163.955,396.999002 C167.861,398.896002 173.481,400 178.546,400 C183.189,400 187.717,399.147002 192.002,397.469002 L342.136,310.522002 C350.753,303.846002 355.823,293.777002 356.279,282.869002 L356.279,116.651002 Z" id="Path"></path>
        </g>
    </g>
</svg>`

export default arbitrum