import { Redirect, Route, Switch } from 'react-router'
import { paths } from './utils/nav'
import HomePage from './components/home/HomePage'
import JoinWaitlistPage from './components/forms/JoinWaitlistPage'
import DashboardPage from './components/dashboard/DashboardPage'
import BlogPage from './components/blog/BlogPage'

const defaultRoutes = [
    {
        path: paths.HOME,
        component: BlogPage,
        exact: true,
    },
    {
        path: paths.JOIN_WAITLIST,
        component: JoinWaitlistPage,
        exact: true,
    },
    {
        path: paths.BLOG_PATTERN,
        component: BlogPage,
    },
    {
        path: paths.ECOSYSTEM_PATTERN,
        component: DashboardPage,
    },
]

const buildRouteSpec = () => ({
    routes: defaultRoutes,
    fallback: paths.HOME,
})

function App() {
    const { routes, fallback } = buildRouteSpec()
    return (
        <div id='app'>
            <Switch>
                { routes.map((props, i) => <Route key={i} {...props}/> )}
                <Redirect to={fallback} />
            </Switch>
        </div>
    )
}

export default App