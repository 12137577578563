import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getPCN, cn } from '../../utils/classes'
import CountUp from 'react-countup'
import Counter from './Counter';
import $ from 'jquery'

const className = 'mf-counter'
const pcn = getPCN(className)

function MultiFacetCounter({ value, duration }) {
    const hasTickedUp = useRef(false)
    const counterRef = useRef()
    const [_, setForceRender] = useState(false)
    const mainRef = useRef()

    useEffect(() => {
        counterRef.current?.flipTo(value)
    }, [value])

    const renderInnerComp = useCallback(() => {
        const hasValue = value !== null && value !== undefined

        // No value yet -- still fetching from backend.
        if (!hasValue) {
            return <div className={pcn('__ph')}>--</div>
        }

        // Ticker up to the given value.
        if (!hasTickedUp.current) {
            return (
                <CountUp 
                    className={pcn('__ticker')}
                    start={0}
                    end={value}
                    delay={0}
                    duration={duration}
                    useEasing={true}
                    easingFn={(t, b, c, d) => c*((t=t/d-1)*t*t*t*t + 1) + b}
                    separator=','
                    onEnd={() => {
                        hasTickedUp.current = true
                        $(mainRef.current).removeClass(`${className}--ticking`)
                        setTimeout(() => {
                            setForceRender(prevState => !prevState)
                        }, 1500)
                    }}
                />
            )
        }

        // Any *new* realtime updates should cause flip. 
        return (
            <Counter
                initialValue={value}
                formatter={value => value.toLocaleString('en-US')}
                highlightFlip={true}
                onTimer={false}
                ref={counterRef}
            />
        )
    }, [value])

    return (
        <div className={cn(className, value !== null && value !== undefined && !hasTickedUp.current ? `${className}--ticking` : '')} ref={mainRef}>
            { renderInnerComp() }
        </div>
    )
}

export default MultiFacetCounter
