import React, { useMemo, useRef, useCallback, forwardRef, useImperativeHandle } from 'react'
import { getPCN, cn } from '../../../utils/classes'
import { noop } from '../../../utils/nodash'
import { withIndefiniteArticle } from '../../../utils/formatters'
import EditableLiveColumns from './EditableLiveColumns'
import RequiredLinks from './RequiredLinks'

const className = 'new-live-column-specs'
const pcn = getPCN(className)

function NewLiveColumnSpecs(props, ref) {
    const { liveObjectSpec = {}, selectLiveColumnFormatter = noop } = props
    const name = useMemo(() => liveObjectSpec.name, [liveObjectSpec])
    const typeDef = useMemo(() => liveObjectSpec.typeDef, [liveObjectSpec])
    const requiredLinks = useMemo(() => typeDef?.properties?.filter(p => !!p.linkRequired), [typeDef])
    const editableLiveColumnsRef = useRef()

    useImperativeHandle(ref, () => ({
        serialize: () => editableLiveColumnsRef.current?.serialize() || {},
        selectDataSource: (...args) => editableLiveColumnsRef.current?.selectDataSource(...args),
        typeColumnName: (...args) => editableLiveColumnsRef.current?.typeColumnName(...args),
        setTransform: (...args) => editableLiveColumnsRef.current?.setTransform(...args),
    }))

    const renderProperties = useCallback(() => typeDef.properties.map((p, i) => (
        <div key={i} className={pcn('__doc-property')}>
            <div><span>{p.name}</span><span>{p.type}</span></div>
            <div>{p.desc}</div>
        </div>
    )), [typeDef])

    if (!name || !typeDef) {
        return <div className={className}></div>
    }

    return (
        <div className={className}>
            <div className={pcn('__type-overview')}>
                <div className={pcn('__docs')}>
                    <div className={pcn('__doc-object-name')}>
                        <span>{ typeDef.name }</span><span>&mdash;</span><span>Live Object</span>
                    </div>
                    <div className={pcn('__doc-properties')}>
                        { renderProperties() }
                    </div>
                </div>
                <div className={pcn('__interface')}>
                    <div className={pcn('__interface-header')}>
                        <span>TS</span><span>Type Definition</span>
                    </div>
                    <div
                        className={pcn('__interface-body')}
                        dangerouslySetInnerHTML={{ __html: typeDef.code }}>
                    </div>
                </div>
            </div>
            <div className={pcn('__cols')}>
                <div className={pcn('__cols-section-title', '__cols-section-title--pad-left')}>
                    <span className='blink-indicator'><span></span></span>
                    { window.innerWidth <= 580 ? 'New Live Column' : 'New Live Columns' }
                </div>
                <div className={pcn('__new-cols')}>
                    <EditableLiveColumns
                        liveObjectSpec={liveObjectSpec}
                        selectLiveColumnFormatter={selectLiveColumnFormatter}
                        ref={editableLiveColumnsRef}
                    />
                </div>
            </div>
            { requiredLinks?.length > 0 &&
                <div className={pcn('__rel')}>
                    <div className={pcn('__rel-section-title')}>
                        Connect The Dots
                    </div>
                    <div className={pcn('__rel-section-subtitle')}>
                        How does a <span>{ typeDef.name }</span> instance know which records it corresponds to?
                    </div>
                    <div className={pcn('__rel-inputs')}>
                        <RequiredLinks
                            liveObjectSpec={liveObjectSpec}
                            properties={requiredLinks}
                        />
                    </div>
                </div>
            }
        </div>
    )
}

NewLiveColumnSpecs = forwardRef(NewLiveColumnSpecs)
export default NewLiveColumnSpecs