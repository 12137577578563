import { cn, getPCN } from '../../utils/classes'
import icons from '../../svgs/icons'
import { noop } from '../../utils/nodash'

function ActionButton(props) {
    const className = 'action-button'
    const pcn = getPCN(className)
    const { 
        text, 
        icon, 
        withBorder = false, 
        fill = false,
        ghost = false,
        onClick = noop
    } = props

    return (
        <button
            className={cn(
                className, 
                props.className, 
                withBorder ? '--with-border' : '',
                fill ? '--fill' : '',
                ghost ? '--ghost' : '',
            )}
            onClick={onClick}>
            <span className={pcn('__text')}>{text}</span>
            { icon &&
                <span
                    className={pcn('__icon', `__icon--${icon}`)}
                    dangerouslySetInnerHTML={{ __html: icons[icon] }}>
                </span>
            }
        </button>
    )
}

export default ActionButton
