import React, { useState, useCallback, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { getPCN } from '../../utils/classes'
import { noMod } from '../../utils/formatters'
import { getRandom } from '../../utils/math'

const modifiers = {
    initial: 'initial',
    up: 'up',
    waitDown: 'wait-down',
}

const timing = {
    duration: 240,
}

function Counter(props, ref) {
    const {
        formatter = noMod, 
        incrementAmount = 1, 
        updateRange = [5, 20],
        onlyOnce = false,
        highlightFlip = false,
        onTimer = true
    } = props
    const className = 'counter'
    const pcn = getPCN(className)
    const [value, setValue] = useState(props.initialValue || 0)
    const [animationModifier, setAnimationModifier] = useState(modifiers.initial)
    const started = useRef(false)
    const timer = useRef(null)

    useImperativeHandle(ref, () => ({
        flipTo: newValue => {
            if (value === newValue) return
            setTimeout(() => setAnimationModifier(modifiers.up), 0)
            setTimeout(() => setValue(newValue), timing.duration / 2)
            setTimeout(() => setAnimationModifier(highlightFlip ? `${modifiers.waitDown} flipped` : modifiers.waitDown), timing.duration / 2)
            setTimeout(() => setAnimationModifier(highlightFlip ? `${modifiers.initial} flipped flipped-done` : modifiers.initial), timing.duration * 0.6)    
        },
    }), [value])

    const increment = useCallback(() => {
        setTimeout(() => setAnimationModifier(modifiers.up), 0)
        setTimeout(() => setValue(prevState => {
            if (typeof incrementAmount === 'function') {
                return prevState + incrementAmount(prevState)
            } else {
                return prevState + incrementAmount
            }
        }), timing.duration / 2)
        setTimeout(() => setAnimationModifier(highlightFlip ? `${modifiers.waitDown} flipped` : modifiers.waitDown), timing.duration / 2)
        setTimeout(() => setAnimationModifier(highlightFlip ? `${modifiers.initial} flipped flipped-done` : modifiers.initial), timing.duration * 0.6)
    }, [incrementAmount])

    const incrementAfterDelay = useCallback(() => {
        const randomDelay = Math.round(getRandom(...updateRange) * 1000)
        timer.current && clearTimeout(timer.current)
        timer.current = setTimeout(() => {
            increment()
            !onlyOnce && incrementAfterDelay()
        }, randomDelay)
    }, [increment, onlyOnce])

    useEffect(() => {
        if (started.current || !onTimer) return
        started.current = true
        incrementAfterDelay()
    }, [incrementAfterDelay, onTimer])

    return (
        <div className={className}>
            <div className={pcn('__liner')}>
                <span className={animationModifier}>{formatter(value)}</span>
            </div>
        </div>
    )
}

Counter = forwardRef(Counter)
export default Counter
