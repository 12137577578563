const dashedbox = `<svg viewBox="0 0 230 162">
    <defs>
        <rect id="mask-db-1" x="0" y="0" width="230" height="162" rx="8"></rect>
        <mask id="mask-db" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0" width="230" height="162" fill="white">
            <use xlink:href="#mask-db-1"></use>
        </mask>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-dasharray="4" stroke-opacity="0.16">
        <use id="Rectangle" stroke="#6874FF" mask="url(#mask-db)" stroke-width="2" xlink:href="#mask-db-1"></use>
    </g>
</svg>`
export default dashedbox