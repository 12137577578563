import React, { useCallback, useRef, forwardRef, useImperativeHandle } from 'react'
import { getPCN, cn } from '../../../utils/classes'
import LiveTableSearch from './LiveTableSearch'
import { noop } from '../../../utils/nodash'

const className = 'new-live-table-panel'
const pcn = getPCN(className)

function NewLiveTablePanel(props, ref) {
    const { onSelectLiveTable = noop } = props
    const liveTableSearchRef = useRef()

    useImperativeHandle(ref, () => ({
        search: (...args) => liveTableSearchRef.current?.search(...args),
        delete: (...args) => liveTableSearchRef.current?.delete(...args),
        addTop: () => liveTableSearchRef.current?.addTop(),
    }))

    const renderHeader = useCallback(() => (
        <div className={pcn('__header')}>
            <div className={pcn('__header-liner')}>
                <div className={pcn('__header-title')}>
                    <span>New Live Table</span>
                </div>
            </div>
        </div>
    ), [])

    return (
        <div className={className}>
            { renderHeader() }
            <div
                className={pcn('__section', '__section--0')}>
                <LiveTableSearch
                    onSelectLiveTable={onSelectLiveTable}
                    ref={liveTableSearchRef}
                />
            </div>
        </div>
    )
}

NewLiveTablePanel = forwardRef(NewLiveTablePanel)
export default NewLiveTablePanel