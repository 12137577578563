const eth = `<svg viewBox="0 0 100 163">
    <g fill-rule="evenodd">
        <g fill-rule="nonzero">
            <polygon id="Path" fill-opacity="0.92" points="49.9853386 0 48.8927419 3.71125872 48.8927419 111.393857 49.9853386 112.483904 99.9694022 82.9378992"></polygon>
            <polygon id="Path" fill-opacity="0.87" points="49.9853386 0 0 82.9378992 49.9853386 112.483904 49.9853386 60.2177544"></polygon>
            <polygon id="Path" fill-opacity="0.92" points="49.9853386 121.94755 49.3695577 122.698471 49.3695577 161.056644 49.9853386 162.854265 100 92.4168441"></polygon>
            <polygon id="Path" fill-opacity="0.84" points="49.9853386 162.854265 49.9853386 121.94755 0 92.4168441"></polygon>
            <polygon id="Path" points="49.9853386 112.483904 99.9694022 82.9378992 49.9853386 60.2177544"></polygon>
            <polygon id="Path" fill-opacity="0.67" points="0 82.9378992 49.9853386 112.483904 49.9853386 60.2177544"></polygon>
        </g>
    </g>
</svg>`

export default eth