import ensProfileInterface from './ens-profile'
import compoundAccountHealthInterface from './compound-health'
import nftInterface from './nft'

const ensProfileSpec = {
	name: 'ENS Profile',
	typeDef: {
		name: 'ENSProfile',
		properties: [
			{
				name: 'address',
				type: 'string',
				desc: 'The address of the ENS domain\'s owner.',
                linkRequired: true,
			},
			{
				name: 'domain',
				type: 'string',
				desc: 'The ENS domain (i.e. vitalik.eth).'
			},
			{
				name: 'textRecords',
				type: 'hash',
				desc: 'Text records associated with the domain.'
			},
		],
		code: ensProfileInterface,
	},
}

const nftSpec = {
	name: 'NFT',
	typeDef: {
		name: 'NFT',
		properties: [
			{
				name: 'collection',
				type: 'string',
				desc: 'The name of the NFT collection.'
			},
			{
				name: 'ownerAddress',
				type: 'string',
				desc: 'The address of the NFT\'s owner.',
                linkRequired: true,
			},
			{
				name: 'contractAddress',
				type: 'string',
				desc: 'The address of the NFT\'s contract.',
                linkRequired: true,
			},
			{
				name: 'tokenId',
				type: 'number',
				desc: 'The NFT\'s id.',
                linkRequired: true,
			},
			{
				name: 'tokenURI',
				type: 'string',
				desc: 'The URI hosting the NFT\'s metadata.'
			},
			{
				name: 'chain',
				type: 'string',
				desc: 'The blockchain associated with the NFT.'
			},
			{
				name: 'standard',
				type: 'string',
				desc: 'The standard of the NFT (ERC-721 or ERC-1155).'
			},
			{
				name: 'metadata',
				type: 'hash',
				desc: 'The NFT\'s metadata object.'
			},
		],
		code: nftInterface,
	},
}

const aaveUserPositionSpec = {
	name: 'Aave Position',
	typeDef: {
		name: 'AaveUserPosition',
		properties: [
			{
				name: 'address',
				type: 'string',
				desc: 'The address of the Aave user.',
                linkRequired: true,
			},
			{
				name: 'asset',
				type: 'string',
				desc: 'The name of the Aave asset.',
				linkRequired: true,
			},
			{
				name: 'deposited',
				type: 'number',
				desc: 'The user\'s current aToken balance (in ETH)',
			},
			{
				name: 'stableDebt',
				type: 'number',
				desc: 'The user\'s current stable debt (in ETH)',
			},
			{
				name: 'variableDebt',
				type: 'number',
				desc: 'The user\'s current variable debt (in ETH)',
			},
		],
		code: null,
	},
}

const compoundUserHealthSpec = {
	name: 'Compound Account Health',
	typeDef: {
		name: 'CompoundAccountHealth',
		properties: [
			{
				name: 'address',
				type: 'string',
				desc: 'The address of the account.',
                linkRequired: true,
			},
			{
				name: 'totalCollateral',
				type: 'number',
				desc: 'All collateral supplied by the account (in ETH).',
			},
			{
				name: 'totalBorrowed',
				type: 'number',
				desc: 'All outstanding borrows with interest (in ETH).',
			},
			{
				name: 'healthFactor',
				type: 'number',
				desc: 'The ratio of totalCollateral to totalBorrowed.',
			},
		],
		code: compoundAccountHealthInterface,
	},
}

const tokenBalanceSpec = {
	name: 'Token Balance',
	typeDef: {
		name: 'TokenBalance',
		properties: [
			{
				name: 'token',
				type: 'string',
				desc: '',
				linkRequired: true,
			},
			{
				name: 'owner',
				type: 'string',
				desc: '',
				linkRequired: true,
			},
			{
				name: 'balance',
				type: 'string',
				desc: '',
			},
			{
				name: 'chainId',
				type: 'number',
				desc: '',
                linkRequired: true,
			},
			{
				name: 'updatedAt',
				type: '',
				desc: ''
			},
		],
		code: nftInterface,
	},
}

const roleGrantedSpec = {
	name: 'Role Granted',
	typeDef: {
		name: 'RoleGranted',
		properties: [
			{
				name: 'role',
				type: 'string',
				desc: '',
			},
			{
				name: 'account',
				type: 'string',
				desc: '',
			},
			{
				name: 'sender',
				type: 'string',
				desc: '',
			},
			{
				name: 'chainId',
				type: 'number',
				desc: '',
			},
			{
				name: 'updatedAt',
				type: '',
				desc: ''
			},
		],
		code: nftInterface, // doesn't matter
	},
}

const lidoHolderSpec = {
	name: 'Holder',
	typeDef: {
		name: 'Holder',
		properties: [
			{
				name: 'address',
				type: 'string',
				desc: '',
			},
			{
				name: 'hasBalance',
				type: 'boolean',
				desc: '',
			},
			{
				name: 'chainId',
				type: 'number',
				desc: '',
			},
			{
				name: 'updatedAt',
				type: 'timestamptz',
				desc: ''
			},
		],
		code: nftInterface, // doesn't matter
	},
}

const specs = {
	[ensProfileSpec.typeDef.name]: ensProfileSpec,
	[nftSpec.typeDef.name]: nftSpec,
	[aaveUserPositionSpec.typeDef.name]: aaveUserPositionSpec,
	[tokenBalanceSpec.typeDef.name]: tokenBalanceSpec,
	[roleGrantedSpec.typeDef.name]: roleGrantedSpec,
	[lidoHolderSpec.typeDef.name]: lidoHolderSpec,
}

export {
	specs,
	nftSpec,
	ensProfileSpec,
	aaveUserPositionSpec,
	compoundUserHealthSpec,
	tokenBalanceSpec,
	roleGrantedSpec,
	lidoHolderSpec,
}