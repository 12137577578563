import React, { useState, useCallback, useRef } from 'react'
import Header from '../shared/header/Header'
import { getPCN, cn } from '../../utils/classes'
import { noop } from '../../utils/nodash'
import spinner from '../../svgs/svgjs/chasing-tail-spinner'
import checkIcon from '../../svgs/svgjs/bens-check'
import TextInput from '../shared/inputs/TextInput'
import supabase from '../../utils/supabase'
import { isEmail } from '../../utils/validators'
import { links } from '../../utils/nav'
import discordIcon from '../../svgs/svgjs/discord'
import { waitlistJoined } from '../../utils/segment'

const className = 'form'
const pcn = getPCN(className)

const statuses = {
    DEFAULT: 'default',
    SUBMITTING: 'submitting',
    SUCCESS: 'success'
}

const timing = {
    minSubmissionTime: 1000,
}

function JoinWaitlistPage(props) {
    const [status, setStatus] = useState(statuses.DEFAULT)
    const [data, setData] = useState({})
    const emailRef = useRef()
    const useCaseRef = useRef()
    const successTimer = useRef(null)
    const requestSent = useRef(false)

    const submit = useCallback(async () => {
        requestSent.current = false
        successTimer.current = setTimeout(() => {
            if (requestSent.current) {
                setStatus(statuses.SUCCESS)
                setData({})    
            } else {
                clearTimeout(successTimer.current)
                successTimer.current = null
            }
        }, timing.minSubmissionTime)

        const { error } = await supabase
            .from('waitlist_users')
            .insert({ 
                email: data.email, 
                use_case: data.useCase,
            }, { returning: 'minimal' })

        if (error) {
            console.error(error)
            return
        }

        // analytics
        waitlistJoined()

        requestSent.current = true

        if (successTimer.current !== null) {
            return
        }   

        setStatus(statuses.SUCCESS)
        setData({})
    }, [data])

    const onClickSubmit = useCallback(() => {
        const emailIsValid = emailRef.current?.validate()
        const useCaseIsValid = useCaseRef.current?.validate()
        if (!emailIsValid || !useCaseIsValid) {
            return
        }

        setStatus(statuses.SUBMITTING)
        submit()
    }, [submit])

    const renderButtonIcon = useCallback(() => {
        switch (status) {
            case statuses.SUBMITTING:
                return (
                    <span
                        className={pcn('__button-icon', '__button-icon--spinner')}
                        dangerouslySetInnerHTML={{ __html: spinner }}>
                    </span>
                )
            case statuses.SUCCESS:
                return (
                    <span
                        className={pcn('__button-icon', '__button-icon--check')}
                        dangerouslySetInnerHTML={{ __html: checkIcon }}>
                    </span>
                )
            default:
                return null
            }
    }, [status])

    const getButtonText = useCallback(() => {
        switch (status) {
            case statuses.SUBMITTING:
                return 'Submitting...'
            case statuses.SUCCESS:
                return 'Got it!'
            default:
                return 'Submit'
            }
    }, [status])

    return (
        <div className={cn(className, `${className}--join-waitlist`, `${className}--${status}`)}>
            <Header/>
            <div className={pcn('__liner')}>
                <div className={pcn('__title')}>
                    Request early access to Spec
                </div>
                <div className={pcn('__comps')}>
                    <TextInput
                        className={pcn('__input-field', '__input-field--email')}
                        value={data.email || ''}
                        placeholder='Email address'
                        isRequired={true}
                        updateFromAbove={true}
                        validator={isEmail}
                        spellCheck={false}
                        optionals={{ tabIndex: 1 }}
                        onChange={value => setData(prevState => ({ ...prevState, email: value }))}
                        ref={emailRef}
                    />
                    <TextInput
                        className={pcn('__input-field', '__input-field--use-case')}
                        value={data.useCase || ''}
                        placeholder='What data are you most interested in?'
                        isRequired={true}
                        updateFromAbove={true}
                        spellCheck={false}
                        optionals={{ tabIndex: 2 }}
                        onEnter={status === statuses.DEFAULT ? onClickSubmit : noop}
                        onChange={value => setData(prevState => ({ ...prevState, useCase: value }))}
                        ref={useCaseRef}
                    />
                </div>
                <button
                    className={pcn('__button')}
                    onClick={status === statuses.DEFAULT ? onClickSubmit : noop}
                    tabIndex={3}>
                    <span className={pcn('__button-text')}>{getButtonText()}</span>
                    { renderButtonIcon() }
                </button>
                <div id='earlyAccessEcosystemButton'>
                    <a
                        target='_blank'
                        tabIndex='-1'
                        rel='noreferrer'
                        href={ links.DISCORD }>
                        <span dangerouslySetInnerHTML={{ __html: discordIcon }}></span>
                        <span>Join the dev community</span>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default JoinWaitlistPage