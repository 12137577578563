import { s3 } from '../../../utils/path'
import IntroducingSpec from './IntroducingSpec'

export const authors = {
    BEN_WHITTLE: {
        name: 'Ben Whittle',
        title: 'Founder & CEO',
        link: 'https://github.com/whittlbc', 
        avatar: s3('avatars/ben-whittle.jpg'),
    }
}

export const labels = {
    BETA: 'beta',
    EARLY_ACCESS: 'early-access',
}

export const postIds = {
    INTRODUCING_SPEC: 'introducing-spec',
}

export const titles = {
    INTRODUCTION: 'Introduction',
    POSTGRES_TO_THE_RESCUE: 'Postgres to the rescue?',
    A_BRIGHTER_FUTURE: 'Seamless multi-chain indexing',
    LIVE_TABLES: 'Live multi-chain Postgres tables',
    AUTO_POPULATING: 'Auto-populating',
    AUTO_UPDATING: 'Auto-updating',
    LIVE_RELATIONSHIPS: 'Live relationships',
    LIVE_OBJECTS: 'Subgraphs, farewell.',
    INDEXING: 'Indexing protocol',
    PUBLISHING: 'Publishing',
    NAMESPACES: 'Namespaces',
    ECOSYSTEM: 'Ecosystem',
    CONTRACT_GROUPS: 'Contract Groups',
    SEAMLESS_ONBOARDING: 'Seamless onboarding',
    SUBSCRIPTION: 'Multi-chain events',
    EARLY_ACCESS: 'Early access',
}

export const posts = {
    [postIds.INTRODUCING_SPEC]: {
        title: 'Introducing Spec',
        subtitle: 'Instant Postgres tables for multi-chain apps & protocols.',
        date: '10.26.2023',
        authors: [authors.BEN_WHITTLE],
        labels: [labels.BETA, labels.EARLY_ACCESS],
        toc: [
            { 
                title: titles.INTRODUCTION 
            },
            { 
                title: titles.POSTGRES_TO_THE_RESCUE 
            },
            { 
                title: titles.A_BRIGHTER_FUTURE,
            },
            { 
                title: titles.LIVE_TABLES, 
            },
            { 
                title: titles.LIVE_OBJECTS,
            },
            {
                title: titles.EARLY_ACCESS,
            }
        ],
        render: (props) => <IntroducingSpec {...props}/>
    },
}

export const fallbackPostId = postIds.INTRODUCING_SPEC