import React, { useState, useEffect } from 'react'
import { getPCN, cn } from '../../../utils/classes'
import { s3 } from '../../../utils/path'
import heartIcon from '../../../svgs/svgjs/heart'
import caretIcon from '../../../svgs/svgjs/caret-down'
import { noop } from '../../../utils/nodash'
import icons from '../../../svgs/icons'

const className = 'live-object-search-result'
const pcn = getPCN(className)

function LiveObjectSearchResult(props) {
    const { canAnimate = false, name, icon, desc, likes, author, onClick = noop } = props
    const [added, _] = useState(props.added || false)
    const [animateToAdded, setAnimateToAdded] = useState(false)

    useEffect(() => {
        if (props.added === true && !added) {
            setAnimateToAdded(true)
        }
    }, [props.added, added])

    return (
        <div
            className={cn(
                className, 
                canAnimate ? `${className}--can-animate` : '',
                animateToAdded ? `${className}--animate` : '',
            )}>
            <div className={pcn('__liner')}>
                <div className={pcn('__left')}>
                    <img
                        className={pcn('__icon')}
                        src={s3(icon)}
                        alt=""
                    />
                    <div className={pcn('__main')}>
                        <div className={pcn('__name')}>
                            { name }
                        </div>
                        <div className={pcn('__desc')}>
                            <span>{ desc }</span>
                        </div>
                    </div>
                </div>
                <div className={pcn('__right')}>
                    <div className={pcn('__author')}>
                        { author }
                    </div>
                    <div className={pcn('__likes')}>
                        <span>{ likes }</span>
                        <span dangerouslySetInnerHTML={{ __html: heartIcon }}>
                        </span>
                    </div>
                </div>
                <div className={pcn('__fake-buttons')}>
                    <span className={pcn(
                        '__fake-button', 
                        '__fake-button--add',
                        props.added ? '__fake-button--added' : '',
                        animateToAdded ? '__fake-button--animate' : '',
                    )}>
                        <span>+</span>
                        <span>Add</span>
                        <div dangerouslySetInnerHTML={{ __html: icons.check }}>
                        </div>
                    </span>
                    <span className={pcn('__fake-button', '__fake-button--customize')}>
                        <span>Customize</span>
                    </span>
                    <span className={pcn(
                        '__fake-button', 
                        '__fake-button--arrow')}>
                        <span dangerouslySetInnerHTML={{ __html: caretIcon }}></span>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default LiveObjectSearchResult