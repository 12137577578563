const gql = `<svg viewBox="0 0 30 33.7082391">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g fill="currentColor" fill-rule="nonzero">
            <polygon id="Rectangle" transform="translate(9.0051, 13.3955) rotate(-149.9993) translate(-9.0051, -13.3955)" points="8.29063002 -0.38962254 9.71949738 -0.38962254 9.71949738 27.1806314 8.29063002 27.1806314"></polygon>
            <rect id="Rectangle" x="1.21026582" y="23.0689685" width="27.5708605" height="1.4288988"></rect>
            <polygon id="Rectangle" transform="translate(9.0083, 27.2341) rotate(-149.9993) translate(-9.0083, -27.2341)" points="1.04628118 26.5196802 16.9704054 26.5196802 16.9704054 27.9485475 1.04628118 27.9485475"></polygon>
            <polygon id="Rectangle" transform="translate(20.9882, 6.4825) rotate(-149.9993) translate(-20.9882, -6.4825)" points="13.0261687 5.76802395 28.9502929 5.76802395 28.9502929 7.19689131 13.0261687 7.19689131"></polygon>
            <polygon id="Rectangle" transform="translate(9.0122, 6.477) rotate(-120.0007) translate(-9.0122, -6.477)" points="8.29773148 -1.48504462 9.72659884 -1.48504462 9.72659884 14.4390796 8.29773148 14.4390796"></polygon>
            <polygon id="Rectangle" transform="translate(20.998, 13.3958) rotate(-120.0007) translate(-20.998, -13.3958)" points="7.21290813 12.681329 34.7831621 12.681329 34.7831621 14.1101964 7.21290813 14.1101964"></polygon>
            <rect id="Rectangle" x="2.30345948" y="8.89188228" width="1.4288988" height="15.9244746"></rect>
            <rect id="Rectangle" x="26.2676417" y="8.89188228" width="1.4288988" height="15.9244746"></rect>
            <polygon id="Rectangle" transform="translate(20.9882, 27.2262) rotate(-120.0007) translate(-20.9882, -27.2262)" points="20.364117 20.3013267 21.6122241 20.3013267 21.6122241 34.151011 20.364117 34.151011"></polygon>
            <path d="M29.5902618,25.2811793 C28.7639107,26.718686 26.9218364,27.2093319 25.4843297,26.3829808 C24.0468231,25.5566297 23.5561771,23.7145553 24.3825282,22.2770487 C25.2088794,20.8395421 27.0509537,20.3488961 28.4884603,21.1752472 C29.9345748,22.0102062 30.4252208,23.8436727 29.5902618,25.2811793" id="Path"></path>
            <path d="M5.60886393,11.4311904 C4.78251281,12.868697 2.94043846,13.359343 1.50293184,12.5329919 C0.0654252174,11.7066408 -0.425220755,9.8645664 0.401130357,8.42705978 C1.22748147,6.98955315 3.06955582,6.49890718 4.50706244,7.32525829 C5.94456907,8.16021723 6.43521504,9.99368376 5.60886393,11.4311904" id="Path"></path>
            <path d="M0.409738181,25.2811793 C-0.416612931,23.8436727 0.0740330415,22.0102062 1.51153966,21.1752472 C2.94904628,20.3488961 4.78251281,20.8395421 5.61747175,22.2770487 C6.44382286,23.7145553 5.95317689,25.5480219 4.51567027,26.3829808 C3.06955582,27.2093319 1.23608929,26.718686 0.409738181,25.2811793" id="Path"></path>
            <path d="M24.3911361,11.4311904 C23.564785,9.99368376 24.0554309,8.16021723 25.4929376,7.32525829 C26.9304442,6.49890718 28.7639107,6.98955315 29.5988696,8.42705978 C30.4252208,9.8645664 29.9345748,11.6980329 28.4970682,12.5329919 C27.0595615,13.359343 25.2174872,12.868697 24.3911361,11.4311904" id="Path"></path>
            <path d="M15,33.7082391 C13.33869,33.7082391 11.9958694,32.3654185 11.9958694,30.7041085 C11.9958694,29.0427985 13.33869,27.6999779 15,27.6999779 C16.66131,27.6999779 18.0041306,29.0427985 18.0041306,30.7041085 C18.0041306,32.3568107 16.66131,33.7082391 15,33.7082391" id="Path"></path>
            <path d="M15,6.00826121 C13.33869,6.00826121 11.9958694,4.66544065 11.9958694,3.0041306 C11.9958694,1.34282056 13.33869,0 15,0 C16.66131,0 18.0041306,1.34282056 18.0041306,3.0041306 C18.0041306,4.66544065 16.66131,6.00826121 15,6.00826121" id="Path"></path>
        </g>
    </g>
</svg>`
export default gql