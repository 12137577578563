import React, { useState, useRef, useCallback, forwardRef, useImperativeHandle } from 'react'
import { getPCN } from '../../../utils/classes'
import $ from 'jquery'
import searchIcon from '../../../svgs/svgjs/search'
import LiveObjectSearchResult from './LiveObjectSearchResult'
import { noop } from '../../../utils/nodash'
import Typewriter from 'typewriter-effect'

const className = 'live-table-search'
const pcn = getPCN(className)

const results = [
    {
        id: 'AaveUserPositions',
        name: 'Aave User Positions',
        icon: 'aave.jpg',
        desc: 'Current user asset positions on Aave V3.',
        likes: '1.4k',
        author: '@aave',
    },
    {
        id: 'UniswapTradingPairs',
        name: 'Uniswap Trading Pairs',
        icon: 'uniswap.jpg',
        desc: 'Current UniswapV3 trading pairs with spot prices.',
        likes: '2.4k',
        author: '@paul',
    },
    {
        id: 'WalletNFTs',
        name: 'Wallet NFTs',
        icon: 'user-nfts.jpg',
        desc: 'NFTs owned by wallet.',
        likes: '5.1k',
        author: '@spec',
    },
    {
        id: 'MakerDAOVoters',
        name: 'MakerDAO Voters',
        icon: 'makerdao.jpg',
        desc: 'Wallets that have voted on a MakerDAO poll.',
        likes: '1.2k',
        author: '@makerdao',
    },
    {
        id: 'WalletERC20s',
        name: 'ERC-20 Token Holdings',
        icon: 'ethereum.jpg',
        desc: 'ERC-20 tokens owned by wallet.',
        likes: '4.2k',
        author: '@spec',
    },
    {
        id: 'CurvePoolBalances',
        name: 'Curve Pool Balances',
        icon: 'curve.jpg',
        desc: 'The current balances for all token pools on Curve.',
        likes: '2.0k',
        author: '@curve.fi',
    },
    {
        id: 'NFTSalesHistory',
        name: 'NFT Sales History',
        icon: 'get-nft-sales-history.jpg',
        desc: 'NFT sales and price history.',
        likes: '3.1k',
        author: '@spec',
    },
]

function LiveTableSearch(props, ref) {
    const { onSelectLiveTable = noop } = props
    const searchRef = useRef()
    const searchPlaceholderRef = useRef()
    const [searchResults, setSearchResults] = useState(results)
    const typewriterRef = useRef()
    const [added, setAdded] = useState([])

    const onTypeQuery = useCallback(value => {
        setSearchResults(results.filter(r => 
            r.name.toLowerCase().includes(value) || r.desc.toLowerCase().includes(value)
        ))
    }, [])

    const addTopResult = useCallback(() => {
        const cursor = $(searchRef.current).find('.Typewriter__cursor')[0]
        if (cursor) {
            cursor.style.visibility = 'hidden'
        }
        setAdded(prevState => [ ...prevState, searchResults[0].id ])
    }, [searchResults])

    const searchQuery = useCallback((query) => {
        let result = typewriterRef.current
        let val = ''

        for (let c of query) {
            val += c
            const value = val
            result = result.typeString(c)
            result = result.callFunction(() => onTypeQuery(value))
        }

        if (searchPlaceholderRef.current) {
            setTimeout(() => {
                searchPlaceholderRef.current.style.opacity = 0
            }, 5)
        }

        result.start()
    }, [])

    const deleteQuery = useCallback((query) => {
        let result = typewriterRef.current

        for (let i = 0; i < query.length; i++) {
            const val = query.substr(0, query.length - i)
            result = result.deleteChars(1)
            result = result.callFunction(() => onTypeQuery(val))
        }

        if (searchPlaceholderRef.current) {
            result = result.callFunction(() => {
                searchPlaceholderRef.current.style.opacity = 1
            })
        }

        const cursor = $(searchRef.current).find('.Typewriter__cursor')[0]
        if (cursor) {
            cursor.style.visibility = 'visible'
        }

        result.start()
    }, [])

    useImperativeHandle(ref, () => ({
        search: searchQuery,
        delete: deleteQuery,
        addTop: addTopResult,
    }))

    const renderResults = useCallback(() => searchResults.map((result, i) => (
        <LiveObjectSearchResult 
            key={result.id} 
            added={added.includes(result.id)}
            {...result} 
        />
    )), [searchResults, onSelectLiveTable, added])

    const onInitTypewriter = useCallback(typewriter => {
        typewriterRef.current = typewriter
    }, [])

    return (
        <div className={className}>
            <div className={pcn('__search')} ref={searchRef}>
                <div className={pcn('__search-liner')}>
                    <span dangerouslySetInnerHTML={{ __html: searchIcon }}></span>
                    <span className={pcn('__search-text')}>
                        <span className={pcn('__search-ph')} ref={searchPlaceholderRef}>
                            Search Live Table specs...
                        </span>
                        <Typewriter
                            onInit={onInitTypewriter}
                            options={{ delay: 40, deleteSpeed: 40 }}
                        />
                    </span>
                </div>
            </div>
            <div className={pcn('__filters')}>
                <div className={pcn('__filters-liner')}>
                    <div className={pcn('__categories')}>
                        <span>All</span>
                        <span>Identity</span>
                        <span>NFT</span>
                        <span>DeFi</span>
                        <span>Governance</span>
                    </div>
                </div>
            </div>
            <div className={pcn('__results')}>
                <div className={pcn('__results-liner')}>
                    { renderResults() }
                </div>
            </div>
        </div>
    )
}

LiveTableSearch = forwardRef(LiveTableSearch)
export default LiveTableSearch