const ensProfile = `<div class="interface">
    <span class="line"><span class="keyword">interface</span><span class="type"> ENSProfile </span><span class="default">{</span></span><br/>
    <span class="line"><span class="tab"></span><span class="default">address: </span><span class="type">string</span></span><br/>
    <span class="line"><span class="tab"></span><span class="default">domain: </span><span class="type">string</span></span><br/>
    <span class="line"><span class="tab"></span><span class="default">textRecords: {</span></span><br/>
    <span class="line"><span class="tab"></span><span class="tab"></span><span class="default">[key: </span><span class="type">string</span><span class="default">]: </span><span class="type">string</span></span><br/>
    <span class="line"><span class="tab"></span><span class="default">}</span></span><br/>
    <span class="line"><span class="default">}</span></span><br/>
</div>`

export default ensProfile
