import React, { useCallback, useEffect, useRef } from 'react'
import $ from 'jquery'
import { getPCN } from '../../../utils/classes'
import Typewriter from 'typewriter-effect/dist/core'

const className = 'custom-transform'
const pcn = getPCN(className)

const timing = {
    initialDelay: 1500,
    delayBetweenLines: 400,
}

function CustomTransform(props) {
    const { 
        code,
        startTyping = false,
        lines = [],
    } = props
    const hasStartedTyping = useRef(false)

    const initTypewriter = useCallback((i) => {
        let typewriter
        try {
            typewriter = new Typewriter(`#custom-transform-mp${i}`, {
                delay: 40,
            })
        } catch (err) {
            console.warn('couldn\'t find typewriter mountpoint')
            return null
        }
        return typewriter
    }, [])

    const writeLine = useCallback((i) => {
        const typewriter = initTypewriter(i)
        if (!typewriter) return

        typewriter
            .typeString(lines[i])
            .callFunction(() => {
                setTimeout(() => {
                    typewriter.stop()

                    const cursor = $(`#custom-transform-mp${i}`).find('.Typewriter__cursor')[0]
                    if (cursor) {
                        cursor.style.visibility = 'hidden'
                    }

                    if (i < lines.length - 1) {
                        writeLine(i + 1)
                    }
                }, i < lines.length - 1 ? timing.delayBetweenLines : 5)
            })
            .start()
    }, [lines])

    useEffect(() => {
        if (startTyping && !hasStartedTyping.current) {
            hasStartedTyping.current = true
            setTimeout(() => writeLine(0), timing.initialDelay)
        }
    }, [startTyping, writeLine])

    return (
        <div className={className} dangerouslySetInnerHTML={{ __html: code }}>
        </div>
    )
}

export default CustomTransform