import React, { useCallback } from 'react'
import { getPCN } from '../../utils/classes'
import { sections, paths } from '../../utils/nav'
import { useHistory } from 'react-router-dom'
import blinkIndicatorIcon from '../../svgs/svgjs/blink-icon-white'
import docIcon from '../../svgs/svgjs/doc'
import publishIcon from '../../svgs/svgjs/publish'
import { primitiveNamespaces } from '../../utils/chains'
import { stripTrailingSlash } from '../../utils/path'

const className = 'dashboard-side-panel'
const pcn = getPCN(className)

const items = [
    {
        name: 'Live Tables',
        section: sections.LIVE_OBJECTS,
        icon: blinkIndicatorIcon,
        link: nsp => paths.toLiveObjects(nsp)
    },
    {
        name: 'Contract Groups',
        section: sections.CONTRACT_GROUPS,
        icon: docIcon,
        link: nsp => paths.toContractGroups(nsp)
    },
    {
        name: 'Events',
        section: sections.EVENTS,
        icon: publishIcon,
        link: nsp => paths.toEvents(nsp)
    },
]

function DashboardSidePanel(props) {
    const { nsp, currentSection, currentSubsection, scrollToTop } = props
    const history = useHistory()
    const isPrimitiveNamespace = primitiveNamespaces.has(nsp)
    const sideItems = isPrimitiveNamespace ? [items[0]] : items

    const renderItems = useCallback(() => sideItems.map((item, i) => (
        <div
            key={i}
            className={pcn(
                '__section-item',
                `__section-item--${item.section}`,
                item.section === currentSection && !currentSubsection ? '__section-item--selected' : '',
            )}
            onClick={() => {
                const toPath = item.link(nsp)
                if (stripTrailingSlash(window.location.pathname) === stripTrailingSlash(toPath)) {
                    scrollToTop()
                } else {
                    history.push(toPath)
                }
            }}>
            <span dangerouslySetInnerHTML={{ __html: item.icon }}></span>
            <span>{item.name}</span>
        </div>
    )), [currentSection, currentSubsection, scrollToTop])

    return (
        <div className={className}>
            <div className={pcn('__liner')}>
                <div className={pcn('__section')}>
                    <div className={pcn('__section-body')}>
                        <div
                            className={pcn(
                                '__section-item',
                                !currentSection && !currentSubsection ? '__section-item--selected' : '',
                            )}
                            onClick={() => {
                                const toPath = paths.toNamespace(nsp)
                                if (stripTrailingSlash(window.location.pathname) === stripTrailingSlash(toPath)) {
                                    scrollToTop()
                                } else {
                                    history.push(toPath)
                                }
                            }}>
                            <span>@</span>
                            <span>{nsp}</span>
                        </div>
                    </div>
                </div>
                <div className={pcn('__section')}>
                    <div className={pcn('__section-body')}>
                        { renderItems() }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardSidePanel