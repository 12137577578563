import { useCallback, useEffect, useState } from 'react'
import api from '../../utils/api'
import { ContractGroupResources } from './ContractGroupResources'
import { ContractGroupHeader } from './ContractGroupHeader'

const className = 'contract-group-page'

export const ContractGroupPage = ({ nsp, name }) => {
    const group = [nsp, name].join('.')
    const [contractGroup, setContractGroup] = useState(null)
    const isLoading = contractGroup === null

    const getContractGroup = useCallback(async () => {
        const { data, ok } = await api.core.getContractGroup({ group })
        if (!ok) {
            return
        }
        setContractGroup(data)
    }, [group])

    useEffect(() => {
        getContractGroup()
    },[getContractGroup])

    useEffect(() => {
        if (!group) return
        document.title = group
    }, [group])

    return <div className={className}>
        <ContractGroupHeader contractGroup={contractGroup || {}} isLoading={isLoading}/>
        <ContractGroupResources nsp={nsp} contractGroup={contractGroup || {}} isLoading={isLoading}/>
    </div>
}
