import { useCallback } from 'react'
import { getPCN, cn } from '../../utils/classes'
import ObjectPropertyDocs from '../shared/ObjectPropertyDocs'
import Feed from '../shared/Feed'
import api from '../../utils/api'
import ChainAvatar from '../shared/ChainAvatar'
import { extractCustomerNspFromContractNsp } from '../../utils/formatters'
import { useHistory, useLocation } from 'react-router-dom'
import MdTerminal from '../shared/md/MdTerminal'
import { paths } from '../../utils/nav'
import PH from '../shared/PH'

const className = 'live-object-components'
const pcn = getPCN(className)

const categorySliderClassName = 'category-slider'
const csPcn = getPCN(categorySliderClassName)

const sliderStyle = {
    gutter: 18,
    minWidth: 65,
}

export const resourceNames = {
    OVERVIEW: 'overview',
    INPUT_EVENTS: 'input-events',
    TABLE: 'table',
    CONFIG: 'config',
}

export const resources = [
    {
        name: resourceNames.OVERVIEW,
        displayName: 'Overview',
        width: 81.16,
        link: (nsp, uid, subpage, path) => paths[path](nsp, uid, subpage),
    },
    {
        name: resourceNames.INPUT_EVENTS,
        displayName: 'Input Events',
        width: 96.88,
        link: (nsp, uid, subpage, path) => paths[path](nsp, uid, subpage),
    },
    {
        name: resourceNames.TABLE,
        displayName: 'Table',
        width: 65,
        link: (nsp, uid, subpage, path) => paths[path](nsp, uid, subpage),
    },
]

export const LiveObjectComponents = ({ uid, liveObject, isEvent }) => {
    const filter = useLocation().hash.slice(1) || resourceNames.OVERVIEW
    const lov = liveObject?.latestVersion || {}
    const customerNsp = extractCustomerNspFromContractNsp(lov.nsp) || ''
    const resourcesList = isEvent ? [resources[0], resources[2]] : resources
    const history = useHistory()
    const isLoading = !liveObject?.inputEvents

    const displayName = [lov.nsp, lov.name].join('.')
    let fullName = displayName
    if (liveObject?.hasManyVersions) {
        fullName += `@${lov.version}`
    }

    const fetchFeedData = async (cursor) => {
        const { data, ok } = await api.core.fetchLatestLovRecords({
            id: uid,
            cursor,
        })
        return ok ? data : []
    }

    const renderOverview = useCallback(() => {
        return (
            <>
                <ObjectPropertyDocs properties={liveObject?.latestVersion?.properties} />
                <Feed fetchData={fetchFeedData}/>
            </>
        )
    }, [filter, liveObject, fetchFeedData])

    const renderPlaceholders = useCallback((resource, maxPlaceholders) => {
        const empty = Array.apply(null, Array(maxPlaceholders)).map(() => null)
        return (
            <ol className={pcn('__list', `__list--${resource.toLowerCase()}`)}>
                { empty.map((_, i) => 
                    <li
                        key={i}
                        className={pcn('__item', '__item--ph', `__item--${resource.toLowerCase()}`)}>
                        <div className={pcn('__item-liner', '__item-liner--ph', `__item-liner--${resource.toLowerCase()}`)}>
                            <PH id={resource.toLowerCase()}/>
                        </div>
                    </li>
                )}
            </ol>
        )
    }, [])

    const renderInputEvents = useCallback(() => {
        return (
            <ol className={pcn('__list', `__list--${resourceNames.INPUT_EVENTS.toLowerCase()}`)}>
                { liveObject?.inputEvents.map((eventObject, i) => {
                    const chainIds = Object.keys(eventObject.latestVersion.config.chains)
                    const descClassNames = [
                        '__desc',
                        `__desc--${resourceNames.INPUT_EVENTS.toLowerCase()}`,
                        ...chainIds.map(id => `__desc--chain-${id}`),
                    ]
                    const contractGroup = eventObject.latestVersion.nsp
                    const customerNsp = extractCustomerNspFromContractNsp(eventObject.latestVersion.nsp) || ''
                    return (
                        <li
                            key={i}
                            className={pcn('__item', `__item--${resourceNames.INPUT_EVENTS.toLowerCase()}`)}
                            onClick={() => history.push(paths.toEvent(customerNsp, eventObject.latestVersion.id))}>
                            <div className={pcn('__item-liner', `__item-liner--${resourceNames.INPUT_EVENTS.toLowerCase()}`)}>
                                <div className={pcn('__item-body', `__item-body--${resourceNames.INPUT_EVENTS.toLowerCase()}`)}>
                                    <div>
                                        <h4>{eventObject.name}</h4>
                                        <span className={pcn(...descClassNames)}>
                                            { contractGroup }
                                        </span>
                                    </div>
                                    <ChainAvatar chainIds={chainIds} className={className}/>
                                    <p className={pcn('__records')}>
                                        <span>{eventObject.records?.toLocaleString('en-US') || 0}</span>
                                        <span>{eventObject.latestVersion.version}</span>
                                    </p>
                                </div> 
                            </div>
                        </li>
                    )})
                }
            </ol>
        )
    }, [filter, liveObject])

    const renderAddTableInstructions = useCallback(() => {
        return (
            <div className={pcn('__docs')}>
                <section>
                    <div className={pcn('__docs-section-header')}>
                        <h3>Postgres Table</h3>
                        <p>{ isEvent ? 'Add this event as a Live Table in Postgres.' : 'Add this Live Table to your own Postgres schema.' }</p>
                    </div>
                    <MdTerminal withHeader={false} sections={[
                        {
                            input: <span>spec <span className='accent'>add table</span> <span className='variable'>{displayName}</span>{ liveObject?.hasManyVersions ? <span className='variable' style={{ maxWidth: 90, height: 14, display: 'inline-block', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{`@${lov.version}`}</span> : '' }</span>,
                            copyText: `spec add table ${fullName}`,
                        }
                    ]}/>
                </section>
            </div>
        )
    })

    const renderComponent = useCallback(() => {
        if (filter === resourceNames.OVERVIEW) {
            return renderOverview()
        }
        if (filter === resourceNames.INPUT_EVENTS) {
            return isLoading ? renderPlaceholders(resourceNames.INPUT_EVENTS, 5) : renderInputEvents()
        }
        if (filter == resourceNames.TABLE) {
            return renderAddTableInstructions()
        }
    }, [filter, isLoading, renderPlaceholders, renderOverview, renderInputEvents, renderAddTableInstructions])

    const renderCategories = useCallback(() => {
        let sliderLeft = 0
        let sliderWidth = sliderStyle.minWidth
        for (const resource of resourcesList) {
            if (resource.name === filter) {
                sliderWidth = resource.width
                break
            }
            sliderLeft += (sliderStyle.gutter + resource.width)
        }
    
        return (
            <div className={cn(categorySliderClassName, `${categorySliderClassName}--${filter}`)}>
                <div
                    className={csPcn('__slider')}
                    style={{ width: sliderWidth, left: sliderLeft }}>
                </div>
                { resourcesList.map(resource => (
                    <div
                        key={resource.name}
                        className={csPcn(
                            '__category',
                            resource.name === filter ? '__category--selected' : '',
                        )}
                        onClick={() => {
                            window.skipJumpToTop = true
                            history.push(resource.link(customerNsp, uid, resource.name, isEvent ? `toEventSubPage`: `toLiveObjectSubPage`))
                        }}>
                        <span>{resource.displayName}</span>
                    </div>
                ))}
            </div>
        )
    }, [filter, resourcesList, customerNsp])

    return <div className={className}>
        <div className={pcn('__filters')}>
            <div className={pcn('__categories')}>
                { renderCategories() }
            </div>
        </div>
        <div className={pcn('__body', `__body--${filter}`)}>
            { renderComponent() }
        </div>
    </div>
}