const prefix = 'REACT_APP_'

export const ev = (name, fallback = null) => {
    const key = prefix + name
    return process.env.hasOwnProperty(key) ? process.env[key] : fallback
}

export const specEnvs = {
    LOCAL: 'local',
    STAGING: 'staging',
    PROD: 'prod',
}
