import { useCallback, useEffect, useRef, useState } from 'react'
import { chainNames } from '../../utils/chains'
import { getPCN } from '../../utils/classes'
import { extractCustomerNspFromContractNsp, withPeriod } from '../../utils/formatters'
import { format as toTimeAgo } from 'timeago.js'
import ImageComp from '../shared/ImageComp'
import MultiFacetCounter from '../shared/MultiFacetCounter'
import PH from '../shared/PH'
import verifiedIcon from '../../svgs/svgjs/verified'
import github from '../../svgs/svgjs/github'
import twitter from '../../svgs/svgjs/twitter'
import TimeAgo from '../shared/TimeAgo'
import { Link } from 'react-router-dom'
import { paths } from '../../utils/nav'
import api from '../../utils/api'

const className = 'namespace-header'
const pcn = getPCN(className)

const tickerDuration = count => {
    let duration = Math.min(count / 1000000, 1.4)
    return Math.max(duration, 2)
}

const timing = {
    POLL_INTERVAL: 1000
}

export const NamespaceHeader = ({ nsp, isLoading }) => {
    const customerNsp = extractCustomerNspFromContractNsp(nsp.slug) || ''
    const timeSinceJoined = nsp.joinedAt ? toTimeAgo(nsp.joinedAt) : ''
    const chainIds = nsp.chainIds || []
    const sortedChainIds = chainIds 
        .map(v => Number(v))
        .sort((a, b) => a !== 42161 ? -1 : a - b)
        .map(v => v.toString())

    const supportedChainNames = sortedChainIds.map(chainId => chainNames[chainId])
    const [recordStats, setRecordStats] = useState({
        count: nsp.records,
        lastInteraction: nsp.lastInteraction ? new Date(nsp.lastInteraction) : null,
    })
    const poller = useRef()

    const getRecordStats = useCallback(async (slug) => {
        const { data, ok } = await api.core.getNspRecordCounts({ nsps: [slug]})
        if (!ok) return

        const count = parseInt(data[slug]?.count)
        if (Number.isNaN(count)) return

        setRecordStats({ 
            count, 
            lastInteraction: data[slug]?.updatedAt ? new Date(data[slug].updatedAt) : null
        })
    }, [])
   
    useEffect(() => {
        if (!nsp.slug) return

        // Always clear interval when nsp changes.
        const clearPoller = () => poller.current && clearInterval(poller.current)
        clearPoller()

        // Wait for initial ticker to finish & then start polling.
        setTimeout(() => {
            poller.current = setInterval(
                () => getRecordStats(nsp.slug), 
                timing.POLL_INTERVAL,
            )
        }, 3000)

        // Update inital values when nsp changes.
        setRecordStats({ 
            count: nsp.records,
            lastInteraction: nsp.lastInteraction ? new Date(nsp.lastInteraction) : null,
        })

        return clearPoller
    }, [nsp.slug])

    const renderChains = useCallback(() => {
        return supportedChainNames?.map((chain, i) => 
            <div key={i} className={pcn('__chain', `__chain--${chain.replace(' ', '').toLowerCase()}`)}>
                <span>{chain.split(' ').pop()[0]}</span>
            </div>
    )}, [supportedChainNames])

    return <div className={className}>
        <div className={pcn('__top')}>
            <div className={pcn('__top-left')}>
                <ImageComp
                    phId='subject-header-icon'
                    src={nsp.icon}
                    blurhash={nsp.blurhash}
                    withBgBlur={true}
                    height={150}
                    width={150}
                />
                <div className={pcn('__body')}>
                    <div className={pcn('__verified')}>
                        { isLoading && <PH id='subject-header-verified'/> }
                        { nsp.verified
                            ? <>
                                <span dangerouslySetInnerHTML={{ __html: verifiedIcon }}></span>
                                <p>Verified Namespace</p>
                            </> 
                            : ''
                        }
                    </div>
                    <h2>
                        { nsp.displayName || <PH id='subject-header-name'/> }
                    </h2>
                    <div className={pcn('__icons')}>
                        { isLoading ? <PH id='subject-header-icons'/> : <>
                            <Link className={pcn('__tag')} to={paths.toNamespace(customerNsp)}>
                                @{customerNsp}
                            </Link>
                            <span className={pcn('__dot')}></span>
                            <div className={pcn('__chains')}>
                                { renderChains() }
                            </div>
                            <span className={pcn('__dot')}></span>
                            <div className={pcn('__joined')}>joined {timeSinceJoined}</div>
                            <span className={pcn('__dot')}></span>
                            <div className={pcn('__links')}>
                                { nsp.codeUrl && (
                                    <a
                                        target='_blank'
                                        rel='noreferrer'
                                        href={nsp.codeUrl}
                                        dangerouslySetInnerHTML={{ __html: github }}>
                                    </a>
                                )}
                                { nsp.twitterUrl && (
                                    <a
                                        target='_blank'
                                        rel='noreferrer'
                                        href={nsp.twitterUrl}
                                        dangerouslySetInnerHTML={{ __html: twitter }}>
                                    </a>
                                )}
                            </div>                        
                        </> }
                    </div>
                </div>
            </div>
            <div className={pcn('__top-right')}>
                <div>
                    <h4>RECORDS</h4>
                    <MultiFacetCounter
                        isLoading={isLoading}
                        value={recordStats.count}
                        duration={tickerDuration(recordStats.count || 0)}
                    />
                </div>
                <div>
                    <h4>LAST ACTIVITY</h4>
                    { isLoading ? '--' : <TimeAgo value={recordStats.lastInteraction} /> }
                </div>
            </div>
        </div>
        <div className={pcn('__bottom')}>
            { nsp.desc ? withPeriod(nsp.desc) : <PH id='subject-header-bottom'/> }
        </div>
    </div>
}