import { useCallback, useEffect, useState } from 'react'
import { getPCN, cn } from '../../utils/classes'
import { NamespaceResource, resourceNames, resources } from './NamespaceResource'
import api from '../../utils/api'
import { useHistory } from 'react-router-dom'
import { toNumber } from '../../utils/formatters'
import { isContractNamespace, primitiveNamespaces } from '../../utils/chains'

const className = 'namespace-resources'
const pcn = getPCN(className)

const categorySliderClassName = 'category-slider'
const csPcn = getPCN(categorySliderClassName)

const sliderStyle = {
    gutter: 18,
    minWidth: 65,
}

export const NamespaceResources = ({ slug, section }) => {
    const [filter, setFilter] = useState(section || resourceNames.ALL)
    const [resourceData, setResourceData] = useState({})
    const history = useHistory()
    const isPrimitiveNamespace = primitiveNamespaces.has(slug)
    const resourcesList = isPrimitiveNamespace ? resources.slice(0, 2) : resources
    
    const getLiveObjects = useCallback(async () => {
        const { data, ok } = await api.core.getLiveObjects({ 
            filters: { namespace: slug }, 
            limit: 100000 
        })
        if (!ok) {
            return
        }

        let liveObjects = []
        let events = []
        for (const entry of data || []) {
            if (isContractNamespace(entry.latestVersion.nsp)) {
                events.push(entry)
            } else {
                liveObjects.push(entry)
            }
        }

        // Sort live objects by # records and when they were created.
        liveObjects = liveObjects.sort((a, b) => (
            (toNumber(b.records) - toNumber(a.records)) || 
            (new Date(b.lastInteraction) - new Date(a.lastInteraction)) || 
            (new Date(b.latestVersion?.createdAt) - new Date(a.latestVersion?.createdAt))
        ))

        // Sort events by # records and name.
        events = events.sort((a, b) => (
            (toNumber(b.records) - toNumber(a.records)) || 
            (new Date(b.lastInteraction) - new Date(a.lastInteraction)) || 
            (a.latestVersion?.name || '').localeCompare(b.latestVersion?.name || '')
        ))

        setResourceData(prevState => ({
            ...prevState,
            [resourceNames.LIVE_OBJECTS]: liveObjects,
            [resourceNames.EVENTS]: events,
        }))
    }, [filter])

    const getContracts = useCallback(async () => {
        const { data, ok } = await api.core.getContracts({ filters: { namespace: slug } })
        if (!ok) {
            return
        }
        const contractGroups = (data || []).sort((a, b) => (
            ((b.contractCount || 0) - (a.contractCount || 0)) ||
            (new Date(a.createdAt) - new Date(b.createdAt))
        ))

        setResourceData(prevState => ({
            ...prevState,
            [resourceNames.CONTRACT_GROUPS]: contractGroups,
        }))
    }, [filter])

    useEffect(() => {
        getLiveObjects()
        getContracts()
    }, [slug])

    useEffect(() => {
        if (filter !== section) {
            setFilter(section || resourceNames.ALL)
        }
    }, [filter, section])

    const renderCategories = useCallback(() => {
        let sliderLeft = 0
        let sliderWidth = sliderStyle.minWidth
        for (const resource of resourcesList) {
            if (resource.name === filter) {
                sliderWidth = resource.width
                break
            }
            sliderLeft += (sliderStyle.gutter + resource.width)
        }
    
        return (
            <div className={cn(categorySliderClassName, `${categorySliderClassName}--${filter}`)}>
                <div
                    className={csPcn('__slider')}
                    style={{ width: sliderWidth, left: sliderLeft }}>
                </div>
                { resourcesList.map(resource => (
                    <div
                        key={resource.name}
                        className={csPcn(
                            '__category',
                            resource.name === filter ? '__category--selected' : '',
                        )}
                        onClick={() => {
                            window.skipJumpToTop = true
                            history.push(resource.link(slug))
                        }}>
                        <span>{resource.displayName}</span>
                    </div>
                ))}
            </div>
        )
    }, [filter])

    const renderResources = useCallback(() => {
        return filter === resourceNames.ALL
            ? resourcesList.slice(1).map((res, i) => (
                <NamespaceResource
                    key={i}
                    slug={slug}
                    resource={res}
                    resourceData={resourceData[res.name]}
                    isPreview={true}
                />
            )) : (
                <NamespaceResource
                    slug={slug}
                    resource={resourcesList.find(res => res.name === filter)}
                    resourceData={resourceData[filter]}
                />
            )
    }, [filter, resourceData])

    const hasNoLiveTables = (
        filter === resourceNames.ALL &&
        Array.isArray(resourceData[resourceNames.LIVE_OBJECTS]) && 
        !resourceData[resourceNames.LIVE_OBJECTS].length
    )
    const customContentStyle = hasNoLiveTables ? { marginTop: -12 } : {}

    return <div className={className}>
        <div className={pcn('__filters', `__filters--${filter}`)}>
            { renderCategories() }
        </div>
        <div className={pcn('__content', `__content--${filter}`)} style={customContentStyle}>
            { renderResources() }
        </div>
    </div>
}