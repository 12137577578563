import React, { useState, useRef, useCallback, forwardRef, useImperativeHandle } from 'react'
import { getPCN } from '../../../utils/classes'
import $ from 'jquery'
import searchIcon from '../../../svgs/svgjs/search'
import caretIcon from '../../../svgs/svgjs/caret-down'
import LiveObjectSearchResult from './LiveObjectSearchResult'
import { noop } from '../../../utils/nodash'
import hEllipsisIcon from '../../../svgs/svgjs/h-ellipsis'
import Typewriter from 'typewriter-effect'

const className = 'live-object-search'
const pcn = getPCN(className)

const results = [
    {
        id: 'AaveUserHealth',
        name: 'Aave User Health',
        icon: 'aave.jpg',
        desc: 'A user\'s current health on the Aave V3 protocol.',
        likes: '1.1k',
        author: '@aave'
    },
    {
        id: 'ENSProfile',
        name: 'ENS Profile',
        icon: 'ens.jpg',
        desc: 'Profile data for an ENS domain.',
        likes: '1.4k',
        author: '@spec'
    },
    {
        id: 'CompoundMarket',
        name: 'Compound Market',
        icon: 'compound.jpg',
        desc: 'Current market data for a Compound market.',
        likes: '3.2k',
        author: '@j1mmie'
    },
    {
        id: 'UniswapPoolTVL',
        name: 'Uniswap Pool TVL',
        icon: 'uniswap.jpg',
        desc: 'TVL and daily volume for a Uniswap V3 pool.',
        likes: '2.1k',
        author: '@ben'
    },
    {
        id: 'NFT',
        name: 'NFT Sales Price',
        icon: 'user-nfts.jpg',
        desc: 'The latest sales price for an NFT.',
        likes: '1.7k',
        author: '@0xmerkle'
    },
    {
        id: 'YieldPosition',
        name: 'Balancer Yield Position',
        icon: 'balancer.jpg',
        desc: 'A user\'s current yield on the Balancer protocol.',
        likes: '1.2k',
        author: '@balancer'
    },
    {
        id: 'CompoundHealth',
        name: 'Compound Account Health',
        icon: 'compound.jpg',
        desc: 'An account\'s current health on the Compound protocol.',
        likes: '3.2k',
        author: '@j1mmie'
    },
]

function LiveObjectSearch(props, ref) {
    const { onSelectLiveObject = noop } = props
    const searchRef = useRef()
    const searchPlaceholderRef = useRef()
    const typewriterRef = useRef()
    const [searchResults, setSearchResults] = useState(results)
    const [added, setAdded] = useState([])

    const onTypeQuery = useCallback(value => {
        setSearchResults(results.filter(r => 
            r.name.toLowerCase().includes(value) || r.desc.toLowerCase().includes(value) || r.id === 'CompoundHealth'
        ))
    }, [])

    const searchQuery = useCallback((query) => {
        let result = typewriterRef.current
        let val = ''

        for (let c of query) {
            val += c
            const value = val
            result = result.typeString(c)
            result = result.callFunction(() => onTypeQuery(value))
        }

        if (searchPlaceholderRef.current) {
            setTimeout(() => {
                searchPlaceholderRef.current.style.opacity = 0
            }, 5)
        }

        result.start()
    }, [])

    const addTopResult = useCallback(() => {
        const cursor = $(searchRef.current).find('.Typewriter__cursor')[0]
        if (cursor) {
            cursor.style.visibility = 'hidden'
        }
        setAdded(prevState => [ ...prevState, searchResults[0].id ])
    }, [searchResults])
    
    useImperativeHandle(ref, () => ({
        search: searchQuery,
        addTop: addTopResult,
    }))

    const renderResults = useCallback(() => searchResults.map((result, i) => (
        <LiveObjectSearchResult 
            key={result.id}
            canAnimate={true}
            added={added.includes(result.id)}
            {...result} 
        />
    )), [searchResults, onSelectLiveObject, added])

    const onInitTypewriter = useCallback(typewriter => {
        typewriterRef.current = typewriter
    }, [])

    return (
        <div className={className}>
            <div className={pcn('__search')} ref={searchRef}>
                <div className={pcn('__search-liner')}>
                    <span dangerouslySetInnerHTML={{ __html: searchIcon }}></span>
                    <span className={pcn('__search-text')}>
                        <span className={pcn('__search-ph')} ref={searchPlaceholderRef}>
                            Search Live Objects...
                        </span>
                        <Typewriter
                            onInit={onInitTypewriter}
                            options={{ delay: 30, deleteSpeed: 30 }}
                        />
                    </span>
                </div>
            </div>
            <div className={pcn('__filters')}>
                <div className={pcn('__filters-liner')}>
                    <div className={pcn('__categories')}>
                        <span>All</span>
                        <span>Identity</span>
                        <span>NFT</span>
                        <span>DeFi</span>
                        <span>Governance</span>
                        <div
                            className={pcn('__more-categories-button')}
                            dangerouslySetInnerHTML={{ __html: hEllipsisIcon }}>
                        </div>
                    </div>
                    <div className={pcn('__sort-by')}>
                        <span>Most Popular</span>
                        <span dangerouslySetInnerHTML={{ __html: caretIcon }}></span>
                    </div>
                </div>
            </div>
            <div className={pcn('__results')}>
                <div className={pcn('__results-liner')}>
                    { renderResults() }
                </div>
            </div>
        </div>
    )
}

LiveObjectSearch = forwardRef(LiveObjectSearch)
export default LiveObjectSearch