const chainAnalytics = `<svg viewBox="0 0 46 53">
    <g id="Exports" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="noun-cubes-755312" fill="#0E0E23" fill-rule="nonzero">
            <polygon id="Path" points="38.99897 9 32 12.9982036 38.9998374 16.9959783 38.9998374 25 46 21.0022253 46 12.9982036"></polygon>
            <polygon id="Path" points="0 12.9969972 6.99983736 16.995174 6.99983736 25 14 21.0018232 14 12.9969972 6.99896996 9"></polygon>
            <polygon id="Path" points="16 3.99777466 23.0001626 7.99597831 23.0001626 16 30 12.0026543 30 3.99777466 22.9998374 0"></polygon>
            <polygon id="Path" points="16 22.9974529 23.0001626 26.9957637 23.0001626 35 30 31.0016892 30 22.9974529 22.9998374 19"></polygon>
            <polygon id="Path" points="16 40.9982036 23.0001626 44.9959783 23.0001626 53 30 49.0022253 30 40.9982036 22.9998374 37"></polygon>
            <polygon id="Path" points="32 31.9982036 38.9998374 35.9964073 38.9998374 44 46 40.0013674 46 31.9982036 38.99897 28"></polygon>
            <polygon id="Path" points="0 31.9974529 6.99983736 35.9957637 6.99983736 44 14 40.0016892 14 31.9974529 6.99896996 28"></polygon>
        </g>
    </g>
</svg>`

export default chainAnalytics