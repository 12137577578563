import React, { useCallback, useMemo, useEffect, useRef } from 'react'
import { getPCN } from '../../utils/classes'
import $ from 'jquery'
import DashboardSidePanel from './DashboardSidePanel'
import logo from '../../svgs/svgjs/spec-wide-logo'
import { paths, subjects } from '../../utils/nav'
import backArrow from '../../svgs/svgjs/back'
import { Link, useHistory } from 'react-router-dom'
import { NamespacePage } from '../namespace/NamespacePage'
import { LiveObjectPage } from '../live-object/LiveObjectPage'
import GlobalSearchButton from '../shared/GlobalSearchButton'
import { ContractGroupPage } from '../contract-group/ContractGroupPage'

const className = 'dashboard'
const pcn = getPCN(className)

function DashboardPage(props) {
    const history = useHistory()
    const params = (props.match || {}).params || {}
    const currentNsp = useMemo(() => params.nsp || null, [params])
    const currentSection = useMemo(() => params.section || null, [params])
    const currentSubSection = useMemo(() => params.subSection || null, [params])
    const hash = window.location.hash
    const contentRef = useRef()

    const renderContentBodyComp = useCallback(() => {
        switch (currentSection) {
            case subjects.CONTRACT_GROUP:
                return <ContractGroupPage nsp={currentNsp} name={currentSubSection}/>

            case subjects.EVENT:
                return <LiveObjectPage nsp={currentNsp} uid={currentSubSection} isEvent={true}/>

            case subjects.LIVE_OBJECT:
                return <LiveObjectPage nsp={currentNsp} uid={currentSubSection}/>
            default:
                return (
                    <NamespacePage
                        slug={currentNsp}
                        section={currentSection}
                        subSection={currentSubSection}
                    />
                )
            }
    }, [currentSection, currentSubSection])

    const renderSidePanel = useCallback(() => (
        <div className={pcn('__side-panel')}>
            <div className={pcn('__side-panel-liner')}>
                <div className={pcn('__side-panel-header')}>
                    <Link
                        to={paths.HOME}
                        className={pcn('__side-panel-header-logo')}
                        dangerouslySetInnerHTML={{ __html: logo }}>
                    </Link>
                </div>
                <div className={pcn('__side-panel-body')}>
                    <DashboardSidePanel
                        nsp={currentNsp}
                        currentSection={currentSection}
                        currentSubSection={currentSubSection} 
                        scrollToTop={() => $(contentRef.current).scrollTop(0)}
                    />
                </div>
            </div>
        </div>
    ), [currentSection, currentSubSection, history])

    const renderContent = useCallback(() => (
        <div className={pcn('__content')} ref={contentRef}>
            <div className={pcn('__content-liner')}>
                <div className={pcn('__content-header')}>
                    <div className={pcn('__content-header-left')}>
                        <div
                            className={pcn('__header-nav-button')}
                            onClick={() => history.goBack()}>
                            <span dangerouslySetInnerHTML={{ __html: backArrow }}></span>
                        </div>
                        <div
                            className={pcn('__header-nav-button')}
                            onClick={() => history.goForward()}>
                            <span dangerouslySetInnerHTML={{ __html: backArrow }}></span>
                        </div>
                    </div>
                    <div className={pcn('__content-header-right')}>
                        <GlobalSearchButton/>
                    </div>
                </div>
                <div className={pcn('__content-body')}>
                    { renderContentBodyComp() }
                </div>
            </div>
        </div>
    ), [history, renderContentBodyComp])

    useEffect(() => {
        if (window.skipJumpToTop) {
            window.skipJumpToTop = false
            return
        }
        $(contentRef.current).scrollTop(0)
    }, [currentNsp, currentSection, currentSubSection, hash])

    return (
        <div className={className}>
            <div className={pcn('__liner')}>
                { renderSidePanel() }
                { renderContent() }
            </div>
        </div>
    )
}

export default DashboardPage