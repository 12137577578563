const governance = `<svg viewBox="0 0 72 36">
    <g id="Exports" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="noun-vote-62609" fill="#0E0E23" fill-rule="nonzero">
            <polygon id="Path" points="45.296466 4.64752795 43.943531 12.1416447 46.6997188 10.7627538 50.477017 12.6502799 29.0927262 23.3417765 25.4136618 25.1874926 25.414792 25.1824065 25.3011996 25.2377881 21.5249141 23.3496833 26.1640364 21.0304581 28.9490144 5.60994695 38.0741937 1.03587526 36.0007234 0 0 18 36.0007234 36 72 18"></polygon>
            <g id="check" transform="translate(32.000000, 5.000000)">
                <path d="M3.85171858,10.2897999 C3.56303786,10.3570062 3.28962072,10.2935076 3.11442138,10.1150681 L0.743257666,7.71003116 C0.434667929,7.39791239 0.514967624,6.83240403 0.922438803,6.44628427 C1.33057362,6.05875729 1.90992596,6.00092369 2.2185157,6.31366882 L3.77871886,7.89467386 L7.7014585,1.03601044 C7.97155747,0.564741896 8.54228258,0.306601774 8.97629911,0.460489943 C9.40965201,0.613906246 9.54304241,1.12152376 9.2722798,1.59419953 L4.63746105,9.69472879 C4.47686166,9.97702475 4.19879908,10.1945915 3.89419115,10.2786594 C3.8795912,10.283311 3.86631853,10.286401 3.85171858,10.2897999 Z" id="Path"></path>
            </g>
        </g>
    </g>
</svg>`

export default governance