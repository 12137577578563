import React, { useEffect, useState, useCallback, useRef } from 'react'
import { getPCN, cn } from '../../../utils/classes'
import { isElementInView } from '../../../utils/doc'
import dbIcon from '../../../svgs/svgjs/db'
import addToListIcon from '../../../svgs/svgjs/add-to-list'
import arrowSwitchIcon from '../../../svgs/svgjs/arrow-switch'
import pixels from '../../../svgs/svgjs/storage-top-pixels'
import CountUp from 'react-countup'
import NewTableDropdown from '../../shared/NewTableDropdown'
import NewLiveTablePanel from './NewLiveTablePanel'

const status = {
    BACKFILLING: {
        id: 'backfilling',
        title: 'Backfilling records...',
    },
    POPULATING: {
        id: 'populating',
        title: 'Backfilling records...',
    },
    IN_SYNC: {
        id: 'in-sync',
        title: 'IN SYNC',
    }
}

const features = [
    {
        title: 'Auto-Populating',
        desc: 'Live Tables automatically populate based on their relationship to the rest of your tables.',
        icon: addToListIcon,
    },
    {
        title: 'Auto-Updating',
        desc: 'Table records automatically update anytime the on-chain data they represent changes.',
        icon: '',
    },
    {
        title: 'Dedicated Postgres',
        desc: 'Every Spec project is a fully-dedicated Postgres database, one of the world\'s most scalable databases.',
        icon: dbIcon,
    },
    {
        title: 'BYODB',
        desc: 'Kickstart new projects with a hosted Postgres instance, or bring your own and connect Spec to your existing database.',
        icon: arrowSwitchIcon,
    },
]

const timing = {
    initialDelay: window.innerWidth <= 580 ? 10 : 1250,
    ticker: 1600,
}

function LiveTableSection() {
    const className = 'live-table'
    const observerTargetId = 'liveTableObserverTarget'
    const pcn = getPCN(className)
    const [clickNewTable, setClickNewTable] = useState(false)
    const [show2, setShow2] = useState(false)
    const [show3, setShow3] = useState(false)
    const [show4, setShow4] = useState(false)
    const [status2, setStatus2] = useState(status.BACKFILLING)
    const [status3, setStatus3] = useState(status.BACKFILLING)
    const [status4, setStatus4] = useState(status.BACKFILLING)
    const [showPanel, setShowPanel] = useState(false)
    const observerCreated = useRef(false)
    const observerCalled = useRef(false)
    const newTableDropdownRef = useRef()
    const panelRef = useRef()

    const startRow4 = useCallback(() => {
        setShow4(true)
        setTimeout(() => {
            setStatus4(status.POPULATING)
            setTimeout(() => {
                setStatus4(status.IN_SYNC)
                setTimeout(() => panelRef.current?.delete('sales', 1500))
            }, timing.ticker + 1000)
        }, 500)  
    }, [])

    const searchForRow4 = useCallback(() => {
        panelRef.current?.delete('nfts')

        if (window.innerWidth <= 850) {
            return
        }

        setTimeout(() => {
            panelRef.current?.search('sales')

            setTimeout(() => {
                panelRef.current?.addTop()
    
                setTimeout(() => {
                    startRow4()
                }, 300)
            }, 2000)
        }, 2000)
    }, [startRow4])

    const startRow3 = useCallback(() => {
        setShow3(true)
        setTimeout(() => {
            setStatus3(status.POPULATING)
            setTimeout(() => {
                setStatus3(status.IN_SYNC)
                setTimeout(searchForRow4, 1500)
            }, timing.ticker + 1000)
        }, 500)    
    }, [searchForRow4])

    const searchForRow3 = useCallback(() => {
        panelRef.current?.delete('token')

        setTimeout(() => {
            panelRef.current?.search('nfts')

            setTimeout(() => {
                panelRef.current?.addTop()
    
                setTimeout(() => {
                    startRow3()
                }, 300)
            }, 2000)
        }, 2000)
    }, [startRow3])

    const startRow2 = useCallback(() => {
        setShow2(true)
        setTimeout(() => {
            setStatus2(status.POPULATING)
            setTimeout(() => {
                setStatus2(status.IN_SYNC)
                setTimeout(searchForRow3, 1500)
            }, timing.ticker + 1000)
        }, 500)
    }, [searchForRow3])

    const searchForRow2 = useCallback(() => {
        panelRef.current?.search('token')

        setTimeout(() => {
            panelRef.current?.addTop()

            setTimeout(() => {
                startRow2()
            }, 300)
        }, 2000)
    }, [startRow2])

    const startAnimation = useCallback(() => {
        setClickNewTable(true)
        setTimeout(() => {
            newTableDropdownRef.current?.show()
            setTimeout(() => {
                newTableDropdownRef.current?.setShowHover([1])
                setTimeout(() => {
                    newTableDropdownRef.current?.setShowClick([1])
                    setTimeout(() => {
                        newTableDropdownRef.current?.hide()
                    }, 200)
                    setTimeout(() => {
                        setShowPanel(true)
                        setTimeout(searchForRow2, 1500)
                    }, 300)
                }, 1800)
            }, 600)
        }, 200)
    }, [startRow2])

    const createIntersectionObserver = useCallback(() => {
        const observer = new IntersectionObserver( entries => {
            if (entries && entries[0] && entries[0].isIntersecting && !observerCalled.current) {
                observerCalled.current = true
                setTimeout(startAnimation, timing.initialDelay)
            }
        }, { threshold: 0 })

        const el = document.querySelector( `#${observerTargetId}` )
        el && observer.observe( el )
    }, [startAnimation])

    useEffect(() => {
        if (observerCreated.current) return
        observerCreated.current = true

        if ( window.IntersectionObserver ) {
            setTimeout( () => {
                isElementInView( `#${observerTargetId}` )
                    ? setTimeout(startAnimation, timing.initialDelay)
                    : createIntersectionObserver()
            }, 500 )
        } else {
            setTimeout( () => startAnimation(), timing.initialDelay )
        }
    }, [createIntersectionObserver, startAnimation])

    const renderStatus = useCallback((statusId) => (
        <div className={pcn('__status-container')}>
            <div className={pcn('__status', `__status--backfilling`)}>
                <span>{ window.innerWidth <= 850 ? 'Backfilling...' : status.BACKFILLING.title }</span>
            </div>
            <div className={pcn('__status', `__status--in-sync`)}>
                { <span className='blink-indicator'><span></span></span> }
                <span>{ status.IN_SYNC.title }</span>
            </div>
        </div>
    ), [])

    const renderRowCount = useCallback((count, statusId) => {
        switch (statusId) { 
            case status.BACKFILLING.id:
                return <span>{0}</span>
            case status.POPULATING.id:
            case status.IN_SYNC.id:
                return (
                    <CountUp start={statusId === status.IN_SYNC.id ? count : 0} end={count} delay={0} duration={timing.ticker / 1000}>
                        {({ countUpRef }) => <span ref={countUpRef}></span> }
                    </CountUp>                
                )
            default:
                return <span>{0}</span>
        }
    }, [])

    const renderTable = useCallback(() => (
        <div className={pcn('__table')}>
            <div className={pcn('__table-intro')}>
                <div className={pcn('__table-title')}>
                    <span dangerouslySetInnerHTML={{ __html: dbIcon }}></span>
                    <span>Tables</span>
                </div>
                <div className={pcn('__new-table-button')}>
                    <span className={clickNewTable ? '--click' : ''}>
                        <span>+</span>
                        <span>New Table</span>
                    </span>
                    <NewTableDropdown
                        key='newTableDropdown'
                        id='newTableDropdown'
                        ref={newTableDropdownRef}
                    />
                </div>
            </div>
            <div className={pcn('__table-header')}>
                <div><span>Name</span></div>
                <div><span>Type</span></div>
                <div><span>Status</span></div>
                <div><span>Rows</span></div>
                <div><span>Description</span></div>
            </div>
            <div className={pcn('__table-body')}>
                <div className={pcn('__table-row', '__table-row--1', '__table-row--show')}>
                    <div><span>wallets</span></div>
                    <div><span>Standard</span></div>
                    <div><span>--</span></div>
                    <div><span>210</span></div>
                    <div><span>Crypto wallets that our users sign in with.</span></div>
                </div>
                <div className={pcn(
                    '__table-row', 
                    '__table-row--2', 
                    `__table-row--${status2.id}`,
                    show2 ? `__table-row--show` : '',
                )}>
                    <div><span>{ 'token_holdings' }</span></div>
                    <div><span>Live</span></div>
                    <div>{ renderStatus(status2.id) }</div>
                    <div>{ renderRowCount(837, status2.id) }</div>
                    <div><span>ERC-20 tokens held by wallet.</span></div>
                </div>
                <div className={pcn(
                    '__table-row',
                    '__table-row--3',
                    `__table-row--${status3.id}`,
                    show3 ? `__table-row--show` : '',
                )}>
                    <div><span>nfts</span></div>
                    <div><span>Live</span></div>
                    <div>{ renderStatus(status3.id) }</div>
                    <div>{ renderRowCount(422, status3.id) }</div>
                    <div><span>NFTs owned by wallet.</span></div>
                </div>
                <div className={pcn(
                    '__table-row',
                    '__table-row--4',
                    `__table-row--${status4.id}`,
                    show4 ? `__table-row--show` : '',
                )}>
                    <div><span>{ window.innerWidth <= 580 ? 'nft_sales' : 'nft_sales_history' }</span></div>
                    <div><span>Live</span></div>
                    <div>{ renderStatus(status4.id) }</div>
                    <div>{ renderRowCount(1430, status4.id) }</div>
                    <div><span>NFT sales and pricing history.</span></div>
                </div>
            </div>
        </div>
    ), [show2, show3, show4, status2, status3, status4, clickNewTable])

    const renderPanel = useCallback(() => (
        <div className={pcn('__panel', showPanel ? '__panel--show' : '')}>
            <NewLiveTablePanel ref={panelRef}/>
        </div>
    ), [showPanel])

    const renderFeatures = useCallback(() => features.map((feature, i) => (
        <div className={pcn('__feature')} key={i}>
            { i === 1 && <span className='blink-indicator blink-indicator--white'><span></span></span> }
            <div className={pcn('__feature-top')}>
                <span dangerouslySetInnerHTML={{ __html: feature.icon }}></span> 
                <span>{feature.title}</span>
            </div>
            <div className={pcn('__feature-bottom')}>   
                <span>{feature.desc}</span>
            </div>
        </div>
    )), [])

    return (
        <div
            id='liveTable'
            className={cn(className)}>
            <div
                className='pixels'
                dangerouslySetInnerHTML={{ __html: pixels }}>
            </div>
            <div className={pcn('__liner')}>
                <div className={pcn('__text-container')}>
                    <div className={pcn('__name')}>
                        Live Tables
                    </div>
                    <div className={pcn('__title')}>
                        Instantly add the data you need
                    </div>
                    <div className={pcn('__subtitle')}>
                        Add the web3 tables you need and get straight to building what makes your app special.
                    </div>
                </div>
                <div className={pcn('__graphic')}>
                    <div className={pcn('__graphic-liner')}>
                        { renderTable() }
                        { renderPanel() }
                    </div>
                    <span id={observerTargetId}></span>
                </div>
                <div className={pcn('__features')}>
                    <div className={pcn('__features-liner')}>
                        { renderFeatures() }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LiveTableSection
