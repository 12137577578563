import React, { useCallback, useEffect, useState, useRef, useImperativeHandle, forwardRef } from 'react'
import { cn, getPCN } from '../../utils/classes'
import { noMod } from '../../utils/formatters'
import hljs from 'highlight.js/lib/core'
import typescript from 'highlight.js/lib/languages/typescript'
hljs.registerLanguage('typescript', typescript)

const className = 'feed'
const pcn = getPCN(className)

function Feed({ 
    fetchData,
    formatter = noMod,
    ...props
}, ref) {
    const [records, setRecords] = useState(props.records || null)
    const [cursor, setCursor] = useState(props.cursor)
    const hasMadeInitialLoad = useRef(false)

    const loadLatest = useCallback(async () => {
        const data = (await fetchData(cursor)) || {}
        const newRecords = data.records || []
        setRecords(prevRecords => [...newRecords, ...(prevRecords || [])].slice(0, 100))
        setCursor(data.cursor)
    }, [fetchData, cursor])

    useImperativeHandle(ref, () => ({
        load: loadLatest,
    }), [loadLatest])

    useEffect(() => {
        if (!hasMadeInitialLoad.current) {
            hasMadeInitialLoad.current = true
            loadLatest()
        }
    }, [loadLatest])

    const formatRecord = useCallback((record) => {
        if (!record) return ''
        return hljs.highlight(
            JSON.stringify(formatter(record), null, 4).replace(/"([^"]+)":/g, '$1:'), // remove quotes on keys
            { language: 'typescript' }
        ).value
    }, [formatter])

    const renderRecord = useCallback((record, i) => (
        <div
            key={i}
            className={pcn('__record')}
            dangerouslySetInnerHTML={{ __html: formatRecord(record) }}>
        </div>
    ), [formatRecord])

    return (
        <div className={cn(className, !hasMadeInitialLoad.current || records === null ? `${className}--initial-load` : '')}>
            <div className={pcn('__liner')}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <div className={cn(pcn('__records'), 'editor')}>
                    { records?.map((r, i) => renderRecord(r, i)) }
                </div>
            </div>
        </div>
    )
}

Feed = forwardRef(Feed)
export default Feed