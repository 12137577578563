const chainbox = `<svg viewBox="0 0 60 60">
    <defs>
        <rect id="mask-cb-1" x="0" y="0" width="60" height="60" rx="8"></rect>
        <mask id="mask-cb" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0" width="60" height="60" fill="white">
            <use xlink:href="#mask-cb-1"></use>
        </mask>
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-dasharray="4" stroke-opacity="0.16">
        <use id="Rectangle" stroke="#6874FF" mask="url(#mask-cb)" stroke-width="2" xlink:href="#mask-cb-1"></use>
    </g>
</svg>`
export default chainbox